const cleanupTabListener = ref<Maybe<ReturnType<typeof useEventListener>>>(null)
const nextFocusOn = ref<Maybe<string>>(null)
const tabWasPressed = ref(false)

export const useTrackingFieldSingleton = () => {
  const getIsAutomaticFocus = (clickName?: string) =>
    !tabWasPressed.value &&
    (!nextFocusOn.value || nextFocusOn.value !== clickName)

  const notifyAboutFocusIn = () => {
    nextFocusOn.value = null
    tabWasPressed.value = false
  }

  const setNextFocusOn = (clickName?: string) => {
    nextFocusOn.value = clickName ?? null
  }

  const setTabListener = () => {
    if (cleanupTabListener.value) return

    cleanupTabListener.value = useEventListener(
      document,
      'keydown',
      (event) => {
        tabWasPressed.value = event.key === 'Tab'
      },
    )
  }

  const reset = () => {
    cleanupTabListener.value?.()
    cleanupTabListener.value = null
    nextFocusOn.value = null
    tabWasPressed.value = false
  }

  return {
    getIsAutomaticFocus,
    notifyAboutFocusIn,
    reset,
    setNextFocusOn,
    setTabListener,
  }
}
