<template>
  <div class="payment-method-default w-full h-full" :class="[size]">
    <div v-for="index in NUMBER_OF_ROWS" :key="index" class="item" />
  </div>
</template>

<script lang="ts" setup>
import type { PaymentMethodSymbolProps } from '~/features/Payment/PaymentMethodSymbol.vue'

type PaymentMethodSymbolFallbackProps = {
  size: PaymentMethodSymbolProps['size']
}

const NUMBER_OF_ROWS = 3

defineProps<PaymentMethodSymbolFallbackProps>()
</script>

<style lang="scss" scoped>
.payment-method-default {
  display: grid;
  grid-template-rows: 19.1% 32.6% 1fr;
  grid-row-gap: 0.105rem;
  align-items: end;

  .item {
    height: 100%;
    border-radius: 0.0625rem;
    background: token('color.lightblue.600');

    &:nth-child(1) {
      justify-self: flex-end;
      width: 22.9%;
    }

    &:nth-child(2) {
      width: 21.5%;
    }

    &:nth-child(3) {
      width: 73%;
      height: 57.2%;
    }
  }
}
</style>
