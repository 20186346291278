import type { RouteLocationNormalizedGeneric } from 'vue-router'

import { InMemoryCache } from '@apollo/client/core'

import type { LotteryId } from '~/@types/generated/backend/graphql-schema-types'

import { cache, CacheStorage } from '~/apollo/local/cache'
import { PersistedState } from '~/apollo/local/types'

export const getCachedProductId = (
  licensedTerritory: string,
  product: string,
): Maybe<string> =>
  cache().readOrInitCache({
    defaultValue: null,
    inMemoryCache: new InMemoryCache(),
    keyName: PersistedState.ProductId,
    storage: CacheStorage.Cookie,
    variables: {
      licensedTerritory,
      product,
    },
  })

export const getProductId = (
  route: RouteLocationNormalizedGeneric,
): Maybe<LotteryId | string> => {
  const { params, query } = route

  const lotteryId = getLotteryIdInCorrectCase(
    String(params.lotteryId || query.p),
  )
  if (lotteryId) return lotteryId

  const fallbackId = params.slug || query.p
  return fallbackId ? String(fallbackId) : null
}
