import type { CookieRef } from '#app'

import {
  type InMemoryCache,
  makeVar,
  type ReactiveVar,
} from '@apollo/client/core'
import { type RemovableRef, useStorage } from '@vueuse/core'
import { subDays } from 'date-fns'

import type { CookiesQuery } from '~/@types/generated/backend/graphql-schema-types'
import type { PersistedStates } from '~/apollo/local/types'

export enum CacheStorage {
  Cookie = 'Cookie',
  LocalStorage = 'LocalStorage',
  SessionStorage = 'SessionStorage',
}

type ReadOrInitCacheOptions<T> = {
  defaultValue: T
  keyName: PersistedStates
  variables?: LooseObject
} & (
  | { inMemoryCache: InMemoryCache; storage: CacheStorage.Cookie }
  | { storage: CacheStorage.LocalStorage }
  | { storage: CacheStorage.SessionStorage }
)

type TemporaryStorage<T> = { modifiedAt: Date; value: T }

export const CACHE_KEY_PREFIX = 'ltc_cc'

// WARNING do not access or fill on server
const persistedCache: Record<
  string,
  {
    storage: CacheStorage
    storageRef: Maybe<CookieRef<any> | RemovableRef<any>>
    value: ReactiveVar<any>
  }
> = {}

export const cache = () => {
  const addCache = <T>(
    keyName: string,
    storage: CacheStorage,
    defaultValue: T,
  ) => {
    // do not access or fill on server
    if (isNuxtServer()) return
    // return early if makeVar is already present. Storage types LocalStorage and SessionStorage need reevaluation
    if (persistedCache[keyName] && storage === CacheStorage.Cookie) return
    const storageRef = manageStorage<T>(keyName, storage, defaultValue)
    persistedCache[keyName] = {
      storage,
      storageRef,
      value: makeVar(storageRef?.value || defaultValue),
    }
  }

  const deleteCache = (keyName: PersistedStates, variables?: LooseObject) => {
    if (isNuxtServer()) return
    const key = keyGenerator(keyName, variables)
    if (!persistedCache[key]) return
    if (persistedCache[key].storageRef)
      persistedCache[key].storageRef.value = null
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete persistedCache[key]
  }

  const keyGenerator = <T = LooseObject>(
    key: PersistedStates,
    variables?: T,
  ) => {
    if (!variables) return `${CACHE_KEY_PREFIX}_${key}`

    const stringifiedVariables = Object.values(variables)
      .map((value) => (typeof value === 'string' ? value.toLowerCase() : value))
      .sort()
      .join('_')
    return `${CACHE_KEY_PREFIX}_${key}_${stringifiedVariables}`
  }

  const manageStorage = <T>(
    key: string,
    storageType: CacheStorage,
    value: T,
    update?: boolean,
  ) => {
    const validFrom = subDays(new Date(), 30)
    switch (storageType) {
      case CacheStorage.Cookie:
        // eslint-disable-next-line no-case-declarations
        const cookie = useCookie<T>(key, {
          default: () => value,
          maxAge: 60 * 60 * 24 * 30,
        })
        if (cookie.value && !update) return cookie
        cookie.value = value
        return cookie
      case CacheStorage.LocalStorage:
      case CacheStorage.SessionStorage:
        // eslint-disable-next-line no-case-declarations
        const storage = useStorage<TemporaryStorage<T>>(
          key,
          {
            modifiedAt: new Date(),
            value,
          },
          storageType === CacheStorage.SessionStorage
            ? sessionStorage
            : localStorage,
        )
        if (storage.value && !update && validFrom > storage.value.modifiedAt)
          return storage
        storage.value = storage.value = { modifiedAt: new Date(), value }
        return storage
    }
  }

  const readOrInitCache = <T = string>(options: ReadOrInitCacheOptions<T>) => {
    const { defaultValue, keyName, storage, variables } = options
    // return early on server when storage is not available on server
    if (isNuxtServer() && storage !== CacheStorage.Cookie) return null
    const key = keyGenerator(keyName, variables)

    // return early on server with cookie
    if (isNuxtServer() && storage === CacheStorage.Cookie) {
      const cookie = options.inMemoryCache.readQuery<CookiesQuery>({
        query: CookiesDocument,
      })
      if (cookie?.cookies) {
        // passes for numbers and objects
        const parsedCookie = JSON.parse(cookie.cookies)
        // return default value if cookie is not present
        if (!parsedCookie[key]) return defaultValue
        try {
          return JSON.parse(parsedCookie[key])
        } catch {
          // is a string if parse fails
          return parsedCookie[key]
        }
      }
      return defaultValue
    }

    addCache(key, storage, defaultValue)
    return persistedCache[key].value()
  }

  const updateCache = <T, V>(
    keyName: PersistedStates,
    value: T,
    variables?: V,
  ) => {
    const key = keyGenerator<V>(keyName, variables)
    persistedCache[key].value(value)
    manageStorage<T>(key, persistedCache[key].storage, value, true)
  }

  return {
    addCache,
    deleteCache,
    keyGenerator,
    persistedCache,
    readOrInitCache,
    updateCache,
  }
}
