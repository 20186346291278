<template>
  <header
    v-if="usedVariant !== 'no-header'"
    v-bind="$attrs"
    class="the-header py-lg"
  >
    <div class="content-wrap flex justify-between px-md">
      <section>
        <HyperLink to="/">
          <img
            :alt="shopPublicName"
            :height="32"
            src="~/assets/img/brand/logo-default.svg"
            :width="208"
          />
        </HyperLink>
      </section>

      <TheHeaderStateSwitch v-if="usedVariant !== 'logo-only'" />
    </div>

    <div v-if="usedVariant === 'full'" class="content-wrap h-lg mt-md px-md">
      <div class="transition-wrap">
        <TransitionGroup name="fade">
          <TheHeaderBalance v-show="hasBalance" key="balance" />
          <PaymentMethodSymbols
            v-show="!hasBalance"
            key="payment-icons"
            gap-class="gap-xs"
          />
        </TransitionGroup>
      </div>
    </div>
  </header>
  <ActiveAnnouncement class="content-wrap px-xs mb-md" />
</template>

<script lang="ts" setup>
type TheHeaderProps = {
  variant?: TheHeaderVariant
}

type TheHeaderVariant = 'full' | 'logo-only' | 'no-balance' | false

const props = withDefaults(defineProps<TheHeaderProps>(), {
  variant: undefined,
})

const hasBalance = useState(StateKey.HasBalance, () => false)

const {
  i18nConfig: { shopPublicName },
} = useRuntimeConfig().public

const usedVariant = computed(() => {
  let { header } = useRoute().meta
  if (typeof props.variant !== 'undefined') header = props.variant

  if (typeof header === 'undefined') return 'logo-only'
  if (!header) return 'no-header'
  return header
})
</script>

<style lang="scss" scoped>
.the-header {
  a {
    position: relative;
    display: block;

    @include focus-state {
      &::before {
        inset: 0;
        border-radius: $border-radius-sm;
        box-shadow:
          0 0 0 0.125rem color('white'),
          0 0 0 0.3125rem color('info700');
      }
    }
  }

  .fade-leave-active {
    position: absolute;
    top: 0;
    left: 0;
  }

  .transition-wrap {
    position: relative;
  }
}
</style>
