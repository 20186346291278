export const additionalInfoRegex = /^[\p{Ll}\p{Lu}\d.\-,'º/ :#]{0,38}$/gmu

export const cityRegex =
  /^[\p{Lu}\p{Ll}]([\p{Lu}\p{Ll}.\-,' ()/]{0,38}[\p{Lu}\p{Ll}.)])?$/gmu

export const countryRegex =
  /^[\p{Ll}\p{Lu}]([\p{Lu}\p{Ll}.'\- ]{0,38}[\p{Ll}\p{Lu}.])?$/gmu

export const countryCodeRegex = /^\+\d{1,3}$/

export const nameRegex =
  /^[\p{Ll}\p{Lu}][\p{Ll}\p{Lu}'.\- ]{0,38}[\p{Ll}\p{Lu}.]$/gmu

export const stateRegex =
  /^[\p{Lu}\p{Ll}]([\p{Lu}\p{Ll}.'’ -]{0,38}[\p{Lu}\p{Ll}.])?$/gmu

export const streetNameRegex =
  /^[\p{Ll}\p{Lu}\d]([\p{Ll}\p{Lu}\d.\-,'º/ ]{0,38}[\p{Ll}\p{Lu}\d.º])?$/gmu

export const streeNumberRegex = /^[a-zA-Z\d]([a-zA-Z\d/, -]{0,38}[a-zA-Z\d])?$/

export const zipcodeRegex = /^[a-zA-Z\d]([a-zA-Z\d -]{0,38}[a-zA-Z\d])?$/
