<template>
  <div class="payment-method-symbol" :class="[size, { outlined: isOutlined }]">
    <LcIcon v-if="isBank" name="bank" v-bind="{ size }" />

    <img
      v-else-if="isPaymentIcon(variant)"
      :alt="variant.title"
      :src="variant.icon.url"
    />

    <PaymentMethodSymbolFallback v-else v-bind="{ size }" />
  </div>
</template>

<script lang="ts" setup>
import type { PaymentIcon } from '~/composables/paymentIcons'

export type PaymentMethodSymbolProps = {
  outlined?: boolean
  size?: 'medium' | 'small'
  variant?: 'Bank' | PaymentIcon
}

const props = withDefaults(defineProps<PaymentMethodSymbolProps>(), {
  outlined: true,
  size: 'medium',
})

const isBank = computed(() => props.variant === 'Bank')

const isOutlined = computed(
  () =>
    props.outlined &&
    (!isPaymentIcon(props.variant) || !props.variant.isLogoComplianceStrict),
)

const isPaymentIcon = (
  _variant: PaymentMethodSymbolProps['variant'],
): _variant is PaymentIcon =>
  typeof _variant === 'object' && (_variant as PaymentIcon).icon !== undefined
</script>

<style lang="scss" scoped>
.payment-method-symbol {
  display: flex;
  justify-content: center;
  align-items: center;

  &.medium {
    width: 3rem;
    height: 1.875rem;
  }

  &.small {
    width: 2.5rem;
    height: 1.5rem;
  }

  &.outlined {
    padding: spacing('2xs') spacing('3xs');
    border: 0.063rem solid #afd9f9;
    border-radius: $border-radius-sm;
    background: linear-gradient(
      180deg,
      rgba(238, 249, 255, 40%) 0%,
      rgba(255, 255, 255, 40%) 100%
    );
  }
}
</style>
