/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { defineStore } from 'pinia'

import type {
  LotteryId,
  LotteryQuery,
} from '~/@types/generated/backend/graphql-schema-types'
export enum LotteryTicketType {
  PrepaidSubscription = 'PrepaidSubscription',
  Single = 'Single',
  Subscription = 'Subscription',
}
export type LotteryConfig = LotteryQuery['lottery']['currentConfig']
export type LotteryLocalState = {
  confirmedPlays: ConfirmedPlays
  prepaidSubscriptionAmount: PrepaidSubscriptionAmount
  selectedAddOns: LotterySelectedAddOns
  selectedNumbers: SelectedNumbers
  selectedPerPlayAddOns: LotterySelectedPerPlayAddOns
  selectedStakes: LotterySelectedStakes
  ticketType: TicketType
}
export type LotterySelectedAddOn = AddOn
export type LotterySelectedPerPlayAddOn = AddOn
export type LotterySelectedPerPlayAddOns = Record<
  string,
  LotterySelectedPerPlayAddOn[]
>
export type NumberSelection = { isQuickPick: boolean; numbers: number[][] }
export type NumberSelections = NumberSelection[]
type AddOn = { name: string; value: string }
type ConfirmedPlays = Record<string, boolean[]>
type LotterySelectedAddOns = Record<string, LotterySelectedAddOn[]>

type LotterySelectedStakes = Record<string, string[]>

type PrepaidSubscriptionAmount = Record<
  string,
  Maybe<PrepaidSubscriptionOption>
>

type PrepaidSubscriptionOption = {
  label: string
  value: number
}
type SelectedNumbers = Record<string, NumberSelections>

type TicketType = Record<string, LotteryTicketType>

export const useLotteryStore = defineStore('ltc_lottery', {
  actions: {
    deleteLotterySelection(lotteryId: string) {
      delete this.confirmedPlays[lotteryId]
      delete this.selectedAddOns[lotteryId]
      delete this.selectedNumbers[lotteryId]
      delete this.selectedPerPlayAddOns[lotteryId]
      delete this.selectedStakes[lotteryId]
      delete this.ticketType[lotteryId]
      delete this.prepaidSubscriptionAmount[lotteryId]
    },
    setConfirmedPlays(lotteryId: string, plays: boolean[]) {
      this.confirmedPlays[lotteryId] = plays
    },
    setPrepaidSubscriptionAmount(
      lotteryId: string,
      amount: Maybe<PrepaidSubscriptionOption>,
    ) {
      this.prepaidSubscriptionAmount[lotteryId] = amount
    },
    setSelectedAddOns(
      lotteryId: string,
      addOns: { name: string; value: string }[],
    ) {
      this.selectedAddOns[lotteryId] = addOns
    },
    setSelectedLotteryNumbers(
      lotteryId: `${LotteryId}` | LotteryId,
      numbers: NumberSelections,
    ) {
      this.selectedNumbers[lotteryId] = numbers
    },
    setSelectedPerPlayAddOns(
      lotteryId: string,
      perPlayAddOns: LotterySelectedPerPlayAddOn[],
    ) {
      this.selectedPerPlayAddOns[lotteryId] = perPlayAddOns
    },
    setSelectedStakes(lotteryId: string, stakes: string[]) {
      this.selectedStakes[lotteryId] = stakes
    },
    setTicketType(lotteryId: string, ticketType: LotteryTicketType) {
      this.ticketType[lotteryId] = ticketType
    },
  },
  getters: {
    confirmedPlaysByLottery:
      (state) => (lotteryId: string, config: LotteryConfig) =>
        state.confirmedPlays[lotteryId] || getInitialConfirmedPlays(config),
    prepaidSubscriptionAmountByLottery: (state) => (lotteryId: string) =>
      state.prepaidSubscriptionAmount[lotteryId],
    selectedAddOnsByLottery:
      (state) => (lotteryId: string, config: LotteryConfig) =>
        state.selectedAddOns[lotteryId] || getInitialSelectedAddOns(config),

    selectedNumbersByLottery:
      (state) => (lotteryId: string, config: LotteryConfig) =>
        state.selectedNumbers[lotteryId] || getInitialSelectedNumbers(config),

    // state or default
    selectedPerPlayAddOnsByLottery:
      (state) => (lotteryId: string, config: LotteryConfig) =>
        state.selectedPerPlayAddOns[lotteryId] ||
        getInitialPerPlayAddOns(config),
    // state or default
    selectedStakesByLottery:
      (state) => (lotteryId: string, config: LotteryConfig) =>
        state.selectedStakes[lotteryId] || getInitialStakes(config),
    ticketTypeByLottery: (state) => (lotteryId: string) =>
      state.ticketType[lotteryId] || LotteryTicketType.Single,
  },
  persist: {
    serializer: {
      deserialize: (value) => {
        const parsed: LotteryLocalState = JSON.parse(value)
        if (parsed.selectedNumbers) {
          Object.keys(parsed.selectedNumbers).forEach((lotteryId) => {
            parsed.selectedNumbers[lotteryId] = parsed.selectedNumbers[
              lotteryId
            ].map((numbers) => {
              if (numbers.numbers) return numbers as unknown as NumberSelection
              return { isQuickPick: false, numbers: numbers as unknown as [][] }
            })
          })
        }
        return parsed
      },
      serialize: (value) => JSON.stringify(value),
    },
    storage: piniaPluginPersistedstate.localStorage(),
  },
  state: (): {
    confirmedPlays: ConfirmedPlays
    prepaidSubscriptionAmount: PrepaidSubscriptionAmount
    selectedAddOns: LotterySelectedAddOns
    selectedNumbers: SelectedNumbers
    selectedPerPlayAddOns: LotterySelectedPerPlayAddOns
    selectedStakes: LotterySelectedStakes
    ticketType: TicketType
  } => ({
    confirmedPlays: {},
    prepaidSubscriptionAmount: {},
    selectedAddOns: {},
    selectedNumbers: {},
    selectedPerPlayAddOns: {},
    selectedStakes: {},
    ticketType: {},
  }),
})
