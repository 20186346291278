import omit from 'lodash/omit'

export const normalizedSlotData = (data: any) => ({
  ...data,
  setRef: data.setRef ?? (() => {}),
})

export const getClickName = (target: HTMLElement) =>
  target?.getAttribute('data-click-name') ?? undefined

export const getClickNameOfTarget = (event: PointerEvent | UIEvent) => {
  const target = event.target
  if (!(target instanceof HTMLElement)) return

  return getClickName(target)
}

export const getSlot = <T extends LooseObject>(slots: T, name: keyof T) =>
  (slots?.[name]?.() as VNode[])?.[0]

export const removeTrackingProps = <T extends LooseObject>(
  props: T,
): Omit<T, 'tracking'> => omit(props, ['tracking'])
