import type {
  TrackingEvent,
  TrackingEvent2FAOutcome,
  TrackingEventAccountKYC,
  TrackingEventAccountRegistration,
  TrackingEventAccountStatusUpdate,
  TrackingEventAddToCart,
  TrackingEventBeginCheckout,
  TrackingEventBeginCheckoutConfirmation,
  TrackingEventBeginCheckoutFundPayloadParams,
  TrackingEventContentView,
  TrackingEventCustomerData,
  TrackingEventFormAutoSubmit,
  TrackingEventFormInput,
  TrackingEventImpression,
  TrackingEventInitialization,
  TrackingEventLogin,
  TrackingEventPayInPayloadParams,
  TrackingEventPurchase,
  TrackingEventRemoveFromCart,
  TrackingEventUpdatePayment,
  TrackingEventViewItem,
  TrackingEventViewListData,
} from '~/layers/tracking/types/tracking'

const CONTENT_MAX_CHARS = 250

export const useTracking = () => {
  const { gtmEnabled, shop, version } = useRuntimeConfig().public

  const isEnabled = gtmEnabled && import.meta.client
  const APP_NAME = `${shop}-${version}`

  if (isEnabled) window.dataLayer = window.dataLayer || []

  const push = (event: TrackingEvent) => {
    console.info('[TRACKING-DEBUG]', event)

    if (!isEnabled) return

    window.dataLayer = window.dataLayer || []
    customIdleCallback(() => {
      window.dataLayer.push({
        ...event,
        app_name: APP_NAME,
        app_random: Math.random().toString(36).substring(2, 10),
        app_timestamp: Date.now(),
        native_app: useCustomContext().isNativeApp.value,
      })
    })
  }

  const push2FAStartEvent = () => {
    push({ event: '2fa_start' })
  }

  const push2FAOutcomeEvent = (
    payload: Omit<TrackingEvent2FAOutcome, 'event'>,
  ) => {
    push({ event: '2fa_outcome', ...payload })
  }
  const pushAccountKYCEvent = (
    payload: Omit<TrackingEventAccountKYC, 'event'>,
  ) => {
    push({ event: 'kyc', ...payload })
  }

  const pushAccountRegistrationEvent = (
    payload: Omit<TrackingEventAccountRegistration, 'event'>,
  ) => {
    push({ event: 'account_registration', ...payload })
  }

  const pushAccountStatusUpdateEvent = (
    payload: Omit<TrackingEventAccountStatusUpdate, 'event'>,
  ) => {
    push({ event: 'account_status_update', ...payload })
  }

  const pushAddToCartEvent = (
    payload: Maybe<TrackingEventAddToCart['ecommerce']>,
    nextStep?: boolean,
  ) => {
    if (!payload) return
    pushResetEvent()
    push({
      ecommerce: { ...payload },
      event: nextStep ? 'add_to_cart_by_next_step' : 'add_to_cart',
    })
  }

  const pushBeginCheckoutEvent = (
    payload: Maybe<TrackingEventBeginCheckout['ecommerce']>,
  ) => {
    if (!payload) return
    pushResetEvent()
    push({
      checkout: { step_index: 1, step_name: 'cart' },
      ecommerce: { ...payload },
      event: 'begin_checkout',
    })
  }

  const pushBeginCheckoutConfirmationEvent = (
    payload: Maybe<TrackingEventBeginCheckoutConfirmation['ecommerce']>,
    paymentMethods: string[],
  ) => {
    if (!payload) return
    pushResetEvent()
    push({
      checkout: {
        payment_methods: paymentMethods,
        step_index: 20,
        step_name: 'checkout_confirmation',
      },
      ecommerce: { ...payload },
      event: 'begin_checkout',
    })
  }

  const pushBeginCheckoutFundAccountEvent = (
    payload: TrackingEventBeginCheckoutFundPayloadParams,
    paymentMethods: string[],
  ) => {
    if (!payload) return
    pushResetEvent()

    push({
      checkout: {
        payment_methods: paymentMethods,
        step_index: 15,
        step_name: 'fund_account',
      },
      ecommerce: {
        currency: payload.currency,
        items: [
          {
            convenience_fee: payload.convenience_fee,
            currency: payload.currency,
            item_category: 'account_funding',
            item_id: 'account_funding',
            item_name: 'Account Funding',
            price: payload.price,
            quantity: 1,
            tax: 0,
          },
        ],
        value: payload.value,
      },
      event: 'begin_checkout',
    })
  }
  const pushCheckoutRemovePromoCodeEvent = () => {
    push({ event: 'checkout_remove_promo_code' })
  }

  const pushCheckoutStartAddPromoCodeEvent = () => {
    push({ event: 'checkout_start_add_promo_code' })
  }

  const pushCheckoutSubmitPromoCodeEvent = () => {
    push({ event: 'checkout_submit_promo_code' })
  }

  const pushContentViewEvent = (
    payload: Omit<TrackingEventContentView, 'event' | 'page_login_status'> & {
      isLoggedIn?: boolean
    },
  ) => {
    const { isLoggedIn, ...rest } = payload
    const pageLoginStatus = isLoggedIn ? 'logged_in' : 'logged_out'

    push({ event: 'content-view', ...rest, page_login_status: pageLoginStatus })
  }

  const pushCustomerDataEvent = async (
    payload: Omit<TrackingEventCustomerData, 'emh' | 'event'> & {
      email: string
    },
  ) => {
    const { email, ...rest } = payload
    const emh = await sha256(email)

    push({ event: 'c_data', ...rest, emh })
  }

  const pushFormAutoSubmitEvent = (
    payload: Omit<TrackingEventFormAutoSubmit, 'event' | 'finput_action'>,
  ) => {
    push({ event: 'forminput', finput_action: 'auto_submit', ...payload })
  }

  const pushFormInputEvent = (
    payload: Omit<TrackingEventFormInput, 'event'>,
  ) => {
    push({ event: 'forminput', ...payload })
  }

  const pushInitializationEvent = (
    payload: Omit<TrackingEventInitialization, 'event'>,
  ) => {
    push({ event: 'init', ...payload })
  }

  const pushImpressionEvent = (
    payload: Omit<TrackingEventImpression, 'event'>,
  ) => {
    let topic = payload.topic
    if (topic) topic = truncate(topic, CONTENT_MAX_CHARS).trim()

    let message = payload.message
    if (message) message = truncate(message, CONTENT_MAX_CHARS).trim()

    push({ event: 'impression', ...payload, message, topic })
  }

  const pushLoginEvent = (payload: Omit<TrackingEventLogin, 'event'>) => {
    push({ event: 'login', ...payload })
  }

  const pushPayInEvent = (payload: TrackingEventPayInPayloadParams) => {
    if (!payload) return
    pushResetEvent()

    push({
      ecommerce: {
        currency: payload.currency,
        first_transaction: payload.first_transaction,
        items: [
          {
            convenience_fee: payload.convenience_fee,
            currency: payload.currency,
            item_category: 'account_funding',
            item_id: 'account_funding',
            item_name: 'Account Funding',
            price: payload.price,
            quantity: 1,
            tax: 0,
          },
        ],
        pay_in_id: payload.pay_in_id,
        value: payload.value,
      },
      event: 'pay_in',
    })
  }

  const pushRemoveFromCartEvent = (
    payload: Maybe<TrackingEventRemoveFromCart['ecommerce']>,
  ) => {
    if (!payload) return
    pushResetEvent()
    push({
      ecommerce: { ...payload },
      event: 'remove_from_cart',
    })
  }

  const pushResetEvent = () => {
    push({ checkout: null, ecommerce: null, event: 'reset' })
  }

  const pushUpdatePaymentEvent = (
    payload: Maybe<Omit<TrackingEventUpdatePayment, 'event'>>,
  ) => {
    if (!payload) return
    push({
      ...payload,
      event: 'update_payment',
    })
  }

  const pushViewItemEvent = (
    payload: Maybe<TrackingEventViewItem['ecommerce']>,
  ) => {
    if (!payload) return
    pushResetEvent()
    push({
      ecommerce: { ...payload },
      event: 'view_item',
    })
  }

  const pushPurchaseEvent = (payload: Omit<TrackingEventPurchase, 'event'>) => {
    pushResetEvent()
    push({ event: 'purchase', ...payload })
  }

  const pushSelectListItemEvent = (payload: TrackingEventViewListData) => {
    pushResetEvent()

    if (payload.items.length > 1) {
      const first = payload.items[0]
      payload.items = [first]
    }

    push({ ecommerce: { ...payload }, event: 'select_list_item' })
  }

  const pushViewListEvent = (payload: TrackingEventViewListData) => {
    pushResetEvent()
    push({ ecommerce: { ...payload }, event: 'view_list' })
  }

  return {
    push2FAOutcomeEvent,
    push2FAStartEvent,
    pushAccountKYCEvent,
    pushAccountRegistrationEvent,
    pushAccountStatusUpdateEvent,
    pushAddToCartEvent,
    pushBeginCheckoutConfirmationEvent,
    pushBeginCheckoutEvent,
    pushBeginCheckoutFundAccountEvent,
    pushCheckoutRemovePromoCodeEvent,
    pushCheckoutStartAddPromoCodeEvent,
    pushCheckoutSubmitPromoCodeEvent,
    pushContentViewEvent,
    pushCustomerDataEvent,
    pushFormAutoSubmitEvent,
    pushFormInputEvent,
    pushImpressionEvent,
    pushInitializationEvent,
    pushLoginEvent,
    pushPayInEvent,
    pushPurchaseEvent,
    pushRemoveFromCartEvent,
    pushResetEvent,
    pushSelectListItemEvent,
    pushUpdatePaymentEvent,
    pushViewItemEvent,
    pushViewListEvent,
  }
}
