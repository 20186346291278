import type { UserDataQuery } from '~/@types/generated/backend/graphql-schema-types'

const CUSTOM_PARAMETERS = {
  PlayerId: '_pxParam2',
} as const

type CustomParameter = keyof typeof CUSTOM_PARAMETERS

const setCustomParameter = (key: CustomParameter, value: Maybe<string>) => {
  window[CUSTOM_PARAMETERS[key]] = value
}

export default defineNuxtPlugin({
  parallel: true,
  setup: async () => {
    const { humanEnabled } = useRuntimeConfig().public
    const { name } = useRoute()

    if (String(humanEnabled) !== 'true' || name === 'status-health') return

    const { log } = useDatadog()
    const APP_ID = 'PXCCr2bNjI'

    const logoImgSrc = import.meta.glob<string>(
      '~/assets/img/brand/logo-default.svg',
      {
        eager: true,
        import: 'default',
      },
    )['/assets/img/brand/logo-default.svg']

    window._pxCustomLogo = logoImgSrc

    try {
      await scriptGenerator({
        options: {
          async: true,
          customAdd: (element: HTMLScriptElement) => {
            const firstScript = document.getElementsByTagName('script')[0]

            if (firstScript.parentNode) {
              firstScript.parentNode.insertBefore(element, firstScript)
            } else {
              document.head.appendChild(element)
            }
          },
        },
        src: `//client.px-cloud.net/${APP_ID}/main.min.js`,
      })
    } catch (error) {
      log('error', {
        message: 'Failed to load HUMAN sensor script',
        messageContext: { error },
      })
      return
    }

    window[`_${APP_ID}`] = {
      challenge: {
        context: { logoImgSrc },
      },
    }

    // Add Custom Parameters
    const { isLoggedIn } = useAuth()
    const { $apollo } = useNuxtApp()

    watchImmediate(isLoggedIn, async (isUserLoggedIn) => {
      if (!isUserLoggedIn) return setCustomParameter('PlayerId', null)

      try {
        const { data } = await $apollo.defaultClient.query<UserDataQuery>({
          errorPolicy: 'ignore',
          fetchPolicy: 'cache-first',
          query: UserDataDocument,
        })

        setCustomParameter('PlayerId', data.player?.id ?? null)
      } catch {
        // Do nothing
      }
    })
  },
})
