import {
  type CheckScratchcardOrderQuery,
  type OrderTypeQuery,
  PlaySlipType,
} from '~/@types/generated/backend/graphql-schema-types'

export const ORDER_TYPES = {
  Scratchcard: 'scratchcard',
  SingleOrder: 'single_order',
  Subscriptions: 'subscription',
} as const

export const useOrderType = () => {
  const {
    $apollo: { defaultClient },
  } = useNuxtApp()

  const getLotteryOrderType = async (orderId: string) => {
    try {
      const response = await defaultClient.query<OrderTypeQuery>({
        fetchPolicy: 'no-cache',
        query: OrderTypeDocument,
        variables: { orderId },
      })
      if (!response) return false

      return [PlaySlipType.FreeTicket, PlaySlipType.Normal].includes(
        response.data.orderType.type,
      )
        ? ORDER_TYPES.SingleOrder
        : ORDER_TYPES.Subscriptions
    } catch {
      return false
    }
  }

  const isScratchcardOrderType = async (orderId: string) => {
    try {
      const response = await defaultClient.query<CheckScratchcardOrderQuery>({
        fetchPolicy: 'no-cache',
        query: ScratchcardOrderDocument,
        variables: { orderId },
      })

      return !!response
    } catch {
      return false
    }
  }

  const getOrderType = async (orderId: string) => {
    const lotteryOrderType = await getLotteryOrderType(orderId)
    if (lotteryOrderType) return lotteryOrderType

    const isScratchcardOrder = await isScratchcardOrderType(orderId)
    if (isScratchcardOrder) return ORDER_TYPES.Scratchcard

    return null
  }

  return { getOrderType }
}
