import type { Toast } from '~/composables/toasterIsolated'

import { isNuxtServer } from '~/utils/context'

const clientToasts = ref<Toast[]>([])

/**
 * Handles global toasts
 */
export const useToaster = () => {
  const serverToasts = useState<Toast[]>(() => [])

  const toaster = useToasterIsolated(
    isNuxtServer() ? serverToasts : clientToasts,
  )

  const toasts = computed(() =>
    isNuxtServer() ? serverToasts.value : clientToasts.value,
  )

  onMounted(() => {
    if (serverToasts.value.length <= 0) return

    clientToasts.value = [...serverToasts.value, ...clientToasts.value]
    serverToasts.value = []
  })

  return { ...toaster, toasts }
}
