import payload_plugin_SnWBCfJinG from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_patch_hash=6ac14bae10e6750613b2eb98e1f798f_b76ed9433db24812dc2b3dd016546db5/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_K11wfJqeBx from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JF6uy1Qxs6 from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mqrRd4W31h from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Oy2gnDmAho from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.34.4_vite@6.1.0_@types+node@22.13.1_jiti_687e1f0910f01341558fc0e18bc39bbd/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_tVdVa2L8T0 from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_D4kg5vVoue from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Dhrc28zTNu from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Z1JHZmUCOP from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_AFSbvGEqfS from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_patch_hash=6ac14bae10e6750613b2eb98e1f798f_b76ed9433db24812dc2b3dd016546db5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/lottocom/shop-frontend/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_Elz5GOdgzA from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/lottocom/shop-frontend/frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_eoUUI9qfDX from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.34.4_vite@6.1.0_@types+node@22.13.1_jiti@_0647e9dc3be7a6034e773daaad0b4072/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_bt5S5NZtPt from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_patch_ha_460a9ef4cbacd084a715833bfd1449b8/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_syGloDX93i from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt-csurf@1.6.5_magicast@0.3.5/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/builds/lottocom/shop-frontend/frontend/.nuxt/unocss.mjs";
import switch_locale_path_ssr_2SZHB3QlPr from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_96728b28a914419506c5e741a0bb33d1/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_dPssXW85kT from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_96728b28a914419506c5e741a0bb33d1/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_A7TjLYkKwJ from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_96728b28a914419506c5e741a0bb33d1/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import _01_trackingScript_client_M3dMklmLOB from "/builds/lottocom/shop-frontend/frontend/layers/tracking/plugins/01.trackingScript.client.ts";
import _02_trackInitialization_client_oO4LSbZZAX from "/builds/lottocom/shop-frontend/frontend/layers/tracking/plugins/02.trackInitialization.client.ts";
import _03_trackPageNavigation_client_exgFZLCoEb from "/builds/lottocom/shop-frontend/frontend/layers/tracking/plugins/03.trackPageNavigation.client.ts";
import _04_trackInitialAccountStatus_client_s1gGznNDrH from "/builds/lottocom/shop-frontend/frontend/layers/tracking/plugins/04.trackInitialAccountStatus.client.ts";
import resetTrackingFormField_client_BrDm0UcBjL from "/builds/lottocom/shop-frontend/frontend/layers/tracking/plugins/resetTrackingFormField.client.ts";
import frontendComponents_GGPNEaEnXB from "/builds/lottocom/shop-frontend/frontend/layers/frontend-components/plugins/frontendComponents.ts";
import _01_hooks_2rZyncDMMI from "/builds/lottocom/shop-frontend/frontend/plugins/01.hooks.ts";
import _02_polyfill_client_r084uyBewR from "/builds/lottocom/shop-frontend/frontend/plugins/02.polyfill.client.ts";
import _10_apollo_Sxw8Ifvyki from "/builds/lottocom/shop-frontend/frontend/plugins/10.apollo.ts";
import datadog_client_cfAAU11B0P from "/builds/lottocom/shop-frontend/frontend/plugins/datadog.client.ts";
import human_client_hpwxaqW1bb from "/builds/lottocom/shop-frontend/frontend/plugins/human.client.ts";
import marketing_client_qaYJZ8lssm from "/builds/lottocom/shop-frontend/frontend/plugins/marketing.client.ts";
import warnHandler_client_kXDsB7XDTC from "/builds/lottocom/shop-frontend/frontend/plugins/warnHandler.client.ts";
import yup_OM8D6DMeq7 from "/builds/lottocom/shop-frontend/frontend/plugins/yup.ts";
import ssg_detect_SVIO8iti6d from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_96728b28a914419506c5e741a0bb33d1/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_SnWBCfJinG,
  revive_payload_client_K11wfJqeBx,
  unhead_JF6uy1Qxs6,
  router_mqrRd4W31h,
  _0_siteConfig_Oy2gnDmAho,
  payload_client_tVdVa2L8T0,
  navigation_repaint_client_D4kg5vVoue,
  check_outdated_build_client_Dhrc28zTNu,
  chunk_reload_client_Z1JHZmUCOP,
  plugin_vue3_AFSbvGEqfS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Elz5GOdgzA,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_eoUUI9qfDX,
  plugin_bt5S5NZtPt,
  plugin_syGloDX93i,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_2SZHB3QlPr,
  route_locale_detect_dPssXW85kT,
  i18n_A7TjLYkKwJ,
  plugin_wy0B721ODc,
  _01_trackingScript_client_M3dMklmLOB,
  _02_trackInitialization_client_oO4LSbZZAX,
  _03_trackPageNavigation_client_exgFZLCoEb,
  _04_trackInitialAccountStatus_client_s1gGznNDrH,
  resetTrackingFormField_client_BrDm0UcBjL,
  frontendComponents_GGPNEaEnXB,
  _01_hooks_2rZyncDMMI,
  _02_polyfill_client_r084uyBewR,
  _10_apollo_Sxw8Ifvyki,
  datadog_client_cfAAU11B0P,
  human_client_hpwxaqW1bb,
  marketing_client_qaYJZ8lssm,
  warnHandler_client_kXDsB7XDTC,
  yup_OM8D6DMeq7,
  ssg_detect_SVIO8iti6d
]