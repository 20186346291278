import { RetryLink } from '@apollo/client/link/retry'

import type { RepeatOptions } from '~/apollo/links'

export const retryLink = new RetryLink({
  attempts: (count, operation, error): boolean => {
    const context = operation.getContext()
    const repeatCount =
      (context as RepeatOptions).repeatOptions?.repeatCount ?? 5
    if (error && count === repeatCount) {
      operation.setContext({ ...context, repeatCountReached: true })
    }
    return !!error && count <= repeatCount
  },
  delay: (_, operation): number => {
    const { repeatOptions } = operation.getContext() as RepeatOptions
    const delay = repeatOptions?.delay ?? 500

    return repeatOptions?.fixedDelay ? delay : delay * Math.random()
  },
})
