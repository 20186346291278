<template>
  <div class="layout-error flex flex-col">
    <TheHeader variant="logo-only" />
    <main
      class="content-wrap flex flex-1 flex-col items-center mt-lg px-md gap-lg"
    >
      <div class="text-center">
        <h1 class="text-h4 mb-md">
          {{ $t(`error.page.${errorType}.title`) }}
        </h1>
        <p>
          {{ $t(`error.page.${errorType}.text`) }}
        </p>
      </div>

      <Illustration :name="`error-${errorType}`" />

      <i18n-t
        v-if="isNotFound"
        class="text-center"
        keypath="error.page.notfound.prompt"
        scope="global"
        tag="span"
      >
        <template #supportEmail>
          <HyperLink class="block text-link" :to="getMailTo()">
            {{ supportEmail }}
          </HyperLink>
        </template>
      </i18n-t>
      <LcButton v-else @click="handleError">
        {{ $t('error.page.general.button.reload') }}
      </LcButton>
    </main>

    <TheBottomNavigation />
  </div>
</template>

<script lang="ts" setup>
import type { NuxtError } from 'nuxt/app'

import { LcButton } from '@lottocom/frontend-components'

type ErrorProps = {
  error: NuxtError
}

const props = defineProps<ErrorProps>()

const { getMailTo, supportEmail } = useSupportMail()
const { t } = useI18n()

const { log } = useDatadog()

const handleError = () => {
  clearError({ redirect: '/' })
}

onMounted(() => {
  try {
    log('error', {
      component: 'error-page',
      error: props.error,
      message: props.error.message,
      messageContext: {
        url: window.location.href,
      },
    })
  } catch {
    // Do nothing
  }
})

const errorType = computed(() =>
  props.error.statusCode === 404 ? 'notfound' : 'general',
)
const isNotFound = computed(() => errorType.value === 'notfound')

useHead({
  title: t(`error.page.${errorType.value}.title`),
})
</script>

<style lang="scss" scoped>
.layout-error {
  min-height: 100dvh;
  padding-bottom: $main-bottom-navigation-height;
}
</style>
