export const useScratchcardGame = (_slug?: string) => {
  const licensedTerritory = useLicensedTerritory({ raw: true })
  const slug = computed(() => {
    const { params, query } = useRoute()
    return _slug ?? params.slug ?? query.p ?? ''
  })

  const queryResult = useScratchcardGameQuery({
    licensedTerritory,
    slug: slug.value,
  })

  const { currentPrice } = useScratchcardStore()

  const availableCards = computed(() => game.value?.available || 0)
  const game = computed(() => queryResult.result.value?.scratchcardGame)
  const isLoading = computed(() => queryResult.loading.value)
  const name = computed(() => game.value?.name)
  const priceList = computed(() => game.value?.priceList)
  const prizeTiers = computed(() => game.value?.prizeTiers)
  const jackpot = computed(() =>
    prizeTiers.value?.find((prizeTier) => prizeTier.tier === 1),
  )
  const visualParameters = computed(() => game.value?.visualParameters)

  return {
    availableCards,
    currentPrice,
    game,
    isLoading,
    jackpot,
    name,
    priceList,
    prizeTiers,
    visualParameters,
  }
}
