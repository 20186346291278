import { cache } from '~/apollo/local/cache'
import { PersistedState } from '~/apollo/local/types'

export const useCachedProduct = (productName?: string) => {
  const licensedTerritory = useLicensedTerritory()
  const { currentRoute } = useRouter()

  const product = ref(productName || getProductId(currentRoute.value))

  const currentProductId = ref(
    getCachedProductId(licensedTerritory, product.value ?? ''),
  )

  const productId = computed(() => currentProductId.value)

  const deleteProductId = () => {
    const { deleteCache } = cache()
    deleteCache(PersistedState.ProductId, {
      licensedTerritory,
      product: product.value,
    })
    currentProductId.value = null
  }

  const setProductId = (value: string) => {
    const { updateCache } = cache()
    updateCache(PersistedState.ProductId, value, {
      licensedTerritory,
      product: product.value,
    })
    currentProductId.value = value
  }

  watchEffect(() => {
    const newProductId = getCachedProductId(
      licensedTerritory,
      product.value ?? '',
    )

    if (newProductId === currentProductId.value) return
    currentProductId.value = newProductId
  })

  return {
    deleteProductId,
    product: readonly(product),
    productId,
    setProductId,
  }
}
