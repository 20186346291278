import { createHttpLink, from, Observable } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { repeatableLink, retryLink, timeoutLink } from '~/apollo/links'
import { splitTransferLinks } from '~/apollo/utils/split-transfer-links'
import { createWsLink } from '~/apollo/websocket'

/*
Hopefully in a future version of nuxtjs/apollo the workaround with plugin/apollo.ts won't be needed anymore for multi environment setups.
Best attempt would be to use browserHttpEndpoint and httpEndpoint in here with values from runtime config
 */

export const createDefaultLinks = (
  nuxtApp: any,
  uri: string,
  wsUri: string,
) => {
  const { accessToken } = useAuth()
  const { isNativeApp } = useCustomContext()

  const {
    public: { version },
  } = useRuntimeConfig()
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...(accessToken.value
        ? { Authorization: `Bearer ${accessToken.value}` }
        : {}),
      'x-mobile-app': isNativeApp.value,
      'x-shop': `${isNativeApp.value ? 'sfm' : 'sfe'}-${version}`,
    },
  }))

  const errorLink = onError(({ forward, graphQLErrors, operation }) => {
    if (
      !graphQLErrors?.some((err) => err.extensions?.code === 'UNAUTHENTICATED')
    )
      return

    const { accessToken } = useAuth()
    const { mutate } = useRefreshTokenMutation({ clientId: 'auth' })

    return new Observable((subscriber) => {
      mutate()
        .then((result) => {
          if (!result?.data) return

          const { refreshToken } = result.data
          if (refreshToken) {
            accessToken.value = refreshToken.accessToken
            const oldHeaders = operation.getContext().headers
            operation.setContext({
              headers: {
                ...oldHeaders,
                Authorization: `Bearer ${refreshToken.accessToken}`,
              },
            })
          }

          if (subscriber.closed) return
          subscriber.next(!!refreshToken)
          subscriber.complete()
        })
        .catch((error) => {
          subscriber.error(error)
        })
    }).flatMap(() => forward(operation))
  })

  const httpLink = createHttpLink({
    credentials: 'include',
    headers: {
      ...(import.meta.server && {
        cookie: nuxtApp.ssrContext?.event.context.cookie,
      }),
    },
    uri,
  })

  const wsLink = createWsLink(wsUri)
  const requestLink = timeoutLink.concat(httpLink)

  return from([
    authLink,
    retryLink,
    repeatableLink,
    errorLink,
    wsLink ? splitTransferLinks(wsLink, requestLink) : requestLink,
  ])
}
