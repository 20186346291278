export type MoneyFormatterOptions = {
  fractionDigits?: number
}

type Money = { currency: string; value: number }

export const formatMoney = (amount: Money, options?: MoneyFormatterOptions) =>
  new Intl.NumberFormat('en-US', {
    currency: amount.currency,
    maximumFractionDigits: options?.fractionDigits ?? 2,
    minimumFractionDigits: options?.fractionDigits ?? 2,
    style: 'currency',
  }).format(normalizeMoney(amount))

export const normalizeMoney = (amount: Money) => amount.value / 100
