import type { StateSwitchOption } from '~/features/TheHeader/types'

export const useStateSwitch = () => {
  const { t } = useI18n()
  const { currentRoute } = useRouter()
  const { hiddenLicensedTerritories, licensedTerritories, licensedTerritory } =
    useRuntimeConfig().public

  const isStateSwitchRedirectModalVisible = ref(false)

  const toStateSwitchOptions = (value: string): StateSwitchOption => {
    const [id, uri] = value.split(':')

    const state = t(`licensedterritory.${id}`)

    return {
      href: `https://${uri}${currentRoute.value.path}?ts=${id}`,
      id,
      selected: id === licensedTerritory,
      state,
      title: t('stateswitch.link.title', {
        state,
      }),
    }
  }

  const filterHiddenLicensedTerritories = (value: string) =>
    !(hiddenLicensedTerritories.split(',') ?? []).includes(
      value.substring(0, 5),
    )

  const options = computed<StateSwitchOption[]>(() =>
    licensedTerritories
      .split(',')
      .filter(filterHiddenLicensedTerritories)
      .map(toStateSwitchOptions),
  )

  return {
    isStateSwitchRedirectModalVisible,
    licensedTerritory,
    options,
  }
}
