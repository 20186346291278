import type { UserDataQuery } from '~/@types/generated/backend/graphql-schema-types'

export default defineNuxtPlugin(() => {
  if (import.meta.server || !useRuntimeConfig().public.gtmEnabled!) return

  const { isLoggedIn } = useAuth()
  const nuxtApp = useNuxtApp()
  const { pushAccountStatusUpdateEvent } = useTracking()
  const { createAccountStatus } = useTrackingData()
  const { logTrackingError } = useTrackingErrorLog()

  const invokeTrackAccountStatus = async () => {
    const {
      data: { player },
    } = await nuxtApp.$apollo.defaultClient.query<UserDataQuery>({
      fetchPolicy: 'cache-first',
      query: UserDataDocument,
    })

    pushAccountStatusUpdateEvent({
      account_reg_status: createAccountStatus(
        player.status,
        player.verifications,
        player.phone.status,
      ),
    })
  }

  const removeHook = nuxtApp.hook('page:finish', async () => {
    try {
      if (!isLoggedIn.value) return
      await invokeTrackAccountStatus()
    } catch (error) {
      logTrackingError('account_status_update', error)
    } finally {
      removeHook()
    }
  })
})
