<template>
  <img
    v-if="imageProps && variant === 'image'"
    v-bind="imageProps"
    :alt="$t(`lottery.${lotteryId}`)"
    :class="['lottery-logo', 'image', size]"
  />
  <span
    v-else
    :class="['lottery-logo', 'text', size, { 'long-name': hasLongName }]"
    :style="theme"
  >
    <span>
      <slot>
        {{ $t(`lottery.${lotteryId}`) }}
      </slot>
    </span>
  </span>
</template>

<script lang="ts" setup>
import type { LotteryId } from '~/@types/generated/backend/graphql-schema-types'

type LotteryLogoProps = {
  licensedTerritory: string
  lotteryId: LotteryId
  size?: 'fluid' | 'large' | 'medium' | 'small'
  variant?: 'image' | 'text'
}

const props = withDefaults(defineProps<LotteryLogoProps>(), {
  size: 'medium',
  variant: 'image',
})

const { getLotteryLogo } = useAsset()
const { t } = useI18n()

const hasLongName = computed(() => t(`lottery.${props.lotteryId}`).length >= 20)
const imageProps = computed(() =>
  getLotteryLogo(props.licensedTerritory, props.lotteryId),
)
const theme = computed(() => {
  const { createCssVar } = getLotteryCssVarConstructor(
    props.licensedTerritory,
    props.lotteryId,
  )

  return {
    '--lottery-logo-color': createCssVar('color-primary'),
  }
})
</script>

<style lang="scss" scoped>
.lottery-logo {
  &.image {
    width: auto;
    max-width: unset;

    &.fluid {
      max-width: 100%;
    }

    &.large {
      max-height: 3rem;
    }

    &.medium,
    &.small {
      max-height: 2.25rem;
    }
  }

  &.text {
    display: inline-flex;
    align-items: center;
    height: 2rem;
    color: var(--lottery-logo-color);
    font-weight: $font-weight-medium;
    line-height: 1;
    text-transform: uppercase;
    word-break: break-word;

    &.fluid {
      width: 100%;
      container-type: inline-size;

      > span {
        font-size: clamp(1rem, 10cqi, $font-size-h3);
      }

      &.long-name {
        > span {
          font-size: clamp(1rem, 7.5cqi, $font-size-h3);
        }
      }
    }

    &.large {
      font-size: $font-size-h3;
    }

    &.medium {
      font-size: $font-size-h4;
    }

    &.small {
      font-size: $font-size-caption;
    }
  }
}
</style>
