export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:beforeMount', () => {
    ;(function () {
      const originalWarn = console.warn

      function customWarn(message: string) {
        console.warn = originalWarn

        if (/use(Lazy)?Query/.test(message)) {
          useDatadog().log('warn', {
            message,
            messageContext: { useQuery: { url: useRoute().fullPath } },
          })
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line prefer-rest-params
        originalWarn.apply(console, arguments)

        console.warn = customWarn
      }

      console.warn = customWarn
    })()
  })
})
