import type { UserDataQuery } from '~/@types/generated/backend/graphql-schema-types'
import type { TrackingEnvironment } from '~/layers/tracking/types/tracking'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig().public

  if (import.meta.server || !config.gtmEnabled!) return

  const env = config.sentry.environment as TrackingEnvironment

  const { pushCustomerDataEvent, pushInitializationEvent } = useTracking()

  const { logTrackingError } = useTrackingErrorLog()
  const { isNativeApp } = useCustomContext()
  const { isLoggedIn } = useAuth()
  const flutter = useFlutter()

  const nonce = useSafeNonce()

  const nuxtApp = useNuxtApp()
  nuxtApp.hook('app:beforeMount', async () => {
    const adjustAttributes = flutter?.getAdjustAttributes()
    const adjustToken = flutter?.getAdjustToken()

    pushInitializationEvent({
      env,
      native_app: isNativeApp.value,
      nonce,
      originalLocation: window.location.href,
      ...(adjustAttributes && { app_adjust_attributes: adjustAttributes }),
      ...(adjustToken && { app_adjust_token: adjustToken }),
    })

    if (!isLoggedIn.value) return

    try {
      const {
        data: { player },
      } = await nuxtApp.$apollo.defaultClient.query<UserDataQuery>({
        fetchPolicy: 'cache-first',
        query: UserDataDocument,
      })

      await pushCustomerDataEvent({
        cstid: player.customerId,
        email: player.email,
        env,
        user_name: player.givenName,
        user_surname: player.familyName,
      })
    } catch (error) {
      logTrackingError('c_data', error)
    }
  })
})
