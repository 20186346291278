<template>
  <LcToastOriginal
    v-bind="{ ...sanitizedProps, ...$attrs }"
    :data-containerid="containerId"
  >
    <template v-for="(_, name) in slots">
      <slot :name="name" />
    </template>
  </LcToastOriginal>
</template>

<script lang="ts" setup>
import {
  LcToast as LcToastOriginal,
  type LcToastProps,
  type LcToastSlots,
} from '@lottocom/frontend-components'

import type { TrackingImpressionProps } from '~/layers/tracking/types/tracking'

const props = defineProps<LcToastProps & TrackingImpressionProps>()
const slots = defineSlots<LcToastSlots>()

const { pushImpressionEvent } = useTracking()
const { getVNodeTextContent } = useTrackingComponent()
const { log } = useDatadog()

const containerId = computed(() => `toast_message_${props.variant ?? 'info'}`)

const sanitizedProps = computed(() => removeTrackingProps(props))

onMounted(() => {
  try {
    pushImpressionEvent({
      message:
        props.tracking?.customMessage ??
        props.text ??
        getVNodeTextContent(getSlot(slots, 'default')),
      topic: props.tracking?.customTopic ?? props.headline,
      topic_key: props.tracking?.topicKey,
      type: 'toast',
      ...(props.tracking?.action ? { action: props.tracking.action } : {}),
    })
  } catch (error) {
    log('warn', {
      component: 'LcToast.vue',
      error,
      message: 'pushImpressionEvent failed',
    })
  }
})
</script>
