import { EventEmitter } from 'events'

export enum BroadcastMessage {
  ClearScratchcardOrdersCache = 'event:clear:scratchcard:orders:cache',
  ClearSingleOrdersCache = 'event:clear:single:orders:cache',
  ClearSubscriptionOrdersCache = 'event:clear:subscription:orders:cache',
  ClearTransactionsCache = 'event:clear:transactions:cache',
  ContextChange = 'event:context:change',
}

class Broadcaster extends EventEmitter {
  private channel

  constructor() {
    super()
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    try {
      this.channel = new BroadcastChannel('ltc-channel')
      this.channel.onmessage = ({ data }) => {
        self.emit(data)
      }
    } catch {
      this.close()
    }
  }

  close() {
    this.channel?.close()
  }

  override on(
    eventName: string | symbol,
    listener: (...args: any[]) => void,
  ): this {
    if (import.meta.server) return this
    super.on(eventName, listener)
    return this
  }

  postMessage(message: BroadcastMessage) {
    if (!this.channel) return
    this.channel.postMessage(message)
  }
}

const broadcasterInstance = new Broadcaster()

export default broadcasterInstance
