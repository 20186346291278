import type { ValueOf } from 'type-fest'
import type { MetaFlat } from 'zhead'

import type {
  Asset,
  OpenGraph,
} from '~/@types/generated/cms/graphql-schema-types'

type OgTags = Partial<PickStartsWith<MetaFlat, 'og'>>
type TwitterTags = Partial<PickStartsWith<MetaFlat, 'twitter'>>

const isAsset = (property: ValueOf<OpenGraph>): property is Asset =>
  (property as Asset).__typename !== 'Asset'

export const useSeo = async (path: string) => {
  const licensedTerritory = useLicensedTerritory()
  const {
    i18nConfig: { shopPublicName, stateName },
    twitterUserName,
  } = useRuntimeConfig().public

  const { data } = await useContentfulContent().getSeo()
  const seo = data.value?.items.find((item) => item.url === path)

  const links = computed(() => [
    seo?.canonical
      ? { href: replacePlaceholders(seo.canonical), rel: 'canonical' }
      : {},
    {
      content: robots,
      name: 'robots',
      vmid: 'robots',
    },
  ])

  const openGraphTags = computed<OgTags>(() => {
    const defaultTags: OgTags = {
      ogSiteName: shopPublicName,
      ogTitle: title.value,
    }
    if (!seo?.openGraph) return defaultTags

    return Object.entries(seo.openGraph)
      .filter(([key]) => key !== '__typename')
      .reduce((tags, entry) => generateTags(tags, entry, 'og'), defaultTags)
  })

  const robots = computed(() => {
    if (seo?.robots) return seo.robots

    // TODO Add config for restricted routes
    return 'index, follow'
  })

  const title = computed(() => {
    if (!seo?.meta?.title) return shopPublicName
    // TODO Remove after suffix has been removed from contentful entries
    return replacePlaceholders(seo.meta.title).replaceAll(' - Lotto.com', '')
  })

  const twitterTags = computed<TwitterTags>(() => {
    const defaultTags: TwitterTags = {
      twitterCard: 'summary',
      twitterSite: twitterUserName,
    }
    if (!seo?.openGraph) return defaultTags

    return Object.entries(seo.openGraph)
      .filter(([key]) => !['__typename', 'type'].includes(key))
      .reduce(
        (tags, entry) => generateTags(tags, entry, 'twitter'),
        defaultTags,
      )
  })

  const generateTags = <T>(
    tags: T,
    [tag, content]: [string, ValueOf<OpenGraph>],
    prefix: string,
  ) => {
    if (isAsset(content)) {
      delete content.__typename
      Object.values(content).forEach((prop) => {
        if (typeof prop === 'string') replacePlaceholders(prop)
      })
    }

    return {
      ...tags,
      [`${prefix}${toPascalCase(tag)}`]:
        typeof content === 'string' ? replacePlaceholders(content) : content,
    }
  }

  const getReplacementText = (placeholder: string) => {
    switch (placeholder) {
      case 'currentYear':
        return String(useState(() => new Date()).value.getFullYear())
      case 'state':
      case 'stateName':
        return stateName
      case 'subdomain':
        return licensedTerritory.split('-')[1].toLowerCase()
      default:
        return placeholder
    }
  }

  const replacePlaceholders = (toReplace: string) => {
    let placeholder: Maybe<RegExpExecArray>
    const regExp = /\[\[\s*(\w*)\s*\]\]/gm

    while ((placeholder = regExp.exec(toReplace))) {
      toReplace = toReplace.replace(
        placeholder[0],
        getReplacementText(placeholder[1]),
      )
    }
    return toReplace
  }

  return {
    head: {
      link: links,
      titleTemplate: (title?: string) =>
        !!title && title !== 'Lotto.com' ? `${title} - Lotto.com` : 'Lotto.com',
    },
    meta: {
      ...openGraphTags.value,
      ...twitterTags.value,
      description: seo?.meta?.description,
      title,
    },
  }
}
