const POLYFILLS = [
  {
    execute: async () => {
      if (typeof crypto === 'undefined') {
        const gThis = window.globalThis || window || self
        gThis.crypto = {} as Crypto
      }

      const uuid = await import('uuid')
      crypto.randomUUID = () => uuid.v4() as ReturnType<Crypto['randomUUID']>
    },
    isNeeded: () =>
      typeof crypto === 'undefined' ||
      typeof crypto !== 'object' ||
      typeof crypto.randomUUID !== 'function',
  },
]

export default defineNuxtPlugin({
  parallel: false,
  setup: async () => {
    await Promise.all(
      POLYFILLS.filter(({ isNeeded }) => isNeeded()).map(({ execute }) =>
        execute(),
      ),
    )
  },
})
