<template>
  <PriceBreakdownDetailsWrapper
    v-if="currentPrice"
    v-bind="{ showConvenienceFee }"
    class="lottery-price-breakdown-list gap-2xs"
    :convenience-fee="prices.convenienceFee"
  >
    <template v-if="isNumberLottery">
      <PriceListPerPlay v-bind="{ numberOfPlays }">
        {{
          $t('lottery.pricebreakdown.priceperplay.numberlottery', {
            amount: prices.playChargePerPlay,
            name: 'line',
          })
        }}
      </PriceListPerPlay>
      <dd>{{ prices.playChargePerPlaySlip }}</dd>
    </template>

    <template
      v-for="(charge, index) in currentPrice.chargesPerPlay"
      v-else-if="isPickLottery"
      :key="index"
    >
      <dt>
        {{
          getPerPlayTranslation(
            lotteryId,
            perPlayAddOn!.name,
            charge.extraInfo!,
          )
        }}
      </dt>
      <dd>{{ formatMoney(charge.baseCharge) }}</dd>
    </template>

    <template v-for="addOn in addOns" :key="addOn.name">
      <PriceListPerPlay v-if="isNumberLottery" v-bind="{ numberOfPlays }">
        {{
          $t('lottery.pricebreakdown.priceperplay.numberlottery', {
            amount: formatMoney(addOn.baseCharge),
            name: $t(`lottery.addon.${addOn.name}`),
          })
        }}
      </PriceListPerPlay>

      <i18n-t
        v-else
        keypath="lottery.pricebreakdown.priceperplay.picklottery"
        scope="global"
        tag="dt"
      >
        <template #addOn>
          {{ $t(`lottery.addon.${addOn.name}`) }}
        </template>
      </i18n-t>

      <dd>
        {{
          formatMoney({
            ...getChargePerAddon(addOn),
            value: getChargePerAddon(addOn).value,
          })
        }}
      </dd>
    </template>

    <div
      v-if="prepaidSubscriptionAmount"
      class="prepdaid-subscription py-3xs px-2xs rounded-sm mt-sm"
    >
      <dt>
        {{ $t('lottery.pricebreakdown.prepaiddraws') }}
      </dt>
      <dd class="flex justify-end font-medium">
        {{
          $t('lottery.pricebreakdown.prepaiddraws.amount', {
            amount: prepaidSubscriptionAmount,
          })
        }}
      </dd>
    </div>
  </PriceBreakdownDetailsWrapper>
</template>

<script lang="ts" setup>
import type {
  ChargeForAddOn,
  ChargeForPlay,
  Money as MoneyType,
} from '~/@types/generated/backend/graphql-schema-types'

import Money from '~/models/Money'

type LotteryPriceBreakdownListProps = {
  currentPrice?: Maybe<{
    chargesPerPlay: ChargeForPlay[]
    feePerPlaySlip: MoneyType
    numberOfPlays: number
    playChargePerPlaySlip: MoneyType
  }>
  prepaidSubscriptionAmountCheckout?: number
  showConvenienceFee?: boolean
}

const props = defineProps<LotteryPriceBreakdownListProps>()

const {
  isNumberLottery,
  isPickLottery,
  lotteryId,
  perPlayAddOn,
  prepaidSubscriptionAmount: prepaidSubscriptionAmountPlaySlip,
} = usePlaySlip()
const { path } = useRoute()

const { getPerPlayTranslation } = useTranslations()

const addOns = computed(
  () => props.currentPrice?.chargesPerPlay[0]?.addOns ?? [],
)
const numberOfPlays = computed(() => props.currentPrice?.numberOfPlays ?? 0)
const prepaidSubscriptionAmount = computed(() =>
  path.includes('checkout')
    ? props.prepaidSubscriptionAmountCheckout
    : prepaidSubscriptionAmountPlaySlip.value?.value,
)
const prices = computed(() => ({
  convenienceFee: new Money(props.currentPrice?.feePerPlaySlip),
  playChargePerPlay: new Money(
    props.currentPrice?.chargesPerPlay[0]?.baseCharge,
  ),
  playChargePerPlaySlip: new Money(props.currentPrice?.playChargePerPlaySlip),
}))

const getChargePerAddon = (addOn: ChargeForAddOn) => {
  const baseChargeSum = props.currentPrice?.chargesPerPlay
    .filter(
      (charge) => charge.addOns.findIndex((a) => a.name === addOn.name) > -1,
    )
    .reduce((acc, charge) => {
      const baseCharge = charge.addOns.find(
        (add) => add.name === addOn.name,
      )?.baseCharge

      return baseCharge ? acc + baseCharge.value / 100 : acc
    }, 0)

  return new Money(baseChargeSum)
}
</script>

<style lang="scss" scoped>
.lottery-price-breakdown-list {
  .prepdaid-subscription {
    background-color: token('color.green.200');
  }
}
</style>
