import type {
  LocationQuery,
  LocationQueryValue,
  RouteLocationNamedRaw,
  RouteLocationNormalizedGeneric,
  RouteLocationPathRaw,
} from 'vue-router'

type RedirectValue = LocationQueryValue | LocationQueryValue[] | string

const decode = (value: Maybe<LocationQueryValue | LocationQueryValue[]>) => {
  if (!value || Array.isArray(value)) return undefined
  return decodeURIComponent(value)
}

const safeDecode = (
  value: Maybe<LocationQueryValue | LocationQueryValue[]>,
) => {
  try {
    const decoded = decode(value)
    // only allow internal paths
    return decoded && /^\/.*$/.test(decoded) ? decoded : undefined
  } catch {
    return undefined
  }
}

const isEncoded = (value: RedirectValue): value is string => {
  try {
    return decode(value) !== value
  } catch {
    return false
  }
}

export const createRedirectLocation = (
  to: RouteLocationNamedRaw | RouteLocationPathRaw,
  redirect: RedirectValue,
): RouteLocationNamedRaw | RouteLocationPathRaw => {
  const { query, ...rest } = to

  return {
    query: { redirect: encodeRedirectParam(redirect), ...query },
    ...rest,
  }
}

export const decodeRedirectRoute = (route: RouteLocationNormalizedGeneric) =>
  safeDecode(route.query.redirect)

export const decodeRedirectQuery = (query: LocationQuery) =>
  safeDecode(query.redirect)

export const encodeRedirectParam = (value: RedirectValue) => {
  if (!value || Array.isArray(value)) return undefined
  return isEncoded(value) ? value : encodeURIComponent(value)
}
