import type {
  LocationQueryRaw,
  RouteLocationNamedRaw,
  RouteLocationPathRaw,
  RouteLocationRaw,
} from '#vue-router'

export const useRouterUtils = () => {
  const { back, currentRoute, replace } = useRouter()

  const encodedRedirect = computed(() => redirectQuery.value.redirect)

  const decodedRedirect = computed(() =>
    decodeRedirectRoute(currentRoute.value),
  )

  const redirectQuery = computed(() => currentRoute.value.query)

  const navigateBack = async (options?: {
    fallbackRoute?: RouteLocationRaw
    previousRoute?: RouteLocationRaw
  }) => {
    const { previousRoute } = useRoute().meta
    const to = options?.previousRoute || previousRoute

    if (to) {
      await navigateTo(to, { replace: true })
    } else if (window.history.state.back) {
      back()
    } else if (options?.fallbackRoute) {
      await navigateTo(options.fallbackRoute, { replace: true })
    } else {
      await navigateTo('/', { replace: true })
    }
  }

  const navigateToWithRedirect = (
    to: RouteLocationNamedRaw | RouteLocationPathRaw,
  ) => navigateTo(createRedirectLocation(to, encodedRedirect.value))

  const removeQueryParams = (
    query: LocationQueryRaw,
    keyToRemove: string | string[],
  ) =>
    Object.keys(query).reduce<LocationQueryRaw>((acc: LooseObject, key) => {
      if (typeof keyToRemove === 'string' && key !== keyToRemove) {
        acc[key] = query[key]
        return acc
      }

      if (Array.isArray(keyToRemove) && !keyToRemove.includes(key)) {
        acc[key] = query[key]
      }

      return acc
    }, {})

  const removeQueryParam = async (param: string) => {
    const query = { ...useRoute().query }
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete query[param]
    await replace({ query })
  }

  return {
    decodedRedirect,
    encodedRedirect,
    navigateBack,
    navigateToWithRedirect,
    redirectQuery,
    removeQueryParam,
    removeQueryParams,
  }
}
