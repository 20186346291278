<template>
  <LcModalOriginal v-bind="{ ...sanitizedProps, ...$attrs, wrapAttrs }">
    <template v-for="(_, name) in slots" #[name]="slotData">
      <slot :name="name" v-bind="normalizedSlotData(slotData)" />
    </template>
  </LcModalOriginal>
</template>

<script lang="ts" setup>
import {
  LcModal as LcModalOriginal,
  type LcModalProps,
  type LcModalSlots,
} from '@lottocom/frontend-components'
import snakeCase from 'lodash/snakeCase'

import type { TrackingImpressionProps } from '~/layers/tracking/types/tracking'

const props = defineProps<LcModalProps & TrackingImpressionProps>()
const slots = defineSlots<LcModalSlots>()

const { pushImpressionEvent } = useTracking()
const { getVNodeTextContent } = useTrackingComponent()
const { log } = useDatadog()

const wrapAttrs = computed(() => ({
  'data-containerid': snakeCase(`overlay_${props.tracking.topicKey}`),
  ...props.wrapAttrs,
}))

const sanitizedProps = computed(() => removeTrackingProps(props))

watch(
  () => props.isVisible,
  (isVisible) => {
    if (!isVisible) return

    try {
      pushImpressionEvent({
        message:
          props.tracking.customMessage ??
          getVNodeTextContent(getSlot(slots, 'default')),
        topic:
          props.tracking.customTopic ??
          getVNodeTextContent(getSlot(slots, 'header')),
        topic_key: props.tracking.topicKey,
        type: 'modal',
        ...(props.tracking.action ? { action: props.tracking.action } : {}),
      })
    } catch (error) {
      log('warn', {
        component: 'LcModal.vue',
        error,
        message: 'pushImpressionEvent failed',
      })
    }
  },
  { immediate: true },
)
</script>
