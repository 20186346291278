import { datadogRum } from '@datadog/browser-rum'

import type { UserDataQuery } from '~/@types/generated/backend/graphql-schema-types'

export enum StateStorageValue {
  CheckedAuth = 'ltc_checked_auth',
}

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.server) return
  const checkedAuth = useState(StateStorageValue.CheckedAuth, () => false)
  if (checkedAuth.value) return
  const { isLoggedIn } = useAuth()
  if (isLoggedIn.value) return
  const {
    datadog: { enabled: isDatadogEnabled },
  } = useRuntimeConfig().public
  const checkAuth = () =>
    new Promise((resolve) => {
      try {
        const { mutate } = useRefreshTokenMutation()
        mutate()
          .then((result) => {
            const { accessToken } = result?.data?.refreshToken || {}
            if (!accessToken) {
              resolve(false)
            } else {
              useAuth().accessToken.value = accessToken
              const { $apollo } = useNuxtApp()
              $apollo.defaultClient
                .query<UserDataQuery>({ query: UserDataDocument })
                .then(({ data }) => {
                  const { customerId, id, status } = data.player
                  useFlutter()?.setOtherLevelsCustomerId(customerId)
                  if (isDatadogEnabled) {
                    datadogRum.setUser({
                      customerId,
                      id,
                      status,
                    })
                  }
                  resolve(true)
                })
                .catch(resolve)
            }
          })
          .catch(resolve)
          .finally(() => {
            checkedAuth.value = true
          })
      } catch {
        resolve(false)
      }
    })
  await checkAuth()
})
