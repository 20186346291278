import type { Money as MoneyPriceCalculation } from '@lottocom/price-calculation'

import type { Money as MoneyType } from '~/@types/generated/backend/graphql-schema-types'
import type { MoneyFormatterOptions } from '~/utils/money'

export type MoneyInput = Maybe<MoneyPriceCalculation | MoneyType | number>

/**
 * WIP: Not fully transmitted from vue2!
 */

export default class Money {
  currency = 'USD'

  value = 0

  constructor(value?: MoneyInput) {
    if (!value) return

    if (typeof value === 'number') {
      this.value = Number((value * 100).toFixed(0))
    } else {
      this.currency = value.currency
      this.value = value.value
    }
  }

  dividedBy(number: number) {
    if (!number) return this

    this.value /= number
    return this
  }

  times(number: number) {
    this.value *= number
    return this
  }

  toString(options: MoneyFormatterOptions = {}) {
    return formatMoney(this, options)
  }
}
