<template>
  <div>
    <button
      :aria-description="$t('stateswitch.button.arialabel')"
      :aria-label="licensedTerritoryAriaLabel"
      class="state-switch__button flex items-center h-xl"
      type="button"
      @click="isStateSwitchModalVisible = true"
    >
      <LcIcon class="mr-2xs" name="location" />
      <strong>{{ licensedTerritory.replace('US-', '') }}</strong>
      <LcIcon name="chevron-down" />
    </button>

    <TheHeaderStateSwitchModal v-model:is-visible="isStateSwitchModalVisible" />
    <TheHeaderStateSwitchRedirectModal
      v-model:is-visible="isStateSwitchRedirectModalVisible"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  REDIRECT_COOKIE_KEY,
  RedirectType,
  stateSwitchQueryParam,
  stateSwitchRedirectResultQueryParam,
  stateSwitchRedirectTypeQueryParam,
} from '~/features/TheHeader/types'

const { t } = useI18n()
const { addToast } = useToaster()
const { query } = useRoute()
const { replace } = useRouter()
const { removeQueryParams } = useRouterUtils()
const { isStateSwitchRedirectModalVisible, licensedTerritory } =
  useStateSwitch()

const isStateSwitchModalVisible = ref(false)

onMounted(() => {
  let queryParams = []

  if (query[stateSwitchQueryParam]) {
    addToast({
      headline: t('stateswitch.toast.headline', {
        state: t(`licensedterritory.${licensedTerritory}`),
      }),
      message: t('stateswitch.toast.message'),
      tracking: {
        action: 'state_switch',
        topicKey: 'state_switch_success',
      },
      type: 'info',
    })

    queryParams.push(stateSwitchQueryParam)
  }

  if (query[stateSwitchRedirectTypeQueryParam]) {
    const cookie = useCookie<{
      isStateSwitchRedirectModalVisible: Maybe<boolean>
      redirectResult: Maybe<string>
    }>(REDIRECT_COOKIE_KEY, {
      default: () => ({
        isStateSwitchRedirectModalVisible: null,
        redirectResult: null,
      }),
    })

    queryParams = [
      stateSwitchRedirectResultQueryParam,
      stateSwitchRedirectTypeQueryParam,
      ...queryParams,
    ]

    if (query[stateSwitchRedirectTypeQueryParam] === RedirectType.Cloudflare) {
      isStateSwitchRedirectModalVisible.value =
        cookie.value.isStateSwitchRedirectModalVisible ?? true
      cookie.value = {
        isStateSwitchRedirectModalVisible: false,
        redirectResult: String(query[stateSwitchRedirectResultQueryParam]),
      }
    }
  }

  if (queryParams.length) {
    const replaceQuery = removeQueryParams(query, queryParams)
    replace({ query: replaceQuery })
  }
})

const licensedTerritoryAriaLabel = computed(() =>
  t(
    `licensedterritory.${licensedTerritory.replace('-', '').toLocaleLowerCase()}`,
  ),
)
</script>

<style lang="scss" scoped>
.state-switch {
  &__button {
    cursor: pointer;

    @include hover-state {
      color: color('primary500');
    }

    @include focus-state {
      inset: 0.1875rem;
      border-radius: $border-radius-sm;
      box-shadow: 0 0 0 0.1875rem color('info700');
    }
  }
}
</style>
