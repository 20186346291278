const accessToken: Ref<Maybe<string>> = ref(null)

export const useAuth = () => {
  const twoFactorToken = useLocalStorage<Maybe<string>>(
    'two-factor-token',
    null,
  )

  return {
    accessToken,
    isLoggedIn: computed(() => !!accessToken.value),
    twoFactorToken,
  }
}
