import { CUSTOM_MY_ORDERS_ROUTES } from '~/constants/myOrdersRoutes'
export type MyOrdersTabData = { path: string }

export const useMyOrders = async () => {
  const { t } = useNuxtApp().$i18n

  const isScratchcardsEnabled = useIsScratchcardEnabled()

  const response = await useOrderedTypeLazyQuery({
    fetchPolicy: 'network-only',
  }).load()

  const isScratchcardsTabActive = computed(
    () => isScratchcardsEnabled || !!orderedType.value?.scratchcard,
  )
  const orderedType = computed(() => (response ? response.orderedType : null))

  const { activeTabs } = useTabs<MyOrdersTabData>([
    {
      data: { path: CUSTOM_MY_ORDERS_ROUTES.SingleOrders.path },
      icon: 'orders',
      name: CUSTOM_MY_ORDERS_ROUTES.SingleOrders.name,
      title: t('myorders.tabs.singleorder.title'),
    },
    {
      data: { path: CUSTOM_MY_ORDERS_ROUTES.Subscriptions.path },
      icon: 'subscription',
      name: CUSTOM_MY_ORDERS_ROUTES.Subscriptions.name,
      title: t('myorders.tabs.subscription.title'),
    },
    {
      active: isScratchcardsTabActive.value,
      data: { path: CUSTOM_MY_ORDERS_ROUTES.Scratchcards.path },
      icon: 'scratchcard',
      name: CUSTOM_MY_ORDERS_ROUTES.Scratchcards.name,
      title: t('myorders.tabs.scratchcard.title'),
    },
  ])

  return { activeTabs }
}
