<template>
  <div v-if="activeAnnouncement?.active" class="active-announcement">
    <LcMessageBox
      :key="uniqueId('announcement')"
      :headline="activeAnnouncement.title"
      :model-value="!isCollapsed"
      variant="warning"
      @update:model-value="handleUpdate(!$event)"
    >
      <!-- DO NOT CHANGE! PREVENTS WHITE SPACES -->
      <pre class="description">{{ activeAnnouncement.description }}</pre>
    </LcMessageBox>
  </div>
</template>

<script setup lang="ts">
import {
  type StoredAnnouncement,
  useActiveAnnouncementStore,
} from '~/stores/activeAnnouncement'

type Announcement = Omit<StoredAnnouncement, 'collapsed'>

const licensedTerritory = useLicensedTerritory({ raw: true })

const { announcementByKey, deleteAllAnnouncements, setAnnouncementByKey } =
  useActiveAnnouncementStore()

const { onResult: onQueryResult } = useActiveAnnouncementQuery(
  {
    licensedTerritory,
  },
  {
    fetchPolicy: 'no-cache',
  },
)

const { onResult: onSubscriptionResult } = useAnnouncementChangedSubscription(
  {
    licensedTerritory,
  },
  {
    fetchPolicy: 'no-cache',
  },
)

const activeAnnouncement = ref<Maybe<Announcement> | undefined>(
  announcementByKey(licensedTerritory),
)

onQueryResult((payload) => {
  activeAnnouncement.value = payload.data.activeAnnouncement

  if (!activeAnnouncement.value) {
    deleteAllAnnouncements()
    return
  }

  setAnnouncementByKey(licensedTerritory, {
    ...activeAnnouncement.value,
    collapsed: announcementByKey(licensedTerritory)?.collapsed ?? false,
  })
})

onSubscriptionResult((payload) => {
  activeAnnouncement.value = payload.data?.announcementChanged

  if (!activeAnnouncement.value) return

  if (!activeAnnouncement.value.active) {
    deleteAllAnnouncements()
    return
  }

  setAnnouncementByKey(licensedTerritory, {
    ...activeAnnouncement.value,
    collapsed: announcementByKey(licensedTerritory)?.collapsed ?? false,
  })
})

const isCollapsed = computed(() => {
  if (!activeAnnouncement.value) return false

  return announcementByKey(licensedTerritory)?.collapsed
})

const handleUpdate = (collapsed: boolean) => {
  if (!activeAnnouncement.value) return

  setAnnouncementByKey(licensedTerritory, {
    ...activeAnnouncement.value,
    collapsed,
  })
}
</script>

<style lang="scss" scoped>
.active-announcement {
  .description {
    @include token-paragraph-regular;

    overflow-x: auto;
    margin: 0;
    white-space: pre-wrap;
    word-break: break-word;
  }
}
</style>
