import type { MaybeRef } from 'vue'

import type { PaymentMethod } from '~/@types/generated/backend/graphql-schema-types'
import type { CmsPaymentIconsQuery } from '~/@types/generated/cms/graphql-schema-types'

export type PaymentIcon = {
  icon: {
    height: number
    url: string
    width: number
  }
  isLogoComplianceStrict: boolean
  title: string
}

type CmsPaymentIcon = NonNullable<
  CmsPaymentIconsQuery['paymentIconCollection']
>['items'][0]

const isDisplayable = (methods: PaymentMethod[]) => (item: CmsPaymentIcon) =>
  item?.title && item.visible && methods.some(({ name }) => name === item.title)

const withFallbacks = (item: CmsPaymentIcon): PaymentIcon => ({
  icon: {
    height: item?.icon?.height ?? 0,
    url: item?.icon?.url ?? '',
    width: item?.icon?.width ?? 0,
  },
  isLogoComplianceStrict: item?.isLogoComplianceStrict ?? false,
  title: item?.title ?? '',
})

export const usePaymentIcons = (filter?: MaybeRef<string[]>) => {
  const { data } = useContentfulContent().getPaymentIcons()

  const { result: paymentMethodsResult } = usePaymentMethodsQuery({
    licensedTerritory: useLicensedTerritory({ raw: true }),
  })

  const methods = computed(
    () => paymentMethodsResult.value?.paymentMethods ?? [],
  )

  return computed(() => {
    const filterValue = toValue(filter)

    return (
      data.value?.items
        .filter(isDisplayable(methods.value))
        .filter(
          (item) =>
            !item?.title ||
            !filterValue ||
            filterValue.length <= 0 ||
            filterValue.includes(item.title),
        )
        .map(withFallbacks) ?? []
    )
  })
}
