import type { LocationQuery } from '#vue-router'

const SILENT_EMAIL_VERIFICATION_TOKEN_NAME = 'silentEmailVerificationToken'

const removeQueryParam = (query: LocationQuery) => {
  const updatedQuery = { ...query }
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete updatedQuery[SILENT_EMAIL_VERIFICATION_TOKEN_NAME]
  return navigateTo({ query: updatedQuery }, { replace: true })
}

export default defineNuxtRouteMiddleware(({ query }) => {
  if (import.meta.server) return

  const token = query[SILENT_EMAIL_VERIFICATION_TOKEN_NAME]
  if (!token) return

  if (Array.isArray(token)) return removeQueryParam(query)

  const { setToken } = useSilentEmailVerificationStore()
  setToken(token)
  return removeQueryParam(query)
})
