export const NONCE = 'nonce'

export const useSafeNonce = () => {
  const initNonce = () => {
    if (import.meta.dev) {
      return NONCE
    }

    if (import.meta.server) {
      return useNuxtApp().ssrContext?.event.context.security?.nonce ?? NONCE
    }

    if (import.meta.client) {
      return (
        document.querySelector<HTMLScriptElement>('script[nonce]')?.nonce ??
        NONCE
      )
    }

    return NONCE
  }

  return String(useState(StateKey.Nonce, initNonce).value)
}
