export const FAQ_COLLECTION_TYPES = {
  PrizesAndWinnings: 'faqPrizesAndWinnings',
  TicketsAndDrawings: 'faqTicketsAndDrawings',
  TopQuestions: 'faqTopQuestions',
} as const

type FaqCollectionType = ObjectValues<typeof FAQ_COLLECTION_TYPES>

export const getFaqCollectionType = (typename: string): FaqCollectionType => {
  switch (typename) {
    case 'FaqPrizesAndWinningsCollection':
    case 'FaqScratchcardsPrizesAndWinningsCollection':
      return FAQ_COLLECTION_TYPES.PrizesAndWinnings
    case 'FaqScratchcardsTopQuestionsCollection':
    case 'FaqTopQuestionsCollection':
      return FAQ_COLLECTION_TYPES.TopQuestions
    case 'FaqTicketsAndDrawingsCollection':
      return FAQ_COLLECTION_TYPES.TicketsAndDrawings
    default:
      return FAQ_COLLECTION_TYPES.PrizesAndWinnings
  }
}

export const getImageSources = (
  src: string,
  width: number,
  height: number,
) => ({
  src: `${src}?fm=webp&w=${width}&h=${height}`,
  srcset: `${src}?fm=webp&w=${width}&h=${height} 1x, ${src}?fm=webp&w=${width * 2}&h=${height * 2} 2x, ${src}?fm=webp&w=${width * 3}&h=${height * 3} 3x`,
})

export enum ContentType {
  Prizes = 'prizes',
  Rules = 'rules',
}
