import { LicensedTerritory } from '@lottocom/lottery-mapping'
import gt from 'semver/functions/gt'

export const useCustomContext = () => {
  const isNativeApp = useState<boolean>(StateKey.IsNativeApp, () => false)
  const { isAndroid, isChrome, isFirefox, isIos, userAgent } = useDevice()

  const allowedStatesForAndroid: LicensedTerritory[] = [
    LicensedTerritory.US_NJ,
    LicensedTerritory.US_NY,
  ]

  const isAndroidApp = computed(() => isNativeApp.value && isAndroid)

  const isBlockedForAndroid = computed(() => {
    if (!version.value || version.value.hasAndroidAllStatesFlag) return false

    return (
      isNativeApp.value &&
      isAndroid &&
      !allowedStatesForAndroid.includes(
        useLicensedTerritory({ raw: true }) as LicensedTerritory,
      ) &&
      gt(version.value.version, '1.1.5')
    )
  })

  const isChromeIPhone = computed(() => isIos && isChrome)

  const isFirefoxAndroid = computed(() => isAndroid && isFirefox)

  const version = computed(() => {
    const r = /Lottocom_app\/(.*)(\d{1,}.\d{1,}.\d{1,})/
    const matched: Maybe<string[]> = userAgent.match(r)
    if (!matched || matched.length < 3) return null

    return {
      hasAndroidAllStatesFlag: matched[1].length > 0,
      version: matched[2],
    }
  })

  return {
    isAndroidApp,
    isBlockedForAndroid,
    isChromeIPhone,
    isFirefoxAndroid,
    isNativeApp,
    version,
  }
}
