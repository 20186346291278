<template>
  <ul
    v-if="visiblePaymentIcons.length"
    class="flex flex-wrap"
    :class="gapClass"
  >
    <li v-for="icon in visiblePaymentIcons" :key="icon.title">
      <PaymentMethodSymbol v-bind="{ outlined, size }" :variant="icon" />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { PaymentMethodSymbolProps } from '~/features/Payment/PaymentMethodSymbol.vue'

type PaymentMethodSymbols = Pick<
  PaymentMethodSymbolProps,
  'outlined' | 'size'
> & {
  filter?: string[]
  gapClass?: 'gap-2xs' | 'gap-md' | 'gap-xs'
}

const props = withDefaults(defineProps<PaymentMethodSymbols>(), {
  filter: () => [],
  gapClass: 'gap-md',
  outlined: undefined,
})

const visiblePaymentIcons = usePaymentIcons(toRef(props, 'filter'))
</script>
