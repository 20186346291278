<template>
  <LcModal v-bind="$props" @close="emit('cancel')">
    <template v-if="$slots.headline" #header>
      <h2 class="text-h4">
        <slot name="headline" />
      </h2>
    </template>

    <div class="flex flex-col gap-md w-full">
      <div class="flex flex-col items-center">
        <slot />
      </div>
      <footer
        v-if="!noFooter"
        class="flex flex-col gap-md w-full"
        data-ignore-tracking-content
      >
        <LcButton
          v-if="!noConfirm"
          class="block"
          data-testid="modal-confirm-button"
          :loading="isConfirmLoading"
          :variant="confirmVariant"
          @click="emit('confirm')"
        >
          <slot name="confirm">
            {{ $t('modal.button.confirm') }}
          </slot>
        </LcButton>

        <LcButton
          v-if="!noCancel"
          data-testid="modal-cancel-button"
          :variant="cancelVariant"
          @click="emit('cancel')"
        >
          <slot name="cancel">
            {{ $t('modal.button.cancel') }}
          </slot>
        </LcButton>
      </footer>
    </div>
  </LcModal>
</template>

<script lang="ts" setup>
import type {
  LcButtonVariant,
  LcModalProps,
} from '@lottocom/frontend-components'

import type { TrackingImpressionProps } from '~/layers/tracking/types/tracking'

export type ModalProps = TrackingImpressionProps & {
  cancelVariant?: LcButtonVariant
  confirmVariant?: LcButtonVariant
  isConfirmLoading?: boolean
  noCancel?: boolean
  noConfirm?: boolean
  noFooter?: boolean
}

type ModalEmit = {
  (e: 'cancel' | 'confirm'): void
}

const emit = defineEmits<ModalEmit>()

withDefaults(defineProps<LcModalProps & ModalProps>(), {
  cancelVariant: 'primary-ghost',
  confirmVariant: 'primary',
})
</script>
