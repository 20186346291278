import routerOptions0 from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/lottocom/shop-frontend/frontend/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}