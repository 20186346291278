import type { Ref } from 'vue'

import type { ToasterToastProps } from '~/features/Toaster/ToasterToast.vue'

export type Toast = ToasterToastProps

export type ToastOptions = Omit<Toast, 'id'>

type ToastError = ToastTracking & {
  error: unknown
  options?: Partial<Omit<ToastOptions, 'tracking'>>
  prefix: string
}

type ToastErrorConfig = {
  fallback?: Omit<ToastOptions, 'tracking'> & Partial<ToastTracking>
  handlers?: ToastErrorHandler[]
}

type ToastErrorHandler = (
  errorMessage: string,
) => Partial<ToastOptions> | undefined

type ToastTracking = {
  tracking: Pick<ToastOptions['tracking'], 'action' | 'topicKey'>
}

export const useToasterIsolated = (toasts: Ref<Toast[]>) => {
  const { getTranslation, t } = useI18nUtils()

  const DEFAULT_ERROR_TOAST: ToastOptions = {
    headline: t('common.toast.error.general.headline'),
    message: t('common.toast.error.general.message'),
    tracking: { topicKey: 'common_error' },
    type: 'danger',
  }

  const isAlreadyPresent = (id: string) =>
    toasts.value.some((toast) => toast.id === id)

  const addToast = (
    options: ToastOptions,
    id: string = uniqueId('toastisolated-'),
  ) => {
    if (isAlreadyPresent(id)) return

    toasts.value.push({ id, ...options })
  }

  const addToastError = (
    { error, options = {}, prefix, tracking }: ToastError,
    { fallback = DEFAULT_ERROR_TOAST, handlers = [] }: ToastErrorConfig = {},
  ) => {
    if (!isApolloError(error)) {
      const { tracking: _tracking, ...rest } = fallback
      return addToast({ ...rest, tracking: { ...tracking, ..._tracking } })
    }

    const [{ message: firstErrorMessage }] = error.graphQLErrors
    const { headline, message } = getTranslatedErrorParts(
      firstErrorMessage,
      prefix,
    )

    const toastOptions: ToastOptions = {
      headline: headline ?? undefined,
      message: message ?? fallback.message,
      tracking,
      type: 'danger',
      ...options,
    }

    if (handlers?.length) {
      let customOptions: ReturnType<ToastErrorHandler>
      for (const handler of handlers) {
        customOptions = handler(firstErrorMessage)
        if (customOptions) break
      }

      if (customOptions) {
        return addToast({
          ...toastOptions,
          ...customOptions,
        })
      }
    }

    const { tracking: _tracking, ...rest } = fallback
    return addToast(
      message
        ? toastOptions
        : { ...rest, tracking: { ..._tracking, ...tracking } },
    )
  }

  const getTranslatedErrorParts = (error: string, prefix?: string) => {
    const errorPath = normalizeKey(`${prefix}.${error}`)

    return {
      headline: getTranslation({ key: `${errorPath}.headline` }),
      message: getTranslation({ key: `${errorPath}.message` }),
    }
  }

  const removeAllToasts = () => {
    toasts.value = []
  }

  const removeAllToastsExcept = (ids: string[]) => {
    if (!ids.length) return removeAllToasts()
    toasts.value = toasts.value.filter((toast) => ids.includes(toast.id))
  }

  const removeToast = (id: string) => {
    const index = toasts.value.findIndex((toast) => toast.id === id)
    if (index < 0) return

    toasts.value.splice(index, 1)
  }

  return {
    addToast,
    addToastError,
    removeAllToasts,
    removeAllToastsExcept,
    removeToast,
    toasts,
  }
}
