import type { TrackingEventType } from '~/layers/tracking/types/tracking'

export const useTrackingErrorLog = () => {
  const { log } = useDatadog()

  const logTrackingError = (event: TrackingEventType, error: unknown) => {
    log('error', {
      error,
      message: `Tracking for event ${event} failed`,
      messageContext: {
        tracking: {
          event,
        },
      },
    })
  }

  return {
    logTrackingError,
  }
}
