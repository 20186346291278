const ROOT = '/'

const TRAILING_SLASH_REGEX = /\/$/
const MULTIPLE_SLASHES_REGEX = /\/{2,}/g
const TRAILING_SLASH_BEFORE_QUERY_REGEX = /\/\?/

const needsNormalization = (path: string) =>
  TRAILING_SLASH_REGEX.test(path) ||
  MULTIPLE_SLASHES_REGEX.test(path) ||
  TRAILING_SLASH_BEFORE_QUERY_REGEX.test(path)

/*
 * This middleware is used to normalize the URL path.
 * It is necessary because marketing links like /scratchcard/co-1000000-oro/
 * can lead to unexpected behavior and errors.
 */
export default defineNuxtRouteMiddleware((to) => {
  if (to.path === ROOT || !needsNormalization(to.fullPath)) return

  const normalizedPath = to.fullPath
    .replace(MULTIPLE_SLASHES_REGEX, '/')
    .replace(TRAILING_SLASH_BEFORE_QUERY_REGEX, '?')
    .replace(TRAILING_SLASH_REGEX, '')

  return navigateTo(normalizedPath, { redirectCode: 301 })
})
