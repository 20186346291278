import { datadogRum } from '@datadog/browser-rum'

export default defineNuxtPlugin(() => {
  const { shouldBeIgnored } = useDatadog()
  const { name } = useRoute()
  const {
    datadog: { enabled, env },
    sentry: { environment: sentryEnvironment },
    version,
  } = useRuntimeConfig().public

  const temporaryDisabled = isWeekendTime(new Date())
  if (name === 'status-health' || !enabled || temporaryDisabled) return

  if (!datadogRum) return

  const botRegex =
    /(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)/i

  const isBot = botRegex.test(navigator.userAgent)
  const sampleRate = isBot ? 0 : sentryEnvironment === 'production' ? 10 : 100

  datadogRum.init({
    allowedTracingUrls: [
      { match: /.*\/graphql/, propagatorTypes: ['datadog', 'tracecontext'] },
    ],
    applicationId: 'ff6ccab5-089b-49be-8b5d-6da929dbf832',
    beforeSend: (event) => {
      if (event.type !== 'error') return true
      return !shouldBeIgnored(event.type, String(event.error.message))
    },
    clientToken: 'pubb0a38cafe39fc0ca2856f9fa8d8e01eb',
    defaultPrivacyLevel: 'mask-user-input',
    env,
    service: 'nuxt3-frontend-client',
    sessionReplaySampleRate: sampleRate,
    sessionSampleRate: sampleRate,
    site: 'datadoghq.eu',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version,
  })
})
