import { defineStore } from 'pinia'

import type {
  VerifyEmailMutation,
  VerifyEmailMutationVariables,
} from '~/@types/generated/backend/graphql-schema-types'

export const useSilentEmailVerificationStore = defineStore(
  'ltc_silent_email_verification',
  () => {
    const { clients } = useApollo()
    const { isLoggedIn } = useAuth()

    const token = ref<Maybe<string>>(null)

    const deleteToken = () => {
      token.value = null
    }

    const setToken = (newToken: string) => {
      token.value = newToken
    }

    const verifyEmail = async () => {
      if (!isLoggedIn.value || !token.value) return

      try {
        await clients?.default.mutate<
          VerifyEmailMutation,
          VerifyEmailMutationVariables
        >({
          mutation: VerifyEmailDocument,
          variables: {
            input: {
              silentEmailVerificationToken: token.value,
            },
          },
        })
      } catch {
        // Do nothing
      } finally {
        deleteToken()
      }
    }

    watch([isLoggedIn, token], async ([newIsLoggedIn, newToken]) => {
      if (!newIsLoggedIn || !newToken) return
      await verifyEmail()
    })

    return {
      deleteToken,
      setToken,
      token,
    }
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
    },
  },
)
