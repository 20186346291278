import type {
  CancelledOrder,
  CheckoutQuery,
  OrderPollQuery,
  PayWithTopUp,
} from '~/@types/generated/backend/graphql-schema-types'

export const isCancelledOrder = (
  order: OrderPollQuery['order'],
): order is CancelledOrder => {
  const cancelledOrder = order as CancelledOrder
  return cancelledOrder?.cancelReasonType !== undefined
}

export const isError = (err: unknown): err is Error => err instanceof Error

export const isPayWithTopUp = (
  pay: CheckoutQuery['checkout']['payment'],
): pay is PayWithTopUp => {
  const payWithTopUp = pay as PayWithTopUp
  return (
    payWithTopUp?.initial !== undefined && payWithTopUp?.stored !== undefined
  )
}

export const isEProtectResponseSuccess = (
  response: LooseObject,
): response is EProtectResponseSuccess => {
  const responseSuccess = response as EProtectResponseSuccess
  return (
    responseSuccess.paypageRegistrationId !== undefined &&
    responseSuccess.type !== undefined
  )
}
