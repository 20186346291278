import { createHttpLink, from } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

/*
Hopefully in a future version of nuxtjs/apollo the workaround with plugin/apollo.ts won't be needed anymore for multi environment setups.
Best attempt would be to use browserHttpEndpoint and httpEndpoint in here with values from runtime config
 */
export const createCmsLinks = (nuxtApp: any, uri: string, token: string) => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  }))

  const httpLink = createHttpLink({
    uri,
  })

  const errorLink = onError((error) => {
    console.warn(`CMS error on ${uri}`, error)
    nuxtApp.hook('apollo:error', () => error)
  })

  return from([errorLink, authLink, httpLink])
}
