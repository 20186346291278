import { MarketingChannel } from '~/@types/generated/backend/graphql-schema-types'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:beforeMount', async () => {
    if (!useCustomContext().isNativeApp.value || !useAuth().isLoggedIn.value)
      return

    const { log } = useDatadog()
    const status = await useFlutter()!.requestPushNotificationPermissionStatus()

    try {
      await useSetMarketingChannelPreferencesMutation().mutate({
        input: {
          activated: status === 'granted',
          channel: MarketingChannel.PushNotification,
        },
      })
    } catch (error) {
      log('error', {
        component: 'marketing.ts',
        error,
        message: `Set of marketing channel preferences failed with${isError(error) ? `with ${error.message}` : ''}`,
      })
    }
  })
})
