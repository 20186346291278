export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.server || !useRuntimeConfig().public.gtmEnabled!) return

  const gtmId = useRuntimeConfig().public.gtmId

  nuxtApp.hook('app:suspense:resolve', () => {
    window.dataLayer = window.dataLayer || []

    const fileName = {
      production: 'sst.js',
      staging: 'gtm.js',
    }

    const urlParams = {
      production: {
        gtm_auth: '9mkOWT05bQCXmsXNEAw9cQ',
        gtm_preview: 'env-1',
        // the id will be mapped server side for production in the sst.js file
      },
      staging: {
        gtm_auth: 'I_Ixab44OOYzBW4Gf-92GQ',
        gtm_preview: 'env-202',
        id: gtmId,
      },
    }

    const isProd = useRuntimeConfig().public.sentry.environment === 'production'
    const file = isProd ? fileName.production : fileName.staging

    const url = new URL(`https://sst.lotto.com/${file}`)
    url.search = new URLSearchParams(
      isProd ? urlParams.production : urlParams.staging,
    ).toString()
    try {
      loadScript({ options: { async: true }, src: url.href })
    } catch {
      // Do nothing
    }
  })
})
