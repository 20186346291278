import { ProductType } from '~/composables/checkout'
import { BroadcastMessage } from '~/utils/broadcaster'

type ClearMyOrdersCacheOptions = {
  broadcast?: boolean
  productType?: ProductType
}

export const useApolloCache = () => {
  const apollo = useApollo()

  const clearMyOrdersCache = ({
    broadcast = false,
    productType,
  }: ClearMyOrdersCacheOptions = {}) => {
    switch (productType) {
      case ProductType.Lottery:
        return clearSingleOrdersCache(broadcast)
      case ProductType.LotterySubscription:
        return clearSubscriptionsCache(broadcast)
      case ProductType.Scratchcard:
        return clearScratchcardsCache(broadcast)
      default:
        clearSingleOrdersCache(broadcast)
        clearScratchcardsCache(broadcast)
        clearSubscriptionsCache(broadcast)
    }
  }

  const clearScratchcardsCache = (broadcast = false) => {
    if (broadcast) notifyTabs(BroadcastMessage.ClearScratchcardOrdersCache)

    apollo.clients.default.cache.evict({
      fieldName: 'scratchcardOrders',
    })
  }

  const clearSingleOrdersCache = (broadcast = false) => {
    if (broadcast) notifyTabs(BroadcastMessage.ClearSingleOrdersCache)

    apollo.clients.default.cache.evict({
      fieldName: 'playSlipOrders',
    })
  }

  const clearSubscriptionsCache = (broadcast = false) => {
    if (broadcast) notifyTabs(BroadcastMessage.ClearSubscriptionOrdersCache)

    apollo.clients.default.cache.evict({
      fieldName: 'subscriptionsMultipleLines',
    })
  }

  const clearTransactionsCache = (broadcast = false) => {
    if (broadcast) notifyTabs(BroadcastMessage.ClearTransactionsCache)

    apollo.clients.default.cache.evict({
      fieldName: 'transactionHistory',
    })
  }

  const notifyTabs = (message: BroadcastMessage) => {
    broadcaster.postMessage(message)
  }

  return {
    clearMyOrdersCache,
    clearScratchcardsCache,
    clearSingleOrdersCache,
    clearSubscriptionsCache,
    clearTransactionsCache,
  }
}
