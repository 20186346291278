<template>
  <LcModal
    v-bind="{ isVisible }"
    background-class="state-switch__modal--background"
    :close-triggers="['click-outside', 'escape']"
    :tracking="{
      action: 'state_switch',
      topicKey: 'state_selection',
      customMessage: trackingOptions,
      customTopic: $t(`licensedterritory.${licensedTerritory}`),
    }"
    transition="slide-down-lg"
    @close="isVisible = false"
  >
    <template #modal="{ setRef }">
      <LcCard
        :ref="setRef"
        body-class="state-switch__card--body"
        class="state-switch__card lc-modal__card lc-modal__card--large"
        data-containerid="state_picker"
        role="dialog"
      >
        <header
          ref="headerRef"
          class="state-switch__header flex items-center justify-between p-sm"
        >
          <span class="flex items-center">
            <LcIcon class="mr-2xs" name="location" />
            {{ $t(`licensedterritory.${licensedTerritory}`) }}
          </span>

          <LcButton
            icon="close"
            size="small"
            variant="tertiary"
            @click="isVisible = false"
          />
        </header>

        <ListSelect
          v-model="formattedOptions"
          :style="{ height: `calc(100% - ${headerHeight}px)` }"
        />
      </LcCard>
    </template>
  </LcModal>
</template>

<script lang="ts" setup>
import ListSelect from '~/components/ListSelect/ListSelect.vue'

const { licensedTerritory, options } = useStateSwitch()

const headerRef = ref()

const { height: headerHeight } = useElementBounding(headerRef)

const isVisible = defineModel('isVisible', { default: false, type: Boolean })

const formattedOptions = computed(() =>
  options.value.map((option) => ({
    href: option.href,
    hrefTitle: option.title,
    label: option.state,
    selected: option.selected,
    value: option.id,
  })),
)

const trackingOptions = computed(() =>
  options.value.map(({ state }) => state).join(', '),
)
</script>

<style lang="scss">
.state-switch {
  &__card {
    overflow: hidden;
    background-color: token('color.background.main.tertiary');

    &--body {
      height: 100%;
      padding: 0;
    }

    @include media-tablet {
      height: 100%;
      border-radius: 0;
    }
  }

  &__modal {
    &--background {
      padding: 4rem;

      @include media-tablet {
        padding: 0;
        background: none;
        backdrop-filter: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.state-switch {
  &__header {
    position: sticky;
    top: 0;
  }
}
</style>
