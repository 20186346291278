<template>
  <PriceBreakdownDetailsWrapper :convenience-fee="prices.fee">
    <PriceListPerPlay v-bind="{ numberOfPlays: numberOfTickets }">
      {{
        $t('scratchcard.pricebreakdownlist.labels.pricepercard', {
          amount: prices.cardPrice,
        })
      }}
    </PriceListPerPlay>
    <dd>{{ formatMoney(prices.totalPrice) }}</dd>
  </PriceBreakdownDetailsWrapper>
</template>

<script lang="ts" setup>
import Money from '~/models/Money'

const { priceList } = useScratchcardGame()
const { currentPrice } = useScratchcardStore()
const numberOfTickets = computed(() =>
  priceList?.value ? currentPrice(priceList.value)!.quantity : 1,
)
const totalPrice = computed(
  () => currentPrice(priceList.value!)?.chargesForCards,
)

const prices = computed(() => ({
  cardPrice: new Money(totalPrice.value!).dividedBy(numberOfTickets.value),
  fee: new Money(currentPrice(priceList.value!)?.feeTotal),
  totalPrice: new Money(totalPrice.value),
}))
</script>
