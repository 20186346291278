<template>
  <LcToast
    class="the-toaster-toast"
    v-bind="{ headline, closable, tracking }"
    :variant="type"
    @close="emit('close', id)"
  >
    <p v-if="typeof message === 'string'">{{ message }}</p>

    <p
      v-for="(text, index) in message"
      v-else-if="Array.isArray(message)"
      :key="index"
    >
      {{ text }}
    </p>

    <i18n-t v-else :keypath="message.translationKey" scope="global" tag="div">
      <template
        v-for="(translationVariable, slotName) in message.translationVariables"
        :key="slotName"
        #[slotName]
      >
        <HyperLink
          v-if="isLink(translationVariable)"
          :target="translationVariable.target"
          :to="translationVariable.to"
        >
          {{ translationVariable.text }}
        </HyperLink>

        <p v-else>
          {{ translationVariable.text }}
        </p>
      </template>
    </i18n-t>
  </LcToast>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from '#vue-router'
import type { LcToastVariant } from '@lottocom/frontend-components'

import type { TrackingImpressionProps } from '~/layers/tracking/types/tracking'

export type ToasterToastProps = TrackingImpressionProps & {
  autoClose?: boolean
  closable?: boolean
  headline?: string
  id: string
  message: string | string[] | ToastMessage
  type?: LcToastVariant
}

export type ToastTranslationLink = {
  target?: '_blank' | '_parent' | '_self' | '_top'
  text: string
  to: RouteLocationRaw
  type: 'link'
}

export type ToastTranslationText = {
  text: string
  type: 'text'
}

type ToastMessage = {
  translationKey: string
  translationVariables?: ToastMessageTranslationVariables
}

type ToastMessageTranslationVariables = Record<string, ToastTranslationType>

type ToastTranslationType = ToastTranslationLink | ToastTranslationText

const emit = defineEmits<{
  (e: 'close', id: string): void
}>()

const props = withDefaults(defineProps<ToasterToastProps>(), {
  autoClose: true,
  closable: true,
})

const isLink = (
  translationVariable: ToastTranslationType,
): translationVariable is ToastTranslationLink =>
  translationVariable.type === 'link'

onMounted(() => {
  if (!props.autoClose) return

  setTimeout(() => {
    emit('close', props.id)
  }, 5000)
})
</script>

<style lang="scss" scoped>
.the-toaster-toast {
  a {
    @include link-default;
  }
}
</style>
