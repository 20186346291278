import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import type { MyOrdersTabData } from '~/composables/myOrders'
import type { Tab } from '~/composables/tabs'

import { ORDER_TYPES } from '~/composables/orderType'

export const CUSTOM_MY_ORDERS_ROUTES = {
  Scratchcards: { name: 'my_orders_scratchcards', path: 'scratchcards' },
  SingleOrders: { name: 'my_orders_single-orders', path: 'single-orders' },
  Subscriptions: { name: 'my_orders_subscriptions', path: 'subscriptions' },
} as const

const isTabActive =
  (to: RouteLocationNormalized) => (tab: Tab<MyOrdersTabData>) =>
    tab.name === to.name

const checkStateOfTab = async (to: RouteLocationNormalized) => {
  const { activeTabs } = await useMyOrders()

  if (activeTabs.value.find(isTabActive(to))) return
  return '/my-orders/single-orders'
}

const MyOrders = () => import('~/custom-pages/my-orders/index.vue')
const MyOrdersUnknown = () =>
  import('~/custom-pages/my-orders/children/unknown.vue')

const MyOrdersScratchcards = () =>
  import('~/custom-pages/my-orders/children/scratchcards.vue')

const MyOrdersSingleOrders = () =>
  import('~/custom-pages/my-orders/children/single-orders.vue')

const MyOrdersSubscriptions = () =>
  import('~/custom-pages/my-orders/children/subscriptions.vue')

const components = {
  [CUSTOM_MY_ORDERS_ROUTES.Scratchcards.name]: MyOrdersScratchcards,
  [CUSTOM_MY_ORDERS_ROUTES.SingleOrders.name]: MyOrdersSingleOrders,
  [CUSTOM_MY_ORDERS_ROUTES.Subscriptions.name]: MyOrdersSubscriptions,
}

export const myOrdersRoutes: RouteRecordRaw = {
  children: [
    {
      component: MyOrdersUnknown,
      meta: {
        middleware: async (to) => {
          const orderId = to.params?.id
          if (!orderId) return '/my-orders/single-orders'

          const type = await useOrderType().getOrderType(String(orderId))

          switch (type) {
            case ORDER_TYPES.Scratchcard:
              return `/my-orders/scratchcard/${orderId}`
            case ORDER_TYPES.SingleOrder:
              return `/my-orders/single-order/${orderId}`
            case ORDER_TYPES.Subscriptions:
              return `/my-orders/subscription/${orderId}`
            default:
              return create404Error()
          }
        },
      },
      path: ':id',
    },
    {
      components,
      meta: { middleware: checkStateOfTab },
      ...CUSTOM_MY_ORDERS_ROUTES.Scratchcards,
    },
    {
      components,
      ...CUSTOM_MY_ORDERS_ROUTES.SingleOrders,
    },
    {
      components,
      meta: { middleware: checkStateOfTab },
      ...CUSTOM_MY_ORDERS_ROUTES.Subscriptions,
    },
  ],
  component: MyOrders,
  meta: {
    footer: 'full',
    header: 'logo-only',
    layout: false,
    middleware: 'auth',
  },
  path: '/my-orders',
  redirect: '/my-orders/single-orders',
}
