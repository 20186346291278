<template>
  <nav class="the-bottom-navigation">
    <div class="content-wrap px-sm">
      <ul class="flex items-center justify-between">
        <li v-for="item in items" :key="item.icon">
          <TheBottomNavigationItem v-bind="item" />
        </li>
      </ul>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import type { TheBottomNavigationItemProps } from './TheBottomNavigationItem.vue'

const { t } = useI18n()

const items: TheBottomNavigationItemProps[] = [
  {
    icon: 'play-now',
    title: t('navigation.bottom.playnow'),
    to: '/play-now',
  },
  {
    icon: 'results',
    title: t('navigation.bottom.results'),
    to: '/results',
  },
  {
    icon: 'my-orders',
    title: t('navigation.bottom.myorders'),
    to: '/my-orders',
  },
  {
    icon: 'account',
    title: t('navigation.bottom.account'),
    to: '/account',
  },
  {
    icon: 'service',
    title: t('navigation.bottom.service'),
    to: '/service',
  },
]
</script>

<style lang="scss" scoped>
.the-bottom-navigation {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  height: $main-bottom-navigation-height;
  background-color: rgb(color('white'), 92%);
  box-shadow: $shadow-sm;

  @supports (backdrop-filter: blur(1rem)) {
    backdrop-filter: blur(0.5625rem);
  }

  @include media-mobile-small {
    ul > * {
      flex-basis: 100%;
    }
  }
}
</style>
