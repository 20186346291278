import type { RumEvent } from '@datadog/browser-rum'

import { datadogLogs } from '@datadog/browser-logs'

import { DATADOG_IGNORABLE } from '~/constants/datadog'

type GlobalMessageContextKeys = 'amount' | 'paymentId' | 'playSlipId'

export const DATADOG_LOG_TYPES = {
  Error: 'error',
  Info: 'info',
  Warn: 'warn',
} as const

export type DatadogLogType = ObjectValues<typeof DATADOG_LOG_TYPES>

export type DatadogPayload = {
  appendPlayerInfo?: boolean
  component?: string
  error?: unknown
  message: string
  messageContext?: LooseObject
}

export type IgnorableFn = (
  type: DatadogLogType | RumEvent['type'],
  message: string,
) => boolean

const globalMessageContext = ref<Map<GlobalMessageContextKeys, any>>(new Map())

export const useDatadog = () => {
  const {
    datadog: { container, enabled, env, token },
    licensedTerritory,
    namespace,
    version: release,
  } = useRuntimeConfig().public

  if (String(enabled) === 'true' && !datadogLogs.getInitConfiguration()) {
    datadogLogs.init({
      clientToken: token,
      env,
      forwardErrorsToLogs: true,
      service: 'nuxt3-frontend-client',
      site: 'datadoghq.eu',
      version: release,
    })

    datadogLogs.logger.setContext({
      containerName: container,
      host: 'browser',
      licensedTerritory,
      namespace,
    })
  }

  const { isLoggedIn } = useAuth()

  const { result } = useUserDataQuery({
    enabled: isLoggedIn.value,
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-only',
  })

  const playerId = computed(() => result.value?.player?.id)

  const addToGlobalContext = (key: GlobalMessageContextKeys, value: any) => {
    if (globalMessageContext.value.has(key)) return
    globalMessageContext.value.set(key, value)
  }

  const clearGlobalContext = () => {
    globalMessageContext.value.clear()
  }

  const log = (type: DatadogLogType, payload: DatadogPayload) => {
    if (shouldBeIgnored(type, payload)) return

    const {
      appendPlayerInfo,
      component,
      error,
      message: originalMessage,
      messageContext,
    } = payload

    const message = appendPlayerInfo
      ? `${originalMessage} for player ${playerId.value}`
      : originalMessage

    const composedMessageContext = {
      ...Object.fromEntries(globalMessageContext.value),
      ...(playerId.value ? { playerId: playerId.value } : {}),
      ...(component ? { component } : {}),
      ...(messageContext || {}),
    }

    if (enabled) {
      customIdleCallback(() =>
        datadogLogs.logger[type](
          message,
          composedMessageContext,
          isError(error) ? error : undefined,
        ),
      )
      return
    }

    return console[type]('[DATADOG-DEBUG]', {
      error,
      message,
      messageContext: composedMessageContext,
      timestamp: new Date().toISOString(),
    })
  }

  const removeFromGlobalContext = (key: GlobalMessageContextKeys) => {
    if (!globalMessageContext.value.has(key)) return
    globalMessageContext.value.delete(key)
  }

  const shouldBeIgnored = (
    type: DatadogLogType,
    payload: DatadogPayload | string,
  ) => {
    const message = typeof payload === 'string' ? payload : payload.message
    return DATADOG_IGNORABLE.some((fn) => fn(type, message))
  }
  return {
    addToGlobalContext,
    clearGlobalContext,
    log,
    removeFromGlobalContext,
    shouldBeIgnored,
  }
}
