// @ts-nocheck
import * as Types from '~/@types/generated/backend/graphql-schema-types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export const AddOnFragmentDoc = gql`
    fragment addOn on AddOn {
  mandatory
  name
  options
  perPlay
}
    `;
export const CouponInfoFragmentDoc = gql`
    fragment couponInfo on BonusCodeInfoPayload {
  code
  description
  title
  value {
    amount
    type
  }
}
    `;
export const MoneyFragmentDoc = gql`
    fragment money on Money {
  currency
  value
}
    `;
export const CouponValidationResultFragmentDoc = gql`
    fragment couponValidationResult on BonusValidationResult {
  fulfillable
  fulfilled
  info {
    ...couponInfo
  }
  minTotalCharge {
    ...money
  }
  unfulfilledConditions
}
    ${CouponInfoFragmentDoc}
${MoneyFragmentDoc}`;
export const ImagePropertiesFragmentDoc = gql`
    fragment imageProperties on ImagePropertiesPayload {
  blurHash
  height
  width
}
    `;
export const CampaignFragmentDoc = gql`
    fragment campaign on CampaignPayload {
  ... on CampaignBadgePayload {
    bonusCode
    infoHeadline
    infoText
    title
    type
  }
  ... on CampaignHeroHighlightPayload {
    badge {
      bonusCode
      color
      infoHeadline
      infoText
      placement
      title
      subtitle
    }
    pattern
  }
}
    `;
export const LotteryTeaserFragmentDoc = gql`
    fragment lotteryTeaser on LotteryTeaserPayload {
  id
  name
  drawDate
  closingDate
  comingSoon
  jackpotSize {
    currency
    value
    __typename
  }
  jackpotType
  lotteryType
  campaign {
    ...campaign
  }
  quickPick {
    addOns {
      name
      value
      __typename
    }
    plays
    __typename
  }
  __typename
}
    ${CampaignFragmentDoc}`;
export const PlayerLimitFragmentDoc = gql`
    fragment playerLimit on PlayerLimit {
  available {
    ...money
  }
  nextIncreaseFrom
  nextPeriodStart
  value {
    ...money
  }
}
    ${MoneyFragmentDoc}`;
export const PrizeTierCategoryFragmentDoc = gql`
    fragment prizeTierCategory on PrizeTierCategory {
  categoryRequirements {
    type
    ... on ByAddOnSelected {
      __typename
      addOnName
    }
    ... on ByMultiplierDeselected {
      __typename
      addOnName
    }
    ... on ByMultiplierSelected {
      __typename
      addOnName
      reference
      value
    }
    ... on ByMultiplierValueMatched {
      __typename
      addOnName
      value
    }
  }
  group
  name
  prizeTiers {
    amount {
      ...money
    }
    jackpot
    payoutInterval
    requirements {
      type
      ... on NumberLottery {
        numberRequirements {
          amountOfCorrectNumbers
          numbersReference
        }
      }
      ... on PickLottery {
        pickRequirements {
          betType
          ignoreFields
        }
      }
      ... on LuckyLines {
        matchingLines
      }
    }
    tier
    type
  }
}
    ${MoneyFragmentDoc}`;
export const ScratchcardTeaserFragmentDoc = gql`
    fragment scratchcardTeaser on ScratchcardTeaserPayload {
  amount {
    currency
    value
  }
  campaign {
    ...campaign
  }
  name
  price {
    currency
    value
  }
  slug
  visualParameters {
    primaryColor
    secondaryColor
    teaserImageUrl
    titleColor
  }
}
    ${CampaignFragmentDoc}`;
export const AccountDocument = gql`
    query Account {
  account {
    balance {
      currency
      value
      __typename
    }
  }
}
    `;

/**
 * __useAccountQuery__
 *
 * To run a query within a Vue component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAccountQuery();
 */
export function useAccountQuery(options: VueApolloComposable.UseQueryOptions<Types.AccountQuery, Types.AccountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.AccountQuery, Types.AccountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.AccountQuery, Types.AccountQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.AccountQuery, Types.AccountQueryVariables>(AccountDocument, {}, options);
}
export function useAccountLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.AccountQuery, Types.AccountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.AccountQuery, Types.AccountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.AccountQuery, Types.AccountQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.AccountQuery, Types.AccountQueryVariables>(AccountDocument, {}, options);
}
export type AccountQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.AccountQuery, Types.AccountQueryVariables>;
export const AddPaymentInstrumentDocument = gql`
    mutation AddPaymentInstrument($input: AddPaymentInstrumentInput!) {
  addPaymentInstrument(input: $input) {
    success
  }
}
    `;

/**
 * __useAddPaymentInstrumentMutation__
 *
 * To run a mutation, you first call `useAddPaymentInstrumentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentInstrumentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddPaymentInstrumentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentInstrumentMutation(options: VueApolloComposable.UseMutationOptions<Types.AddPaymentInstrumentMutation, Types.AddPaymentInstrumentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.AddPaymentInstrumentMutation, Types.AddPaymentInstrumentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.AddPaymentInstrumentMutation, Types.AddPaymentInstrumentMutationVariables>(AddPaymentInstrumentDocument, options);
}
export type AddPaymentInstrumentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.AddPaymentInstrumentMutation, Types.AddPaymentInstrumentMutationVariables>;
export const ChangeLimitsDocument = gql`
    mutation ChangeLimits($limits: LimitsInput!, $type: LimitType!) {
  changeLimitValue(type: $type, value: $limits) {
    period
    type
    __typename
  }
}
    `;

/**
 * __useChangeLimitsMutation__
 *
 * To run a mutation, you first call `useChangeLimitsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangeLimitsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangeLimitsMutation({
 *   variables: {
 *     limits: // value for 'limits'
 *     type: // value for 'type'
 *   },
 * });
 */
export function useChangeLimitsMutation(options: VueApolloComposable.UseMutationOptions<Types.ChangeLimitsMutation, Types.ChangeLimitsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.ChangeLimitsMutation, Types.ChangeLimitsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.ChangeLimitsMutation, Types.ChangeLimitsMutationVariables>(ChangeLimitsDocument, options);
}
export type ChangeLimitsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.ChangeLimitsMutation, Types.ChangeLimitsMutationVariables>;
export const ClosePlayerDocument = gql`
    mutation ClosePlayer($input: ClosePlayerInput!) {
  closePlayer(input: $input) {
    status
  }
}
    `;

/**
 * __useClosePlayerMutation__
 *
 * To run a mutation, you first call `useClosePlayerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useClosePlayerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useClosePlayerMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useClosePlayerMutation(options: VueApolloComposable.UseMutationOptions<Types.ClosePlayerMutation, Types.ClosePlayerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.ClosePlayerMutation, Types.ClosePlayerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.ClosePlayerMutation, Types.ClosePlayerMutationVariables>(ClosePlayerDocument, options);
}
export type ClosePlayerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.ClosePlayerMutation, Types.ClosePlayerMutationVariables>;
export const DeactivateStoredPaymentInstrumentDocument = gql`
    mutation DeactivateStoredPaymentInstrument($input: DeactivateStoredPaymentInstrumentInput!) {
  deactivateStoredPaymentInstrument(input: $input) {
    success
  }
}
    `;

/**
 * __useDeactivateStoredPaymentInstrumentMutation__
 *
 * To run a mutation, you first call `useDeactivateStoredPaymentInstrumentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateStoredPaymentInstrumentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeactivateStoredPaymentInstrumentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateStoredPaymentInstrumentMutation(options: VueApolloComposable.UseMutationOptions<Types.DeactivateStoredPaymentInstrumentMutation, Types.DeactivateStoredPaymentInstrumentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.DeactivateStoredPaymentInstrumentMutation, Types.DeactivateStoredPaymentInstrumentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.DeactivateStoredPaymentInstrumentMutation, Types.DeactivateStoredPaymentInstrumentMutationVariables>(DeactivateStoredPaymentInstrumentDocument, options);
}
export type DeactivateStoredPaymentInstrumentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.DeactivateStoredPaymentInstrumentMutation, Types.DeactivateStoredPaymentInstrumentMutationVariables>;
export const InitialPaymentInstrumentsDocument = gql`
    query initialPaymentInstruments($input: InitialPaymentInstrumentsInput!) {
  initialPaymentInstruments(input: $input) {
    accountId
    integrationType
    methods
    provider
    providerUri
    token {
      expires
      value
    }
    type
    venmoProfileId
  }
}
    `;

/**
 * __useInitialPaymentInstrumentsQuery__
 *
 * To run a query within a Vue component, call `useInitialPaymentInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialPaymentInstrumentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useInitialPaymentInstrumentsQuery({
 *   input: // value for 'input'
 * });
 */
export function useInitialPaymentInstrumentsQuery(variables: Types.InitialPaymentInstrumentsQueryVariables | VueCompositionApi.Ref<Types.InitialPaymentInstrumentsQueryVariables> | ReactiveFunction<Types.InitialPaymentInstrumentsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.InitialPaymentInstrumentsQuery, Types.InitialPaymentInstrumentsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.InitialPaymentInstrumentsQuery, Types.InitialPaymentInstrumentsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.InitialPaymentInstrumentsQuery, Types.InitialPaymentInstrumentsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.InitialPaymentInstrumentsQuery, Types.InitialPaymentInstrumentsQueryVariables>(InitialPaymentInstrumentsDocument, variables, options);
}
export function useInitialPaymentInstrumentsLazyQuery(variables?: Types.InitialPaymentInstrumentsQueryVariables | VueCompositionApi.Ref<Types.InitialPaymentInstrumentsQueryVariables> | ReactiveFunction<Types.InitialPaymentInstrumentsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.InitialPaymentInstrumentsQuery, Types.InitialPaymentInstrumentsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.InitialPaymentInstrumentsQuery, Types.InitialPaymentInstrumentsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.InitialPaymentInstrumentsQuery, Types.InitialPaymentInstrumentsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.InitialPaymentInstrumentsQuery, Types.InitialPaymentInstrumentsQueryVariables>(InitialPaymentInstrumentsDocument, variables, options);
}
export type InitialPaymentInstrumentsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.InitialPaymentInstrumentsQuery, Types.InitialPaymentInstrumentsQueryVariables>;
export const LimitsDocument = gql`
    query Limits($withDeposit: Boolean = true, $withSpending: Boolean = true) {
  limits {
    deposit @include(if: $withDeposit) {
      ... on PlayerLimit {
        ...playerLimit
      }
      type
      period
      __typename
    }
    maxDeposit @include(if: $withDeposit) {
      ... on MaxLimit {
        value {
          ...money
        }
      }
      type
      period
      __typename
    }
    spending @include(if: $withSpending) {
      ... on PlayerLimit {
        ...playerLimit
      }
      type
      period
      __typename
    }
    maxSpending @include(if: $withSpending) {
      ... on MaxLimit {
        value {
          ...money
        }
      }
      type
      period
      __typename
    }
  }
}
    ${PlayerLimitFragmentDoc}
${MoneyFragmentDoc}`;

/**
 * __useLimitsQuery__
 *
 * To run a query within a Vue component, call `useLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLimitsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLimitsQuery({
 *   withDeposit: // value for 'withDeposit'
 *   withSpending: // value for 'withSpending'
 * });
 */
export function useLimitsQuery(variables: Types.LimitsQueryVariables | VueCompositionApi.Ref<Types.LimitsQueryVariables> | ReactiveFunction<Types.LimitsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<Types.LimitsQuery, Types.LimitsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.LimitsQuery, Types.LimitsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.LimitsQuery, Types.LimitsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.LimitsQuery, Types.LimitsQueryVariables>(LimitsDocument, variables, options);
}
export function useLimitsLazyQuery(variables: Types.LimitsQueryVariables | VueCompositionApi.Ref<Types.LimitsQueryVariables> | ReactiveFunction<Types.LimitsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<Types.LimitsQuery, Types.LimitsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.LimitsQuery, Types.LimitsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.LimitsQuery, Types.LimitsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.LimitsQuery, Types.LimitsQueryVariables>(LimitsDocument, variables, options);
}
export type LimitsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.LimitsQuery, Types.LimitsQueryVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
  requestPasswordReset(input: $input) {
    result
  }
}
    `;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRequestPasswordResetMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(options: VueApolloComposable.UseMutationOptions<Types.RequestPasswordResetMutation, Types.RequestPasswordResetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.RequestPasswordResetMutation, Types.RequestPasswordResetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.RequestPasswordResetMutation, Types.RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
}
export type RequestPasswordResetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.RequestPasswordResetMutation, Types.RequestPasswordResetMutationVariables>;
export const RequestVerificationCodeDocument = gql`
    mutation RequestVerificationCode($input: ResendCodeMutationInput!) {
  requestVerificationCode(input: $input) {
    countryCode
    nationalNumber
  }
}
    `;

/**
 * __useRequestVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestVerificationCodeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRequestVerificationCodeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRequestVerificationCodeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRequestVerificationCodeMutation(options: VueApolloComposable.UseMutationOptions<Types.RequestVerificationCodeMutation, Types.RequestVerificationCodeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.RequestVerificationCodeMutation, Types.RequestVerificationCodeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.RequestVerificationCodeMutation, Types.RequestVerificationCodeMutationVariables>(RequestVerificationCodeDocument, options);
}
export type RequestVerificationCodeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.RequestVerificationCodeMutation, Types.RequestVerificationCodeMutationVariables>;
export const SelfExclusionDocument = gql`
    mutation SelfExclusion($input: SelfExclusionInput!) {
  selfExclusion(input: $input) {
    expires
    type
    status
  }
}
    `;

/**
 * __useSelfExclusionMutation__
 *
 * To run a mutation, you first call `useSelfExclusionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSelfExclusionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSelfExclusionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSelfExclusionMutation(options: VueApolloComposable.UseMutationOptions<Types.SelfExclusionMutation, Types.SelfExclusionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.SelfExclusionMutation, Types.SelfExclusionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.SelfExclusionMutation, Types.SelfExclusionMutationVariables>(SelfExclusionDocument, options);
}
export type SelfExclusionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.SelfExclusionMutation, Types.SelfExclusionMutationVariables>;
export const SetScanVerifyUploadStatusDocument = gql`
    mutation SetScanVerifyUploadStatus($input: SetScanVerifyUploadStatusInput!) {
  setScanVerifyUploadStatus(input: $input) {
    manualVerificationUrl
    name
    status
  }
}
    `;

/**
 * __useSetScanVerifyUploadStatusMutation__
 *
 * To run a mutation, you first call `useSetScanVerifyUploadStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetScanVerifyUploadStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetScanVerifyUploadStatusMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSetScanVerifyUploadStatusMutation(options: VueApolloComposable.UseMutationOptions<Types.SetScanVerifyUploadStatusMutation, Types.SetScanVerifyUploadStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.SetScanVerifyUploadStatusMutation, Types.SetScanVerifyUploadStatusMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.SetScanVerifyUploadStatusMutation, Types.SetScanVerifyUploadStatusMutationVariables>(SetScanVerifyUploadStatusDocument, options);
}
export type SetScanVerifyUploadStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.SetScanVerifyUploadStatusMutation, Types.SetScanVerifyUploadStatusMutationVariables>;
export const StoredPaymentInstrumentsDocument = gql`
    query storedPaymentInstruments($input: StoredPaymentInstrumentsInput!) {
  storedPaymentInstruments(input: $input) {
    activePayInInstruments {
      internalId
      paymentMethod
      type
      ... on StoredCardInstrument {
        cardholderName
        expirationDate
        expiryMonth
        expiryYear
        maskedCardNumber
      }
      ... on StoredInstrumentPayPal {
        holderName
        email
      }
      ... on StoredInstrumentVenmo {
        venmoUserName
      }
    }
  }
}
    `;

/**
 * __useStoredPaymentInstrumentsQuery__
 *
 * To run a query within a Vue component, call `useStoredPaymentInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoredPaymentInstrumentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStoredPaymentInstrumentsQuery({
 *   input: // value for 'input'
 * });
 */
export function useStoredPaymentInstrumentsQuery(variables: Types.StoredPaymentInstrumentsQueryVariables | VueCompositionApi.Ref<Types.StoredPaymentInstrumentsQueryVariables> | ReactiveFunction<Types.StoredPaymentInstrumentsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.StoredPaymentInstrumentsQuery, Types.StoredPaymentInstrumentsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.StoredPaymentInstrumentsQuery, Types.StoredPaymentInstrumentsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.StoredPaymentInstrumentsQuery, Types.StoredPaymentInstrumentsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.StoredPaymentInstrumentsQuery, Types.StoredPaymentInstrumentsQueryVariables>(StoredPaymentInstrumentsDocument, variables, options);
}
export function useStoredPaymentInstrumentsLazyQuery(variables?: Types.StoredPaymentInstrumentsQueryVariables | VueCompositionApi.Ref<Types.StoredPaymentInstrumentsQueryVariables> | ReactiveFunction<Types.StoredPaymentInstrumentsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.StoredPaymentInstrumentsQuery, Types.StoredPaymentInstrumentsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.StoredPaymentInstrumentsQuery, Types.StoredPaymentInstrumentsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.StoredPaymentInstrumentsQuery, Types.StoredPaymentInstrumentsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.StoredPaymentInstrumentsQuery, Types.StoredPaymentInstrumentsQueryVariables>(StoredPaymentInstrumentsDocument, variables, options);
}
export type StoredPaymentInstrumentsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.StoredPaymentInstrumentsQuery, Types.StoredPaymentInstrumentsQueryVariables>;
export const TransactionsDocument = gql`
    query Transactions($filter: ShopTransactionsFilterInput, $limit: Int!, $offset: Int!) {
  transactionHistory(filter: $filter, limit: $limit, offset: $offset) {
    total
    transactions {
      amount {
        ...money
      }
      bookingDate
      comment
      id
      orderId
      payOutId
      paymentIdentifier
      paymentMethod
      paymentProvider
      serviceFees {
        ...money
      }
      totalCharged {
        ...money
      }
      type
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a Vue component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTransactionsQuery({
 *   filter: // value for 'filter'
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 * });
 */
export function useTransactionsQuery(variables: Types.TransactionsQueryVariables | VueCompositionApi.Ref<Types.TransactionsQueryVariables> | ReactiveFunction<Types.TransactionsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.TransactionsQuery, Types.TransactionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.TransactionsQuery, Types.TransactionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.TransactionsQuery, Types.TransactionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.TransactionsQuery, Types.TransactionsQueryVariables>(TransactionsDocument, variables, options);
}
export function useTransactionsLazyQuery(variables?: Types.TransactionsQueryVariables | VueCompositionApi.Ref<Types.TransactionsQueryVariables> | ReactiveFunction<Types.TransactionsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.TransactionsQuery, Types.TransactionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.TransactionsQuery, Types.TransactionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.TransactionsQuery, Types.TransactionsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.TransactionsQuery, Types.TransactionsQueryVariables>(TransactionsDocument, variables, options);
}
export type TransactionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.TransactionsQuery, Types.TransactionsQueryVariables>;
export const UploadIdentityDocumentDocument = gql`
    mutation UploadIdentityDocument($input: UploadIdentityDocumentInput!) {
  uploadIdentityDocument(input: $input) {
    receivedAt
  }
}
    `;

/**
 * __useUploadIdentityDocumentMutation__
 *
 * To run a mutation, you first call `useUploadIdentityDocumentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUploadIdentityDocumentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUploadIdentityDocumentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUploadIdentityDocumentMutation(options: VueApolloComposable.UseMutationOptions<Types.UploadIdentityDocumentMutation, Types.UploadIdentityDocumentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.UploadIdentityDocumentMutation, Types.UploadIdentityDocumentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.UploadIdentityDocumentMutation, Types.UploadIdentityDocumentMutationVariables>(UploadIdentityDocumentDocument, options);
}
export type UploadIdentityDocumentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.UploadIdentityDocumentMutation, Types.UploadIdentityDocumentMutationVariables>;
export const UserDataDocument = gql`
    query UserData {
  player {
    address
    dateOfBirth
    id
    isAutomaticAgeVerificationPending @client
    email
    formattedPhoneNumber @client
    phone {
      countryCode
      number
      status
    }
    givenName
    hasManualAgeVerification @client
    familyName
    customerId
    registrationDate
    status
    isVerified @client
    selfExclusion {
      expires
      type
    }
    shouldVerifyPhone @client
    verifications {
      status
      manualVerificationUrl
      name
      dependsOn
    }
  }
}
    `;

/**
 * __useUserDataQuery__
 *
 * To run a query within a Vue component, call `useUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserDataQuery();
 */
export function useUserDataQuery(options: VueApolloComposable.UseQueryOptions<Types.UserDataQuery, Types.UserDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.UserDataQuery, Types.UserDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.UserDataQuery, Types.UserDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.UserDataQuery, Types.UserDataQueryVariables>(UserDataDocument, {}, options);
}
export function useUserDataLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.UserDataQuery, Types.UserDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.UserDataQuery, Types.UserDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.UserDataQuery, Types.UserDataQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.UserDataQuery, Types.UserDataQueryVariables>(UserDataDocument, {}, options);
}
export type UserDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.UserDataQuery, Types.UserDataQueryVariables>;
export const UserSecurityPhoneDocument = gql`
    query UserSecurityPhone {
  player {
    phone {
      countryCode
      number
      status
    }
    status
    shouldVerifyPhone @client
    isVerified @client
  }
}
    `;

/**
 * __useUserSecurityPhoneQuery__
 *
 * To run a query within a Vue component, call `useUserSecurityPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSecurityPhoneQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserSecurityPhoneQuery();
 */
export function useUserSecurityPhoneQuery(options: VueApolloComposable.UseQueryOptions<Types.UserSecurityPhoneQuery, Types.UserSecurityPhoneQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.UserSecurityPhoneQuery, Types.UserSecurityPhoneQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.UserSecurityPhoneQuery, Types.UserSecurityPhoneQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.UserSecurityPhoneQuery, Types.UserSecurityPhoneQueryVariables>(UserSecurityPhoneDocument, {}, options);
}
export function useUserSecurityPhoneLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.UserSecurityPhoneQuery, Types.UserSecurityPhoneQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.UserSecurityPhoneQuery, Types.UserSecurityPhoneQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.UserSecurityPhoneQuery, Types.UserSecurityPhoneQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.UserSecurityPhoneQuery, Types.UserSecurityPhoneQueryVariables>(UserSecurityPhoneDocument, {}, options);
}
export type UserSecurityPhoneQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.UserSecurityPhoneQuery, Types.UserSecurityPhoneQueryVariables>;
export const UserStatusDocument = gql`
    query UserStatus {
  player {
    selfExclusion {
      expires
      type
    }
    status
  }
}
    `;

/**
 * __useUserStatusQuery__
 *
 * To run a query within a Vue component, call `useUserStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserStatusQuery();
 */
export function useUserStatusQuery(options: VueApolloComposable.UseQueryOptions<Types.UserStatusQuery, Types.UserStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.UserStatusQuery, Types.UserStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.UserStatusQuery, Types.UserStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.UserStatusQuery, Types.UserStatusQueryVariables>(UserStatusDocument, {}, options);
}
export function useUserStatusLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.UserStatusQuery, Types.UserStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.UserStatusQuery, Types.UserStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.UserStatusQuery, Types.UserStatusQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.UserStatusQuery, Types.UserStatusQueryVariables>(UserStatusDocument, {}, options);
}
export type UserStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.UserStatusQuery, Types.UserStatusQueryVariables>;
export const UserVerificationsDocument = gql`
    query UserVerifications {
  player {
    __typename
    verifications {
      __typename
      dependsOn
      manualVerificationUrl
      name
      status
    }
  }
}
    `;

/**
 * __useUserVerificationsQuery__
 *
 * To run a query within a Vue component, call `useUserVerificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserVerificationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserVerificationsQuery();
 */
export function useUserVerificationsQuery(options: VueApolloComposable.UseQueryOptions<Types.UserVerificationsQuery, Types.UserVerificationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.UserVerificationsQuery, Types.UserVerificationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.UserVerificationsQuery, Types.UserVerificationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.UserVerificationsQuery, Types.UserVerificationsQueryVariables>(UserVerificationsDocument, {}, options);
}
export function useUserVerificationsLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.UserVerificationsQuery, Types.UserVerificationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.UserVerificationsQuery, Types.UserVerificationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.UserVerificationsQuery, Types.UserVerificationsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.UserVerificationsQuery, Types.UserVerificationsQueryVariables>(UserVerificationsDocument, {}, options);
}
export type UserVerificationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.UserVerificationsQuery, Types.UserVerificationsQueryVariables>;
export const VerificationDataDocument = gql`
    query VerificationData($includePersonalData: Boolean! = false) {
  player {
    automaticAgeVerification @client {
      dependsOn
      manualVerificationUrl
      name
      status
    }
    customerId
    didAutomaticAgeVerificationFail @client
    didAutomaticAgeVerificationSucceed @client
    email @include(if: $includePersonalData)
    familyName @include(if: $includePersonalData)
    givenName @include(if: $includePersonalData)
    hasAutomaticAgeVerificationStarted @client
    hasManualAgeVerification @client
    hasSendSafelyUpload @client
    idologyUrl @client
    idologyVerification @client {
      dependsOn
      manualVerificationUrl
      name
      status
    }
    isAutomaticAgeVerificationPending @client
    isIdologyPending @client
    isRegistered @client
    isVerified @client
    needsManualVerification @client
    phone {
      status
    }
    shouldVerifyPhone @client
    status
    verifications {
      dependsOn
      manualVerificationUrl
      name
      status
    }
  }
}
    `;

/**
 * __useVerificationDataQuery__
 *
 * To run a query within a Vue component, call `useVerificationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerificationDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVerificationDataQuery({
 *   includePersonalData: // value for 'includePersonalData'
 * });
 */
export function useVerificationDataQuery(variables: Types.VerificationDataQueryVariables | VueCompositionApi.Ref<Types.VerificationDataQueryVariables> | ReactiveFunction<Types.VerificationDataQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.VerificationDataQuery, Types.VerificationDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.VerificationDataQuery, Types.VerificationDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.VerificationDataQuery, Types.VerificationDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.VerificationDataQuery, Types.VerificationDataQueryVariables>(VerificationDataDocument, variables, options);
}
export function useVerificationDataLazyQuery(variables?: Types.VerificationDataQueryVariables | VueCompositionApi.Ref<Types.VerificationDataQueryVariables> | ReactiveFunction<Types.VerificationDataQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.VerificationDataQuery, Types.VerificationDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.VerificationDataQuery, Types.VerificationDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.VerificationDataQuery, Types.VerificationDataQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.VerificationDataQuery, Types.VerificationDataQueryVariables>(VerificationDataDocument, variables, options);
}
export type VerificationDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.VerificationDataQuery, Types.VerificationDataQueryVariables>;
export const VerifyPhoneDocument = gql`
    mutation VerifyPhone($input: VerifyPhoneInput!) {
  verifyPhone(input: $input) {
    verified
  }
}
    `;

/**
 * __useVerifyPhoneMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useVerifyPhoneMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useVerifyPhoneMutation(options: VueApolloComposable.UseMutationOptions<Types.VerifyPhoneMutation, Types.VerifyPhoneMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.VerifyPhoneMutation, Types.VerifyPhoneMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.VerifyPhoneMutation, Types.VerifyPhoneMutationVariables>(VerifyPhoneDocument, options);
}
export type VerifyPhoneMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.VerifyPhoneMutation, Types.VerifyPhoneMutationVariables>;
export const BuyWithBalanceDocument = gql`
    mutation BuyWithBalance($licensedTerritory: String!, $locationProfilingId: String!, $locationSessionId: String, $productId: ProductId!, $submitterIPAddress: String) {
  purchase: buySingleProductWithBalance(
    licensedTerritory: $licensedTerritory
    locationProfilingId: $locationProfilingId
    locationSessionId: $locationSessionId
    productId: $productId
    submitterIPAddress: $submitterIPAddress
  ) {
    firstPayIn
    orderId
    reference
    status
  }
}
    `;

/**
 * __useBuyWithBalanceMutation__
 *
 * To run a mutation, you first call `useBuyWithBalanceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useBuyWithBalanceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useBuyWithBalanceMutation({
 *   variables: {
 *     licensedTerritory: // value for 'licensedTerritory'
 *     locationProfilingId: // value for 'locationProfilingId'
 *     locationSessionId: // value for 'locationSessionId'
 *     productId: // value for 'productId'
 *     submitterIPAddress: // value for 'submitterIPAddress'
 *   },
 * });
 */
export function useBuyWithBalanceMutation(options: VueApolloComposable.UseMutationOptions<Types.BuyWithBalanceMutation, Types.BuyWithBalanceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.BuyWithBalanceMutation, Types.BuyWithBalanceMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.BuyWithBalanceMutation, Types.BuyWithBalanceMutationVariables>(BuyWithBalanceDocument, options);
}
export type BuyWithBalanceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.BuyWithBalanceMutation, Types.BuyWithBalanceMutationVariables>;
export const BuyWithTopUpDocument = gql`
    mutation BuyWithTopUp($licensedTerritory: String!, $locationProfilingId: String!, $locationSessionId: String, $paymentData: PaymentDataInput!, $productId: ProductId!, $submitterIPAddress: String) {
  purchase: buySingleProductWithTopUp(
    licensedTerritory: $licensedTerritory
    locationProfilingId: $locationProfilingId
    locationSessionId: $locationSessionId
    paymentData: $paymentData
    productId: $productId
    submitterIPAddress: $submitterIPAddress
  ) {
    firstPayIn
    orderId
    reference
    status
  }
}
    `;

/**
 * __useBuyWithTopUpMutation__
 *
 * To run a mutation, you first call `useBuyWithTopUpMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useBuyWithTopUpMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useBuyWithTopUpMutation({
 *   variables: {
 *     licensedTerritory: // value for 'licensedTerritory'
 *     locationProfilingId: // value for 'locationProfilingId'
 *     locationSessionId: // value for 'locationSessionId'
 *     paymentData: // value for 'paymentData'
 *     productId: // value for 'productId'
 *     submitterIPAddress: // value for 'submitterIPAddress'
 *   },
 * });
 */
export function useBuyWithTopUpMutation(options: VueApolloComposable.UseMutationOptions<Types.BuyWithTopUpMutation, Types.BuyWithTopUpMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.BuyWithTopUpMutation, Types.BuyWithTopUpMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.BuyWithTopUpMutation, Types.BuyWithTopUpMutationVariables>(BuyWithTopUpDocument, options);
}
export type BuyWithTopUpMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.BuyWithTopUpMutation, Types.BuyWithTopUpMutationVariables>;
export const CheckoutDocument = gql`
    query Checkout($licensedTerritory: String!, $productId: ProductId!) {
  checkout: singleProductCheckout(
    licensedTerritory: $licensedTerritory
    productId: $productId
  ) {
    accountBalance {
      ...money
    }
    bonusCode
    discount {
      ...money
    }
    restrictions {
      geoCheck
      playerCheck
      depositLimit {
        available {
          ...money
        }
        value {
          ...money
        }
        period
        type
      }
      spendingLimit {
        available {
          ...money
        }
        value {
          ...money
        }
        period
        type
      }
    }
    payment {
      ... on PayWithTopUp {
        initial {
          type
          integrationType
          methods
          accountId
          provider
          providerUri
          token {
            expires
            value
          }
          venmoProfileId
        }
        stored {
          type
          integrationType
          method
          holderName
          accountId
          cardNumber
          expiryMonth
          expiryYear
          provider
          requiresCvvConfirmation
          token {
            expires
            value
          }
        }
        paymentId
      }
      accountBalance {
        ...money
      }
      payWithBalance
      topUpAmount {
        ...money
      }
      totalPrice {
        ...money
      }
    }
    product {
      ... on LotteryProduct {
        name
        price {
          ...money
        }
        numberOfDraws
        numberOfPlays
        priceBreakdown {
          addOnChargePerPlaySlip {
            ...money
          }
          addOnFeePerPlaySlip {
            ...money
          }
          chargePerPlaySlip {
            ...money
          }
          chargeTotal {
            ...money
          }
          chargesForPlaySlipAddOns {
            baseCharge {
              ...money
            }
            fee {
              ...money
            }
            name
            total {
              ...money
            }
          }
          chargesPerPlay {
            addOns {
              baseCharge {
                ...money
              }
              fee {
                ...money
              }
              name
              total {
                ...money
              }
            }
            baseCharge {
              ...money
            }
            extraInfo
            fee {
              ...money
            }
            name
          }
          feePerPlaySlip {
            ...money
          }
          feeTotal {
            ...money
          }
          grossTotal {
            ...money
          }
          libraryVersion
          lotteryConfigVersion
          netTotal {
            ...money
          }
          numberOfDraws
          numberOfPlays
          playChargePerPlaySlip {
            ...money
          }
          playFeePerPlaySlip {
            ...money
          }
          priceListVersion
          salesTax {
            ...money
          }
        }
        subscription
        subscriptionOptions {
          drawsPerPurchase
          type
        }
        __typename
      }
      ... on ScratchcardProduct {
        expiresAt
        name
        price {
          ...money
        }
        priceBreakdown {
          chargeForOrder {
            ...money
          }
          chargeTotal {
            ...money
          }
          chargesForCards {
            ...money
          }
          feeTotal {
            ...money
          }
          feesForCards {
            ...money
          }
          feeForOrder {
            ...money
          }
          grossTotal {
            ...money
          }
          netTotal {
            ...money
          }
          quantity
          salesTax {
            ...money
          }
        }
        quantity
        reservationId
        __typename
      }
    }
    state
    notification
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a Vue component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCheckoutQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 *   productId: // value for 'productId'
 * });
 */
export function useCheckoutQuery(variables: Types.CheckoutQueryVariables | VueCompositionApi.Ref<Types.CheckoutQueryVariables> | ReactiveFunction<Types.CheckoutQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.CheckoutQuery, Types.CheckoutQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckoutQuery, Types.CheckoutQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckoutQuery, Types.CheckoutQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.CheckoutQuery, Types.CheckoutQueryVariables>(CheckoutDocument, variables, options);
}
export function useCheckoutLazyQuery(variables?: Types.CheckoutQueryVariables | VueCompositionApi.Ref<Types.CheckoutQueryVariables> | ReactiveFunction<Types.CheckoutQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.CheckoutQuery, Types.CheckoutQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckoutQuery, Types.CheckoutQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckoutQuery, Types.CheckoutQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.CheckoutQuery, Types.CheckoutQueryVariables>(CheckoutDocument, variables, options);
}
export type CheckoutQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.CheckoutQuery, Types.CheckoutQueryVariables>;
export const OrderPollDocument = gql`
    query OrderPoll($orderId: OrderId!) {
  order(id: $orderId) {
    ... on CancelledOrder {
      cancelReasonType
    }
    status
  }
}
    `;

/**
 * __useOrderPollQuery__
 *
 * To run a query within a Vue component, call `useOrderPollQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderPollQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrderPollQuery({
 *   orderId: // value for 'orderId'
 * });
 */
export function useOrderPollQuery(variables: Types.OrderPollQueryVariables | VueCompositionApi.Ref<Types.OrderPollQueryVariables> | ReactiveFunction<Types.OrderPollQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.OrderPollQuery, Types.OrderPollQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.OrderPollQuery, Types.OrderPollQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.OrderPollQuery, Types.OrderPollQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.OrderPollQuery, Types.OrderPollQueryVariables>(OrderPollDocument, variables, options);
}
export function useOrderPollLazyQuery(variables?: Types.OrderPollQueryVariables | VueCompositionApi.Ref<Types.OrderPollQueryVariables> | ReactiveFunction<Types.OrderPollQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.OrderPollQuery, Types.OrderPollQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.OrderPollQuery, Types.OrderPollQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.OrderPollQuery, Types.OrderPollQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.OrderPollQuery, Types.OrderPollQueryVariables>(OrderPollDocument, variables, options);
}
export type OrderPollQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.OrderPollQuery, Types.OrderPollQueryVariables>;
export const OrderedTypeDocument = gql`
    query OrderedType {
  orderedType {
    lottery
    scratchcard
    subscription
  }
}
    `;

/**
 * __useOrderedTypeQuery__
 *
 * To run a query within a Vue component, call `useOrderedTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderedTypeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrderedTypeQuery();
 */
export function useOrderedTypeQuery(options: VueApolloComposable.UseQueryOptions<Types.OrderedTypeQuery, Types.OrderedTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.OrderedTypeQuery, Types.OrderedTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.OrderedTypeQuery, Types.OrderedTypeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.OrderedTypeQuery, Types.OrderedTypeQueryVariables>(OrderedTypeDocument, {}, options);
}
export function useOrderedTypeLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.OrderedTypeQuery, Types.OrderedTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.OrderedTypeQuery, Types.OrderedTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.OrderedTypeQuery, Types.OrderedTypeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.OrderedTypeQuery, Types.OrderedTypeQueryVariables>(OrderedTypeDocument, {}, options);
}
export type OrderedTypeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.OrderedTypeQuery, Types.OrderedTypeQueryVariables>;
export const ApplyBonusCodeDocument = gql`
    mutation ApplyBonusCode($bonusCode: String!, $productId: String!) {
  applyBonusCode(bonusCode: $bonusCode, productId: $productId) {
    ...couponValidationResult
  }
}
    ${CouponValidationResultFragmentDoc}`;

/**
 * __useApplyBonusCodeMutation__
 *
 * To run a mutation, you first call `useApplyBonusCodeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useApplyBonusCodeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useApplyBonusCodeMutation({
 *   variables: {
 *     bonusCode: // value for 'bonusCode'
 *     productId: // value for 'productId'
 *   },
 * });
 */
export function useApplyBonusCodeMutation(options: VueApolloComposable.UseMutationOptions<Types.ApplyBonusCodeMutation, Types.ApplyBonusCodeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.ApplyBonusCodeMutation, Types.ApplyBonusCodeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.ApplyBonusCodeMutation, Types.ApplyBonusCodeMutationVariables>(ApplyBonusCodeDocument, options);
}
export type ApplyBonusCodeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.ApplyBonusCodeMutation, Types.ApplyBonusCodeMutationVariables>;
export const BonusCodeInfoDocument = gql`
    query BonusCodeInfo($bonusCode: String!) {
  bonusCodeInfo(code: $bonusCode) {
    ...couponInfo
  }
}
    ${CouponInfoFragmentDoc}`;

/**
 * __useBonusCodeInfoQuery__
 *
 * To run a query within a Vue component, call `useBonusCodeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusCodeInfoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBonusCodeInfoQuery({
 *   bonusCode: // value for 'bonusCode'
 * });
 */
export function useBonusCodeInfoQuery(variables: Types.BonusCodeInfoQueryVariables | VueCompositionApi.Ref<Types.BonusCodeInfoQueryVariables> | ReactiveFunction<Types.BonusCodeInfoQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.BonusCodeInfoQuery, Types.BonusCodeInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.BonusCodeInfoQuery, Types.BonusCodeInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.BonusCodeInfoQuery, Types.BonusCodeInfoQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.BonusCodeInfoQuery, Types.BonusCodeInfoQueryVariables>(BonusCodeInfoDocument, variables, options);
}
export function useBonusCodeInfoLazyQuery(variables?: Types.BonusCodeInfoQueryVariables | VueCompositionApi.Ref<Types.BonusCodeInfoQueryVariables> | ReactiveFunction<Types.BonusCodeInfoQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.BonusCodeInfoQuery, Types.BonusCodeInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.BonusCodeInfoQuery, Types.BonusCodeInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.BonusCodeInfoQuery, Types.BonusCodeInfoQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.BonusCodeInfoQuery, Types.BonusCodeInfoQueryVariables>(BonusCodeInfoDocument, variables, options);
}
export type BonusCodeInfoQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.BonusCodeInfoQuery, Types.BonusCodeInfoQueryVariables>;
export const CheckBonusCodeForPlaySlipDocument = gql`
    query CheckBonusCodeForPlaySlip($input: BonusCodeValidationInput!) {
  checkBonusCodeForPlaySlip(input: $input) {
    ...couponValidationResult
  }
}
    ${CouponValidationResultFragmentDoc}`;

/**
 * __useCheckBonusCodeForPlaySlipQuery__
 *
 * To run a query within a Vue component, call `useCheckBonusCodeForPlaySlipQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBonusCodeForPlaySlipQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCheckBonusCodeForPlaySlipQuery({
 *   input: // value for 'input'
 * });
 */
export function useCheckBonusCodeForPlaySlipQuery(variables: Types.CheckBonusCodeForPlaySlipQueryVariables | VueCompositionApi.Ref<Types.CheckBonusCodeForPlaySlipQueryVariables> | ReactiveFunction<Types.CheckBonusCodeForPlaySlipQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.CheckBonusCodeForPlaySlipQuery, Types.CheckBonusCodeForPlaySlipQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckBonusCodeForPlaySlipQuery, Types.CheckBonusCodeForPlaySlipQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckBonusCodeForPlaySlipQuery, Types.CheckBonusCodeForPlaySlipQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.CheckBonusCodeForPlaySlipQuery, Types.CheckBonusCodeForPlaySlipQueryVariables>(CheckBonusCodeForPlaySlipDocument, variables, options);
}
export function useCheckBonusCodeForPlaySlipLazyQuery(variables?: Types.CheckBonusCodeForPlaySlipQueryVariables | VueCompositionApi.Ref<Types.CheckBonusCodeForPlaySlipQueryVariables> | ReactiveFunction<Types.CheckBonusCodeForPlaySlipQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.CheckBonusCodeForPlaySlipQuery, Types.CheckBonusCodeForPlaySlipQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckBonusCodeForPlaySlipQuery, Types.CheckBonusCodeForPlaySlipQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckBonusCodeForPlaySlipQuery, Types.CheckBonusCodeForPlaySlipQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.CheckBonusCodeForPlaySlipQuery, Types.CheckBonusCodeForPlaySlipQueryVariables>(CheckBonusCodeForPlaySlipDocument, variables, options);
}
export type CheckBonusCodeForPlaySlipQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.CheckBonusCodeForPlaySlipQuery, Types.CheckBonusCodeForPlaySlipQueryVariables>;
export const DefaultTeasersDocument = gql`
    query DefaultTeasers($licensedTerritory: String!, $productType: KnownProductType!) {
  defaultTeasers(licensedTerritory: $licensedTerritory, productType: $productType) {
    id
    productType
    teasers {
      ...scratchcardTeaser
      ...lotteryTeaser
    }
  }
}
    ${ScratchcardTeaserFragmentDoc}
${LotteryTeaserFragmentDoc}`;

/**
 * __useDefaultTeasersQuery__
 *
 * To run a query within a Vue component, call `useDefaultTeasersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultTeasersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDefaultTeasersQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 *   productType: // value for 'productType'
 * });
 */
export function useDefaultTeasersQuery(variables: Types.DefaultTeasersQueryVariables | VueCompositionApi.Ref<Types.DefaultTeasersQueryVariables> | ReactiveFunction<Types.DefaultTeasersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.DefaultTeasersQuery, Types.DefaultTeasersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.DefaultTeasersQuery, Types.DefaultTeasersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.DefaultTeasersQuery, Types.DefaultTeasersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.DefaultTeasersQuery, Types.DefaultTeasersQueryVariables>(DefaultTeasersDocument, variables, options);
}
export function useDefaultTeasersLazyQuery(variables?: Types.DefaultTeasersQueryVariables | VueCompositionApi.Ref<Types.DefaultTeasersQueryVariables> | ReactiveFunction<Types.DefaultTeasersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.DefaultTeasersQuery, Types.DefaultTeasersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.DefaultTeasersQuery, Types.DefaultTeasersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.DefaultTeasersQuery, Types.DefaultTeasersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.DefaultTeasersQuery, Types.DefaultTeasersQueryVariables>(DefaultTeasersDocument, variables, options);
}
export type DefaultTeasersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.DefaultTeasersQuery, Types.DefaultTeasersQueryVariables>;
export const RedeemMoneyDocument = gql`
    mutation RedeemMoney($input: MoneyBackInput!) {
  requestMoneyBack(input: $input) {
    code
    id
    price {
      ...money
    }
    productId
    status
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useRedeemMoneyMutation__
 *
 * To run a mutation, you first call `useRedeemMoneyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRedeemMoneyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRedeemMoneyMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRedeemMoneyMutation(options: VueApolloComposable.UseMutationOptions<Types.RedeemMoneyMutation, Types.RedeemMoneyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.RedeemMoneyMutation, Types.RedeemMoneyMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.RedeemMoneyMutation, Types.RedeemMoneyMutationVariables>(RedeemMoneyDocument, options);
}
export type RedeemMoneyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.RedeemMoneyMutation, Types.RedeemMoneyMutationVariables>;
export const RemoveBonusCodeDocument = gql`
    mutation RemoveBonusCode($bonusCode: String!, $productId: String!) {
  removeBonusCode(bonusCode: $bonusCode, productId: $productId) {
    bonusCode
  }
}
    `;

/**
 * __useRemoveBonusCodeMutation__
 *
 * To run a mutation, you first call `useRemoveBonusCodeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBonusCodeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveBonusCodeMutation({
 *   variables: {
 *     bonusCode: // value for 'bonusCode'
 *     productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveBonusCodeMutation(options: VueApolloComposable.UseMutationOptions<Types.RemoveBonusCodeMutation, Types.RemoveBonusCodeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.RemoveBonusCodeMutation, Types.RemoveBonusCodeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.RemoveBonusCodeMutation, Types.RemoveBonusCodeMutationVariables>(RemoveBonusCodeDocument, options);
}
export type RemoveBonusCodeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.RemoveBonusCodeMutation, Types.RemoveBonusCodeMutationVariables>;
export const TeasersDocument = gql`
    query Teasers($licensedTerritory: String!) {
  teasers(licensedTerritory: $licensedTerritory) {
    id
    teasers {
      ...lotteryTeaser
      ... on ScratchcardTeaserPayload {
        name
        slug
        price {
          currency
          value
        }
        amount {
          currency
          value
        }
        campaign {
          ...campaign
        }
        visualParameters {
          primaryColor
          secondaryColor
          teaserImageUrl
          titleColor
        }
      }
    }
  }
}
    ${LotteryTeaserFragmentDoc}
${CampaignFragmentDoc}`;

/**
 * __useTeasersQuery__
 *
 * To run a query within a Vue component, call `useTeasersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeasersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTeasersQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 * });
 */
export function useTeasersQuery(variables: Types.TeasersQueryVariables | VueCompositionApi.Ref<Types.TeasersQueryVariables> | ReactiveFunction<Types.TeasersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.TeasersQuery, Types.TeasersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.TeasersQuery, Types.TeasersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.TeasersQuery, Types.TeasersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.TeasersQuery, Types.TeasersQueryVariables>(TeasersDocument, variables, options);
}
export function useTeasersLazyQuery(variables?: Types.TeasersQueryVariables | VueCompositionApi.Ref<Types.TeasersQueryVariables> | ReactiveFunction<Types.TeasersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.TeasersQuery, Types.TeasersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.TeasersQuery, Types.TeasersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.TeasersQuery, Types.TeasersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.TeasersQuery, Types.TeasersQueryVariables>(TeasersDocument, variables, options);
}
export type TeasersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.TeasersQuery, Types.TeasersQueryVariables>;
export const CookiesDocument = gql`
    query Cookies {
  cookies @client
}
    `;

/**
 * __useCookiesQuery__
 *
 * To run a query within a Vue component, call `useCookiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCookiesQuery();
 */
export function useCookiesQuery(options: VueApolloComposable.UseQueryOptions<Types.CookiesQuery, Types.CookiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CookiesQuery, Types.CookiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CookiesQuery, Types.CookiesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.CookiesQuery, Types.CookiesQueryVariables>(CookiesDocument, {}, options);
}
export function useCookiesLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.CookiesQuery, Types.CookiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CookiesQuery, Types.CookiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CookiesQuery, Types.CookiesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.CookiesQuery, Types.CookiesQueryVariables>(CookiesDocument, {}, options);
}
export type CookiesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.CookiesQuery, Types.CookiesQueryVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($code: SubscriptionCode!) {
  cancelSubscription(input: {code: $code}) {
    status
  }
}
    `;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCancelSubscriptionMutation({
 *   variables: {
 *     code: // value for 'code'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(options: VueApolloComposable.UseMutationOptions<Types.CancelSubscriptionMutation, Types.CancelSubscriptionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.CancelSubscriptionMutation, Types.CancelSubscriptionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.CancelSubscriptionMutation, Types.CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
}
export type CancelSubscriptionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.CancelSubscriptionMutation, Types.CancelSubscriptionMutationVariables>;
export const LotteriesDocument = gql`
    query Lotteries($licensedTerritory: String!) {
  lotteries(licensedTerritory: $licensedTerritory) {
    id
    currentConfig {
      jackpotType
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useLotteriesQuery__
 *
 * To run a query within a Vue component, call `useLotteriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotteriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLotteriesQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 * });
 */
export function useLotteriesQuery(variables: Types.LotteriesQueryVariables | VueCompositionApi.Ref<Types.LotteriesQueryVariables> | ReactiveFunction<Types.LotteriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.LotteriesQuery, Types.LotteriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.LotteriesQuery, Types.LotteriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.LotteriesQuery, Types.LotteriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.LotteriesQuery, Types.LotteriesQueryVariables>(LotteriesDocument, variables, options);
}
export function useLotteriesLazyQuery(variables?: Types.LotteriesQueryVariables | VueCompositionApi.Ref<Types.LotteriesQueryVariables> | ReactiveFunction<Types.LotteriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.LotteriesQuery, Types.LotteriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.LotteriesQuery, Types.LotteriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.LotteriesQuery, Types.LotteriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.LotteriesQuery, Types.LotteriesQueryVariables>(LotteriesDocument, variables, options);
}
export type LotteriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.LotteriesQuery, Types.LotteriesQueryVariables>;
export const LotteryDocument = gql`
    query Lottery($licensedTerritory: String!, $lotteryId: LotteryId!) {
  lottery(id: $lotteryId, licensedTerritory: $licensedTerritory) {
    id
    currentConfig {
      addOns {
        ...addOn
      }
      afterPurchaseAddOns {
        ...addOn
      }
      allowedPlays
      isLockoutLottery
      numberOfDraws
      prepaidSubscription {
        customDrawsMax
        customDrawsMin
        selectableDrawsOptions
      }
      numbers {
        ignoredForAddOns {
          name
          value
        }
        max
        min
        name
        selectableMax
      }
      prizeTierCategories {
        ...prizeTierCategory
      }
      quickPick {
        addOns {
          name
          value
        }
        plays
        validQuickPickPlays
      }
      stakes {
        price
      }
      subscriptionsAllowed
      type
      version
    }
    priceList {
      charges {
        ... on FixedCharge {
          id
          perPlay
          type
          value {
            ...money
          }
        }
        ... on MultiplyingCharge {
          id
          perPlay
          type
          multiplier
        }
      }
      fees {
        ... on FixedFee {
          id
          perPlay
          type
          value {
            ...money
          }
        }
        ... on MultiplyingFee {
          id
          perPlay
          type
          multiplier
        }
      }
      lotteryId
      salesTaxStrategy
      version
    }
    properties
  }
}
    ${AddOnFragmentDoc}
${PrizeTierCategoryFragmentDoc}
${MoneyFragmentDoc}`;

/**
 * __useLotteryQuery__
 *
 * To run a query within a Vue component, call `useLotteryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotteryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLotteryQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 *   lotteryId: // value for 'lotteryId'
 * });
 */
export function useLotteryQuery(variables: Types.LotteryQueryVariables | VueCompositionApi.Ref<Types.LotteryQueryVariables> | ReactiveFunction<Types.LotteryQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.LotteryQuery, Types.LotteryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.LotteryQuery, Types.LotteryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.LotteryQuery, Types.LotteryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.LotteryQuery, Types.LotteryQueryVariables>(LotteryDocument, variables, options);
}
export function useLotteryLazyQuery(variables?: Types.LotteryQueryVariables | VueCompositionApi.Ref<Types.LotteryQueryVariables> | ReactiveFunction<Types.LotteryQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.LotteryQuery, Types.LotteryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.LotteryQuery, Types.LotteryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.LotteryQuery, Types.LotteryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.LotteryQuery, Types.LotteryQueryVariables>(LotteryDocument, variables, options);
}
export type LotteryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.LotteryQuery, Types.LotteryQueryVariables>;
export const LotteryTeasersDocument = gql`
    query LotteryTeasers($licensedTerritory: String!) {
  lotteryTeasers(licensedTerritory: $licensedTerritory) {
    ...lotteryTeaser
  }
}
    ${LotteryTeaserFragmentDoc}`;

/**
 * __useLotteryTeasersQuery__
 *
 * To run a query within a Vue component, call `useLotteryTeasersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotteryTeasersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLotteryTeasersQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 * });
 */
export function useLotteryTeasersQuery(variables: Types.LotteryTeasersQueryVariables | VueCompositionApi.Ref<Types.LotteryTeasersQueryVariables> | ReactiveFunction<Types.LotteryTeasersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.LotteryTeasersQuery, Types.LotteryTeasersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.LotteryTeasersQuery, Types.LotteryTeasersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.LotteryTeasersQuery, Types.LotteryTeasersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.LotteryTeasersQuery, Types.LotteryTeasersQueryVariables>(LotteryTeasersDocument, variables, options);
}
export function useLotteryTeasersLazyQuery(variables?: Types.LotteryTeasersQueryVariables | VueCompositionApi.Ref<Types.LotteryTeasersQueryVariables> | ReactiveFunction<Types.LotteryTeasersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.LotteryTeasersQuery, Types.LotteryTeasersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.LotteryTeasersQuery, Types.LotteryTeasersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.LotteryTeasersQuery, Types.LotteryTeasersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.LotteryTeasersQuery, Types.LotteryTeasersQueryVariables>(LotteryTeasersDocument, variables, options);
}
export type LotteryTeasersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.LotteryTeasersQuery, Types.LotteryTeasersQueryVariables>;
export const NextDrawDocument = gql`
    query NextDraw($licensedTerritory: String!, $lotteryId: LotteryId!) {
  nextDraw(licensedTerritory: $licensedTerritory, lotteryId: $lotteryId) {
    jackpots {
      value {
        ...money
      }
    }
    drawDate
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useNextDrawQuery__
 *
 * To run a query within a Vue component, call `useNextDrawQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextDrawQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useNextDrawQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 *   lotteryId: // value for 'lotteryId'
 * });
 */
export function useNextDrawQuery(variables: Types.NextDrawQueryVariables | VueCompositionApi.Ref<Types.NextDrawQueryVariables> | ReactiveFunction<Types.NextDrawQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.NextDrawQuery, Types.NextDrawQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.NextDrawQuery, Types.NextDrawQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.NextDrawQuery, Types.NextDrawQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.NextDrawQuery, Types.NextDrawQueryVariables>(NextDrawDocument, variables, options);
}
export function useNextDrawLazyQuery(variables?: Types.NextDrawQueryVariables | VueCompositionApi.Ref<Types.NextDrawQueryVariables> | ReactiveFunction<Types.NextDrawQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.NextDrawQuery, Types.NextDrawQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.NextDrawQuery, Types.NextDrawQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.NextDrawQuery, Types.NextDrawQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.NextDrawQuery, Types.NextDrawQueryVariables>(NextDrawDocument, variables, options);
}
export type NextDrawQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.NextDrawQuery, Types.NextDrawQueryVariables>;
export const OrderTypeDocument = gql`
    query orderType($orderId: String!) {
  orderType: singleOrderMultipleLines(orderId: $orderId) {
    type
  }
}
    `;

/**
 * __useOrderTypeQuery__
 *
 * To run a query within a Vue component, call `useOrderTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTypeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrderTypeQuery({
 *   orderId: // value for 'orderId'
 * });
 */
export function useOrderTypeQuery(variables: Types.OrderTypeQueryVariables | VueCompositionApi.Ref<Types.OrderTypeQueryVariables> | ReactiveFunction<Types.OrderTypeQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.OrderTypeQuery, Types.OrderTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.OrderTypeQuery, Types.OrderTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.OrderTypeQuery, Types.OrderTypeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.OrderTypeQuery, Types.OrderTypeQueryVariables>(OrderTypeDocument, variables, options);
}
export function useOrderTypeLazyQuery(variables?: Types.OrderTypeQueryVariables | VueCompositionApi.Ref<Types.OrderTypeQueryVariables> | ReactiveFunction<Types.OrderTypeQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.OrderTypeQuery, Types.OrderTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.OrderTypeQuery, Types.OrderTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.OrderTypeQuery, Types.OrderTypeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.OrderTypeQuery, Types.OrderTypeQueryVariables>(OrderTypeDocument, variables, options);
}
export type OrderTypeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.OrderTypeQuery, Types.OrderTypeQueryVariables>;
export const PlaySlipOrdersDocument = gql`
    query PlaySlipOrders($filter: PlaySlipOrdersFilterInput!, $limit: Int!, $offset: Int!) {
  playSlipOrders(filter: $filter, offset: $offset, limit: $limit) {
    count
    playSlipOrders {
      id
      orderId
      lastDrawDate
      licensedTerritory
      lotteryId
      nextDrawDate
      numberOfPlays
      selectedAddOns
      status
      subscriptionInfo {
        status
      }
      ... on ActivePlaySlipOrder {
        hasWinnings
        totalWinAmount {
          currency
          value
        }
      }
      ... on ClosedPlaySlipOrder {
        hasWinnings
        totalWinAmount {
          currency
          value
        }
      }
    }
  }
}
    `;

/**
 * __usePlaySlipOrdersQuery__
 *
 * To run a query within a Vue component, call `usePlaySlipOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaySlipOrdersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePlaySlipOrdersQuery({
 *   filter: // value for 'filter'
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 * });
 */
export function usePlaySlipOrdersQuery(variables: Types.PlaySlipOrdersQueryVariables | VueCompositionApi.Ref<Types.PlaySlipOrdersQueryVariables> | ReactiveFunction<Types.PlaySlipOrdersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.PlaySlipOrdersQuery, Types.PlaySlipOrdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.PlaySlipOrdersQuery, Types.PlaySlipOrdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.PlaySlipOrdersQuery, Types.PlaySlipOrdersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.PlaySlipOrdersQuery, Types.PlaySlipOrdersQueryVariables>(PlaySlipOrdersDocument, variables, options);
}
export function usePlaySlipOrdersLazyQuery(variables?: Types.PlaySlipOrdersQueryVariables | VueCompositionApi.Ref<Types.PlaySlipOrdersQueryVariables> | ReactiveFunction<Types.PlaySlipOrdersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.PlaySlipOrdersQuery, Types.PlaySlipOrdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.PlaySlipOrdersQuery, Types.PlaySlipOrdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.PlaySlipOrdersQuery, Types.PlaySlipOrdersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.PlaySlipOrdersQuery, Types.PlaySlipOrdersQueryVariables>(PlaySlipOrdersDocument, variables, options);
}
export type PlaySlipOrdersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.PlaySlipOrdersQuery, Types.PlaySlipOrdersQueryVariables>;
export const RecommendationsDocument = gql`
    query Recommendations($licensedTerritory: String!, $purchasedProductType: KnownProductType!, $purchasedProductSubtype: String!) {
  recommendations(
    licensedTerritory: $licensedTerritory
    purchasedProductType: $purchasedProductType
    purchasedProductSubtype: $purchasedProductSubtype
  ) {
    id
    teasers {
      ... on LotteryTeaserPayload {
        id
        name
        closingDate
        drawDate
        jackpotSize {
          value
          currency
        }
        jackpotType
        lotteryType
        comingSoon
        campaign {
          ...campaign
        }
        quickPick {
          plays
        }
      }
      ... on ScratchcardTeaserPayload {
        slug
        name
        amount {
          value
          currency
        }
        campaign {
          ...campaign
        }
        visualParameters {
          titleColor
          primaryColor
          secondaryColor
          teaserImageUrl
        }
      }
    }
  }
}
    ${CampaignFragmentDoc}`;

/**
 * __useRecommendationsQuery__
 *
 * To run a query within a Vue component, call `useRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useRecommendationsQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 *   purchasedProductType: // value for 'purchasedProductType'
 *   purchasedProductSubtype: // value for 'purchasedProductSubtype'
 * });
 */
export function useRecommendationsQuery(variables: Types.RecommendationsQueryVariables | VueCompositionApi.Ref<Types.RecommendationsQueryVariables> | ReactiveFunction<Types.RecommendationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.RecommendationsQuery, Types.RecommendationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.RecommendationsQuery, Types.RecommendationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.RecommendationsQuery, Types.RecommendationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.RecommendationsQuery, Types.RecommendationsQueryVariables>(RecommendationsDocument, variables, options);
}
export function useRecommendationsLazyQuery(variables?: Types.RecommendationsQueryVariables | VueCompositionApi.Ref<Types.RecommendationsQueryVariables> | ReactiveFunction<Types.RecommendationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.RecommendationsQuery, Types.RecommendationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.RecommendationsQuery, Types.RecommendationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.RecommendationsQuery, Types.RecommendationsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.RecommendationsQuery, Types.RecommendationsQueryVariables>(RecommendationsDocument, variables, options);
}
export type RecommendationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.RecommendationsQuery, Types.RecommendationsQueryVariables>;
export const ReplayPlaySlipDocument = gql`
    mutation ReplayPlaySlip($licensedTerritory: String!, $playSlipOrderId: String) {
  replayPlaySlip(
    licensedTerritory: $licensedTerritory
    playSlipOrderId: $playSlipOrderId
  ) {
    id
  }
}
    `;

/**
 * __useReplayPlaySlipMutation__
 *
 * To run a mutation, you first call `useReplayPlaySlipMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReplayPlaySlipMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReplayPlaySlipMutation({
 *   variables: {
 *     licensedTerritory: // value for 'licensedTerritory'
 *     playSlipOrderId: // value for 'playSlipOrderId'
 *   },
 * });
 */
export function useReplayPlaySlipMutation(options: VueApolloComposable.UseMutationOptions<Types.ReplayPlaySlipMutation, Types.ReplayPlaySlipMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.ReplayPlaySlipMutation, Types.ReplayPlaySlipMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.ReplayPlaySlipMutation, Types.ReplayPlaySlipMutationVariables>(ReplayPlaySlipDocument, options);
}
export type ReplayPlaySlipMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.ReplayPlaySlipMutation, Types.ReplayPlaySlipMutationVariables>;
export const ScheduledDrawsDocument = gql`
    query ScheduledDraws($licensedTerritory: String!, $limit: Int!, $lotteryId: LotteryId!) {
  scheduledDraws(
    licensedTerritory: $licensedTerritory
    limit: $limit
    lotteryId: $lotteryId
  ) {
    closingDate
    lotteryConfig {
      currentConfig {
        type
      }
    }
    drawDate
    jackpots {
      value {
        ...money
      }
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useScheduledDrawsQuery__
 *
 * To run a query within a Vue component, call `useScheduledDrawsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledDrawsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useScheduledDrawsQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 *   limit: // value for 'limit'
 *   lotteryId: // value for 'lotteryId'
 * });
 */
export function useScheduledDrawsQuery(variables: Types.ScheduledDrawsQueryVariables | VueCompositionApi.Ref<Types.ScheduledDrawsQueryVariables> | ReactiveFunction<Types.ScheduledDrawsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScheduledDrawsQuery, Types.ScheduledDrawsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScheduledDrawsQuery, Types.ScheduledDrawsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScheduledDrawsQuery, Types.ScheduledDrawsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.ScheduledDrawsQuery, Types.ScheduledDrawsQueryVariables>(ScheduledDrawsDocument, variables, options);
}
export function useScheduledDrawsLazyQuery(variables?: Types.ScheduledDrawsQueryVariables | VueCompositionApi.Ref<Types.ScheduledDrawsQueryVariables> | ReactiveFunction<Types.ScheduledDrawsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScheduledDrawsQuery, Types.ScheduledDrawsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScheduledDrawsQuery, Types.ScheduledDrawsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScheduledDrawsQuery, Types.ScheduledDrawsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.ScheduledDrawsQuery, Types.ScheduledDrawsQueryVariables>(ScheduledDrawsDocument, variables, options);
}
export type ScheduledDrawsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.ScheduledDrawsQuery, Types.ScheduledDrawsQueryVariables>;
export const ScratchcardOrdersDocument = gql`
    query ScratchcardOrders($filter: ScratchcardsFilterInput!, $limit: Int!, $offset: Int!) {
  scratchcardOrders(filter: $filter, limit: $limit, offset: $offset) {
    count
    scratchcardOrders: orders {
      gameName
      gameSlug
      orderId
      quantity
      quantityActive
      quantityPast
      state
      titleColor
      winnings {
        currency
        value
      }
    }
  }
}
    `;

/**
 * __useScratchcardOrdersQuery__
 *
 * To run a query within a Vue component, call `useScratchcardOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useScratchcardOrdersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useScratchcardOrdersQuery({
 *   filter: // value for 'filter'
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 * });
 */
export function useScratchcardOrdersQuery(variables: Types.ScratchcardOrdersQueryVariables | VueCompositionApi.Ref<Types.ScratchcardOrdersQueryVariables> | ReactiveFunction<Types.ScratchcardOrdersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScratchcardOrdersQuery, Types.ScratchcardOrdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScratchcardOrdersQuery, Types.ScratchcardOrdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScratchcardOrdersQuery, Types.ScratchcardOrdersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.ScratchcardOrdersQuery, Types.ScratchcardOrdersQueryVariables>(ScratchcardOrdersDocument, variables, options);
}
export function useScratchcardOrdersLazyQuery(variables?: Types.ScratchcardOrdersQueryVariables | VueCompositionApi.Ref<Types.ScratchcardOrdersQueryVariables> | ReactiveFunction<Types.ScratchcardOrdersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScratchcardOrdersQuery, Types.ScratchcardOrdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScratchcardOrdersQuery, Types.ScratchcardOrdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScratchcardOrdersQuery, Types.ScratchcardOrdersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.ScratchcardOrdersQuery, Types.ScratchcardOrdersQueryVariables>(ScratchcardOrdersDocument, variables, options);
}
export type ScratchcardOrdersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.ScratchcardOrdersQuery, Types.ScratchcardOrdersQueryVariables>;
export const SingleOrderMultipleLinesDocument = gql`
    query SingleOrderMultipleLines($orderId: String!, $limitPlays: Int, $offsetPlays: Int, $groupOfPlays: DrawnNumbersGroup) {
  singleOrderMultipleLines(orderId: $orderId) {
    id
    lottery {
      active
      currentConfig {
        addOns {
          name
          perPlay
        }
        afterPurchaseAddOns {
          name
          perPlay
        }
        numbers {
          name
          selectableMax
        }
        type
      }
      id
      licensedTerritory
    }
    nextDrawDate
    orderedIn
    orderId
    price {
      ...money
    }
    results {
      drawDate
      hasWinnings
      hasMultipleDrawsOnDay
      label
      lotteryResults {
        countOfPlays
        drawnNumbers {
          numbers {
            modifies
            name
            values
          }
        }
        group
        plays: plays(
          limit: $limitPlays
          offset: $offsetPlays
          groupOfPlays: $groupOfPlays
        ) {
          addOns {
            name
            value
          }
          addOnValues {
            name
            value
          }
          winnings {
            amount {
              ...money
            }
            numberOfFreePlays
          }
          fields {
            name
            numbers {
              matching
              value
            }
            selected
          }
          serialNumber
          state
        }
      }
      ticketScans {
        serialNumber
        scans {
          imageProperties {
            ...imageProperties
          }
          url
        }
      }
      ticketScanUrlsExpirationDate
      totalNumberOfFreePlays
      totalWinnings {
        ...money
      }
    }
    state
    type
  }
}
    ${MoneyFragmentDoc}
${ImagePropertiesFragmentDoc}`;

/**
 * __useSingleOrderMultipleLinesQuery__
 *
 * To run a query within a Vue component, call `useSingleOrderMultipleLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleOrderMultipleLinesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSingleOrderMultipleLinesQuery({
 *   orderId: // value for 'orderId'
 *   limitPlays: // value for 'limitPlays'
 *   offsetPlays: // value for 'offsetPlays'
 *   groupOfPlays: // value for 'groupOfPlays'
 * });
 */
export function useSingleOrderMultipleLinesQuery(variables: Types.SingleOrderMultipleLinesQueryVariables | VueCompositionApi.Ref<Types.SingleOrderMultipleLinesQueryVariables> | ReactiveFunction<Types.SingleOrderMultipleLinesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.SingleOrderMultipleLinesQuery, Types.SingleOrderMultipleLinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.SingleOrderMultipleLinesQuery, Types.SingleOrderMultipleLinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.SingleOrderMultipleLinesQuery, Types.SingleOrderMultipleLinesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.SingleOrderMultipleLinesQuery, Types.SingleOrderMultipleLinesQueryVariables>(SingleOrderMultipleLinesDocument, variables, options);
}
export function useSingleOrderMultipleLinesLazyQuery(variables?: Types.SingleOrderMultipleLinesQueryVariables | VueCompositionApi.Ref<Types.SingleOrderMultipleLinesQueryVariables> | ReactiveFunction<Types.SingleOrderMultipleLinesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.SingleOrderMultipleLinesQuery, Types.SingleOrderMultipleLinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.SingleOrderMultipleLinesQuery, Types.SingleOrderMultipleLinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.SingleOrderMultipleLinesQuery, Types.SingleOrderMultipleLinesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.SingleOrderMultipleLinesQuery, Types.SingleOrderMultipleLinesQueryVariables>(SingleOrderMultipleLinesDocument, variables, options);
}
export type SingleOrderMultipleLinesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.SingleOrderMultipleLinesQuery, Types.SingleOrderMultipleLinesQueryVariables>;
export const SubscriptionOrderMultipleLinesDocument = gql`
    query SubscriptionOrderMultipleLines($orderId: String!, $limitPlays: Int, $offsetPlays: Int, $limitDrawResults: Int, $offsetDrawResults: Int, $drawDateOfPlays: DateTime, $groupOfPlays: DrawnNumbersGroup) {
  subscriptionOrderMultipleLines(orderId: $orderId) {
    id
    lastDrawDate
    latestPlaySlipOrderId
    lottery {
      active
      currentConfig {
        addOns {
          name
          perPlay
        }
        afterPurchaseAddOns {
          name
          perPlay
        }
        numbers {
          name
          selectableMax
        }
        type
      }
      id
      licensedTerritory
    }
    nextDrawDate
    orderedIn
    price {
      ...money
    }
    results: results(limit: $limitDrawResults, offset: $offsetDrawResults) {
      drawDate
      hasWinnings
      hasMultipleDrawsOnDay
      label
      lotteryResults {
        drawnNumbers {
          numbers {
            modifies
            name
            values
          }
        }
        countOfPlays
        group
        plays: plays(
          limit: $limitPlays
          offset: $offsetPlays
          drawDateOfPlays: $drawDateOfPlays
          groupOfPlays: $groupOfPlays
        ) {
          addOns {
            name
            value
          }
          addOnValues {
            name
            value
          }
          winnings {
            amount {
              ...money
            }
            numberOfFreePlays
          }
          fields {
            name
            numbers {
              matching
              value
            }
          }
          serialNumber
          state
        }
      }
      ticketScanUrlsExpirationDate
      ticketScans {
        serialNumber
        scans {
          imageProperties {
            ...imageProperties
          }
          url
        }
      }
      totalNumberOfFreePlays
      totalWinnings {
        ...money
      }
    }
    status
    subscriptionCode
    totalDrawResults
    type
    prepaidOptions {
      participatedDraws
      remainingDraws
      totalDraws
    }
  }
}
    ${MoneyFragmentDoc}
${ImagePropertiesFragmentDoc}`;

/**
 * __useSubscriptionOrderMultipleLinesQuery__
 *
 * To run a query within a Vue component, call `useSubscriptionOrderMultipleLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionOrderMultipleLinesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSubscriptionOrderMultipleLinesQuery({
 *   orderId: // value for 'orderId'
 *   limitPlays: // value for 'limitPlays'
 *   offsetPlays: // value for 'offsetPlays'
 *   limitDrawResults: // value for 'limitDrawResults'
 *   offsetDrawResults: // value for 'offsetDrawResults'
 *   drawDateOfPlays: // value for 'drawDateOfPlays'
 *   groupOfPlays: // value for 'groupOfPlays'
 * });
 */
export function useSubscriptionOrderMultipleLinesQuery(variables: Types.SubscriptionOrderMultipleLinesQueryVariables | VueCompositionApi.Ref<Types.SubscriptionOrderMultipleLinesQueryVariables> | ReactiveFunction<Types.SubscriptionOrderMultipleLinesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.SubscriptionOrderMultipleLinesQuery, Types.SubscriptionOrderMultipleLinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.SubscriptionOrderMultipleLinesQuery, Types.SubscriptionOrderMultipleLinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.SubscriptionOrderMultipleLinesQuery, Types.SubscriptionOrderMultipleLinesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.SubscriptionOrderMultipleLinesQuery, Types.SubscriptionOrderMultipleLinesQueryVariables>(SubscriptionOrderMultipleLinesDocument, variables, options);
}
export function useSubscriptionOrderMultipleLinesLazyQuery(variables?: Types.SubscriptionOrderMultipleLinesQueryVariables | VueCompositionApi.Ref<Types.SubscriptionOrderMultipleLinesQueryVariables> | ReactiveFunction<Types.SubscriptionOrderMultipleLinesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.SubscriptionOrderMultipleLinesQuery, Types.SubscriptionOrderMultipleLinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.SubscriptionOrderMultipleLinesQuery, Types.SubscriptionOrderMultipleLinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.SubscriptionOrderMultipleLinesQuery, Types.SubscriptionOrderMultipleLinesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.SubscriptionOrderMultipleLinesQuery, Types.SubscriptionOrderMultipleLinesQueryVariables>(SubscriptionOrderMultipleLinesDocument, variables, options);
}
export type SubscriptionOrderMultipleLinesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.SubscriptionOrderMultipleLinesQuery, Types.SubscriptionOrderMultipleLinesQueryVariables>;
export const SubscriptionsMultipleLinesDocument = gql`
    query SubscriptionsMultipleLines($filter: SubscriptionsFilterInput!, $limit: Int!, $offset: Int!) {
  subscriptionsMultipleLines(filter: $filter, offset: $offset, limit: $limit) {
    count
    subscriptions {
      code
      id
      lastDrawDate
      latestOrderId
      licensedTerritory
      latestPlaySlipOrderId
      lotteryId
      nextDrawDate
      numberOfPlays
      selectedAddOns
      status
      prepaidOptions {
        participatedDraws
        remainingDraws
        totalDraws
      }
    }
  }
}
    `;

/**
 * __useSubscriptionsMultipleLinesQuery__
 *
 * To run a query within a Vue component, call `useSubscriptionsMultipleLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsMultipleLinesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSubscriptionsMultipleLinesQuery({
 *   filter: // value for 'filter'
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 * });
 */
export function useSubscriptionsMultipleLinesQuery(variables: Types.SubscriptionsMultipleLinesQueryVariables | VueCompositionApi.Ref<Types.SubscriptionsMultipleLinesQueryVariables> | ReactiveFunction<Types.SubscriptionsMultipleLinesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.SubscriptionsMultipleLinesQuery, Types.SubscriptionsMultipleLinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.SubscriptionsMultipleLinesQuery, Types.SubscriptionsMultipleLinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.SubscriptionsMultipleLinesQuery, Types.SubscriptionsMultipleLinesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.SubscriptionsMultipleLinesQuery, Types.SubscriptionsMultipleLinesQueryVariables>(SubscriptionsMultipleLinesDocument, variables, options);
}
export function useSubscriptionsMultipleLinesLazyQuery(variables?: Types.SubscriptionsMultipleLinesQueryVariables | VueCompositionApi.Ref<Types.SubscriptionsMultipleLinesQueryVariables> | ReactiveFunction<Types.SubscriptionsMultipleLinesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.SubscriptionsMultipleLinesQuery, Types.SubscriptionsMultipleLinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.SubscriptionsMultipleLinesQuery, Types.SubscriptionsMultipleLinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.SubscriptionsMultipleLinesQuery, Types.SubscriptionsMultipleLinesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.SubscriptionsMultipleLinesQuery, Types.SubscriptionsMultipleLinesQueryVariables>(SubscriptionsMultipleLinesDocument, variables, options);
}
export type SubscriptionsMultipleLinesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.SubscriptionsMultipleLinesQuery, Types.SubscriptionsMultipleLinesQueryVariables>;
export const ActiveAnnouncementDocument = gql`
    query ActiveAnnouncement($licensedTerritory: String!) {
  activeAnnouncement(licensedTerritory: $licensedTerritory) {
    id
    title
    description
    changeDate
    active
  }
}
    `;

/**
 * __useActiveAnnouncementQuery__
 *
 * To run a query within a Vue component, call `useActiveAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAnnouncementQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useActiveAnnouncementQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 * });
 */
export function useActiveAnnouncementQuery(variables: Types.ActiveAnnouncementQueryVariables | VueCompositionApi.Ref<Types.ActiveAnnouncementQueryVariables> | ReactiveFunction<Types.ActiveAnnouncementQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ActiveAnnouncementQuery, Types.ActiveAnnouncementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ActiveAnnouncementQuery, Types.ActiveAnnouncementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ActiveAnnouncementQuery, Types.ActiveAnnouncementQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.ActiveAnnouncementQuery, Types.ActiveAnnouncementQueryVariables>(ActiveAnnouncementDocument, variables, options);
}
export function useActiveAnnouncementLazyQuery(variables?: Types.ActiveAnnouncementQueryVariables | VueCompositionApi.Ref<Types.ActiveAnnouncementQueryVariables> | ReactiveFunction<Types.ActiveAnnouncementQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ActiveAnnouncementQuery, Types.ActiveAnnouncementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ActiveAnnouncementQuery, Types.ActiveAnnouncementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ActiveAnnouncementQuery, Types.ActiveAnnouncementQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.ActiveAnnouncementQuery, Types.ActiveAnnouncementQueryVariables>(ActiveAnnouncementDocument, variables, options);
}
export type ActiveAnnouncementQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.ActiveAnnouncementQuery, Types.ActiveAnnouncementQueryVariables>;
export const MarketingPreferencesDocument = gql`
    query marketingPreferences {
  marketingPreferences {
    channels {
      channel
      activated
      verified
    }
  }
}
    `;

/**
 * __useMarketingPreferencesQuery__
 *
 * To run a query within a Vue component, call `useMarketingPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingPreferencesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMarketingPreferencesQuery();
 */
export function useMarketingPreferencesQuery(options: VueApolloComposable.UseQueryOptions<Types.MarketingPreferencesQuery, Types.MarketingPreferencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.MarketingPreferencesQuery, Types.MarketingPreferencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.MarketingPreferencesQuery, Types.MarketingPreferencesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.MarketingPreferencesQuery, Types.MarketingPreferencesQueryVariables>(MarketingPreferencesDocument, {}, options);
}
export function useMarketingPreferencesLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.MarketingPreferencesQuery, Types.MarketingPreferencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.MarketingPreferencesQuery, Types.MarketingPreferencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.MarketingPreferencesQuery, Types.MarketingPreferencesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.MarketingPreferencesQuery, Types.MarketingPreferencesQueryVariables>(MarketingPreferencesDocument, {}, options);
}
export type MarketingPreferencesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.MarketingPreferencesQuery, Types.MarketingPreferencesQueryVariables>;
export const SetMarketingChannelPreferencesDocument = gql`
    mutation setMarketingChannelPreferences($input: MarketingChannelPreferenceMutationInput!) {
  setMarketingChannelPreferences(input: $input) {
    activated
    channel
  }
}
    `;

/**
 * __useSetMarketingChannelPreferencesMutation__
 *
 * To run a mutation, you first call `useSetMarketingChannelPreferencesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetMarketingChannelPreferencesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetMarketingChannelPreferencesMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSetMarketingChannelPreferencesMutation(options: VueApolloComposable.UseMutationOptions<Types.SetMarketingChannelPreferencesMutation, Types.SetMarketingChannelPreferencesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.SetMarketingChannelPreferencesMutation, Types.SetMarketingChannelPreferencesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.SetMarketingChannelPreferencesMutation, Types.SetMarketingChannelPreferencesMutationVariables>(SetMarketingChannelPreferencesDocument, options);
}
export type SetMarketingChannelPreferencesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.SetMarketingChannelPreferencesMutation, Types.SetMarketingChannelPreferencesMutationVariables>;
export const AddFundsConfigDocument = gql`
    query AddFundsConfig($licensedTerritory: String!, $input: AddFundsInput) {
  addFundsConfig(licensedTerritory: $licensedTerritory, input: $input) {
    fees {
      dynamicFee
      staticFee {
        ...money
      }
    }
    limits {
      maxPayInAmount {
        ...money
      }
      minPayInAmount {
        ...money
      }
    }
    payment {
      initial {
        accountId
        integrationType
        methods
        provider
        providerUri
        token {
          expires
          value
        }
        type
        venmoProfileId
      }
      paymentId
      stored {
        accountId
        cardNumber
        expiryMonth
        expiryYear
        holderName
        integrationType
        lastUsed
        method
        provider
        requiresCvvConfirmation
        token {
          expires
          value
        }
        type
      }
    }
    pricePoints {
      amount {
        ...money
      }
      fees {
        ...money
      }
      isMostPopular
      totalAmount {
        ...money
      }
      withinFundingLimit
    }
    productId
    withCustomAmount
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useAddFundsConfigQuery__
 *
 * To run a query within a Vue component, call `useAddFundsConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddFundsConfigQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAddFundsConfigQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 *   input: // value for 'input'
 * });
 */
export function useAddFundsConfigQuery(variables: Types.AddFundsConfigQueryVariables | VueCompositionApi.Ref<Types.AddFundsConfigQueryVariables> | ReactiveFunction<Types.AddFundsConfigQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.AddFundsConfigQuery, Types.AddFundsConfigQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.AddFundsConfigQuery, Types.AddFundsConfigQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.AddFundsConfigQuery, Types.AddFundsConfigQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.AddFundsConfigQuery, Types.AddFundsConfigQueryVariables>(AddFundsConfigDocument, variables, options);
}
export function useAddFundsConfigLazyQuery(variables?: Types.AddFundsConfigQueryVariables | VueCompositionApi.Ref<Types.AddFundsConfigQueryVariables> | ReactiveFunction<Types.AddFundsConfigQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.AddFundsConfigQuery, Types.AddFundsConfigQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.AddFundsConfigQuery, Types.AddFundsConfigQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.AddFundsConfigQuery, Types.AddFundsConfigQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.AddFundsConfigQuery, Types.AddFundsConfigQueryVariables>(AddFundsConfigDocument, variables, options);
}
export type AddFundsConfigQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.AddFundsConfigQuery, Types.AddFundsConfigQueryVariables>;
export const AddFundsStatusDocument = gql`
    query AddFundsStatus($id: String!) {
  addFundsStatus(id: $id) {
    id
    status
  }
}
    `;

/**
 * __useAddFundsStatusQuery__
 *
 * To run a query within a Vue component, call `useAddFundsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddFundsStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAddFundsStatusQuery({
 *   id: // value for 'id'
 * });
 */
export function useAddFundsStatusQuery(variables: Types.AddFundsStatusQueryVariables | VueCompositionApi.Ref<Types.AddFundsStatusQueryVariables> | ReactiveFunction<Types.AddFundsStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.AddFundsStatusQuery, Types.AddFundsStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.AddFundsStatusQuery, Types.AddFundsStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.AddFundsStatusQuery, Types.AddFundsStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.AddFundsStatusQuery, Types.AddFundsStatusQueryVariables>(AddFundsStatusDocument, variables, options);
}
export function useAddFundsStatusLazyQuery(variables?: Types.AddFundsStatusQueryVariables | VueCompositionApi.Ref<Types.AddFundsStatusQueryVariables> | ReactiveFunction<Types.AddFundsStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.AddFundsStatusQuery, Types.AddFundsStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.AddFundsStatusQuery, Types.AddFundsStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.AddFundsStatusQuery, Types.AddFundsStatusQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.AddFundsStatusQuery, Types.AddFundsStatusQueryVariables>(AddFundsStatusDocument, variables, options);
}
export type AddFundsStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.AddFundsStatusQuery, Types.AddFundsStatusQueryVariables>;
export const AddFundsDocument = gql`
    mutation AddFunds($input: AddFundsMutationInput!) {
  addFunds(input: $input) {
    id
    isFirstPurchase
    status
  }
}
    `;

/**
 * __useAddFundsMutation__
 *
 * To run a mutation, you first call `useAddFundsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddFundsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddFundsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAddFundsMutation(options: VueApolloComposable.UseMutationOptions<Types.AddFundsMutation, Types.AddFundsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.AddFundsMutation, Types.AddFundsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.AddFundsMutation, Types.AddFundsMutationVariables>(AddFundsDocument, options);
}
export type AddFundsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.AddFundsMutation, Types.AddFundsMutationVariables>;
export const ApplePayMerchantValidationDocument = gql`
    query ApplePayMerchantValidation($input: ApplePayMerchantValidationInput!) {
  applePayMerchantValidation(input: $input) {
    encodedPaymentSession
  }
}
    `;

/**
 * __useApplePayMerchantValidationQuery__
 *
 * To run a query within a Vue component, call `useApplePayMerchantValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplePayMerchantValidationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useApplePayMerchantValidationQuery({
 *   input: // value for 'input'
 * });
 */
export function useApplePayMerchantValidationQuery(variables: Types.ApplePayMerchantValidationQueryVariables | VueCompositionApi.Ref<Types.ApplePayMerchantValidationQueryVariables> | ReactiveFunction<Types.ApplePayMerchantValidationQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ApplePayMerchantValidationQuery, Types.ApplePayMerchantValidationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ApplePayMerchantValidationQuery, Types.ApplePayMerchantValidationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ApplePayMerchantValidationQuery, Types.ApplePayMerchantValidationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.ApplePayMerchantValidationQuery, Types.ApplePayMerchantValidationQueryVariables>(ApplePayMerchantValidationDocument, variables, options);
}
export function useApplePayMerchantValidationLazyQuery(variables?: Types.ApplePayMerchantValidationQueryVariables | VueCompositionApi.Ref<Types.ApplePayMerchantValidationQueryVariables> | ReactiveFunction<Types.ApplePayMerchantValidationQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ApplePayMerchantValidationQuery, Types.ApplePayMerchantValidationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ApplePayMerchantValidationQuery, Types.ApplePayMerchantValidationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ApplePayMerchantValidationQuery, Types.ApplePayMerchantValidationQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.ApplePayMerchantValidationQuery, Types.ApplePayMerchantValidationQueryVariables>(ApplePayMerchantValidationDocument, variables, options);
}
export type ApplePayMerchantValidationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.ApplePayMerchantValidationQuery, Types.ApplePayMerchantValidationQueryVariables>;
export const PaymentMethodsDocument = gql`
    query PaymentMethods($licensedTerritory: String!) {
  paymentMethods(licensedTerritory: $licensedTerritory) {
    allowsSubscriptionPurchase
    name
    type
  }
}
    `;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a Vue component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePaymentMethodsQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 * });
 */
export function usePaymentMethodsQuery(variables: Types.PaymentMethodsQueryVariables | VueCompositionApi.Ref<Types.PaymentMethodsQueryVariables> | ReactiveFunction<Types.PaymentMethodsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.PaymentMethodsQuery, Types.PaymentMethodsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.PaymentMethodsQuery, Types.PaymentMethodsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.PaymentMethodsQuery, Types.PaymentMethodsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.PaymentMethodsQuery, Types.PaymentMethodsQueryVariables>(PaymentMethodsDocument, variables, options);
}
export function usePaymentMethodsLazyQuery(variables?: Types.PaymentMethodsQueryVariables | VueCompositionApi.Ref<Types.PaymentMethodsQueryVariables> | ReactiveFunction<Types.PaymentMethodsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.PaymentMethodsQuery, Types.PaymentMethodsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.PaymentMethodsQuery, Types.PaymentMethodsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.PaymentMethodsQuery, Types.PaymentMethodsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.PaymentMethodsQuery, Types.PaymentMethodsQueryVariables>(PaymentMethodsDocument, variables, options);
}
export type PaymentMethodsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.PaymentMethodsQuery, Types.PaymentMethodsQueryVariables>;
export const CheckRoutingNumberDocument = gql`
    query checkRoutingNumber($routingNumber: String!) {
  checkRoutingNumber(routingNumber: $routingNumber) {
    bankName
    isKnown
  }
}
    `;

/**
 * __useCheckRoutingNumberQuery__
 *
 * To run a query within a Vue component, call `useCheckRoutingNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRoutingNumberQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCheckRoutingNumberQuery({
 *   routingNumber: // value for 'routingNumber'
 * });
 */
export function useCheckRoutingNumberQuery(variables: Types.CheckRoutingNumberQueryVariables | VueCompositionApi.Ref<Types.CheckRoutingNumberQueryVariables> | ReactiveFunction<Types.CheckRoutingNumberQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.CheckRoutingNumberQuery, Types.CheckRoutingNumberQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckRoutingNumberQuery, Types.CheckRoutingNumberQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckRoutingNumberQuery, Types.CheckRoutingNumberQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.CheckRoutingNumberQuery, Types.CheckRoutingNumberQueryVariables>(CheckRoutingNumberDocument, variables, options);
}
export function useCheckRoutingNumberLazyQuery(variables?: Types.CheckRoutingNumberQueryVariables | VueCompositionApi.Ref<Types.CheckRoutingNumberQueryVariables> | ReactiveFunction<Types.CheckRoutingNumberQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.CheckRoutingNumberQuery, Types.CheckRoutingNumberQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckRoutingNumberQuery, Types.CheckRoutingNumberQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckRoutingNumberQuery, Types.CheckRoutingNumberQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.CheckRoutingNumberQuery, Types.CheckRoutingNumberQueryVariables>(CheckRoutingNumberDocument, variables, options);
}
export type CheckRoutingNumberQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.CheckRoutingNumberQuery, Types.CheckRoutingNumberQueryVariables>;
export const PayOutAccountsDocument = gql`
    query payOutAccounts($licensedTerritory: String!) {
  payOutAccounts(licensedTerritory: $licensedTerritory) {
    accounts {
      holderName
      id
      type
      ... on PayOutAccountBank {
        routingNumber
        accountNumber
      }
      ... on PayOutAccountPayPal {
        payPalPayerEmail
      }
    }
    minPayableBalance {
      ...money
    }
    maxPayableBalance {
      ...money
    }
    holderName
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __usePayOutAccountsQuery__
 *
 * To run a query within a Vue component, call `usePayOutAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayOutAccountsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePayOutAccountsQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 * });
 */
export function usePayOutAccountsQuery(variables: Types.PayOutAccountsQueryVariables | VueCompositionApi.Ref<Types.PayOutAccountsQueryVariables> | ReactiveFunction<Types.PayOutAccountsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.PayOutAccountsQuery, Types.PayOutAccountsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.PayOutAccountsQuery, Types.PayOutAccountsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.PayOutAccountsQuery, Types.PayOutAccountsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.PayOutAccountsQuery, Types.PayOutAccountsQueryVariables>(PayOutAccountsDocument, variables, options);
}
export function usePayOutAccountsLazyQuery(variables?: Types.PayOutAccountsQueryVariables | VueCompositionApi.Ref<Types.PayOutAccountsQueryVariables> | ReactiveFunction<Types.PayOutAccountsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.PayOutAccountsQuery, Types.PayOutAccountsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.PayOutAccountsQuery, Types.PayOutAccountsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.PayOutAccountsQuery, Types.PayOutAccountsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.PayOutAccountsQuery, Types.PayOutAccountsQueryVariables>(PayOutAccountsDocument, variables, options);
}
export type PayOutAccountsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.PayOutAccountsQuery, Types.PayOutAccountsQueryVariables>;
export const PayOutToBankAccountDocument = gql`
    mutation payOutToBankAccount($input: BankPayOutInput!) {
  payOutToBankAccount(input: $input) {
    amount {
      ...money
    }
    id
    state
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __usePayOutToBankAccountMutation__
 *
 * To run a mutation, you first call `usePayOutToBankAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `usePayOutToBankAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = usePayOutToBankAccountMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function usePayOutToBankAccountMutation(options: VueApolloComposable.UseMutationOptions<Types.PayOutToBankAccountMutation, Types.PayOutToBankAccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.PayOutToBankAccountMutation, Types.PayOutToBankAccountMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.PayOutToBankAccountMutation, Types.PayOutToBankAccountMutationVariables>(PayOutToBankAccountDocument, options);
}
export type PayOutToBankAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.PayOutToBankAccountMutation, Types.PayOutToBankAccountMutationVariables>;
export const PayOutToSavedAccountDocument = gql`
    mutation payOutToSavedAccount($input: SavedPayOutInput!) {
  payOutToSavedAccount(input: $input) {
    amount {
      ...money
    }
    id
    state
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __usePayOutToSavedAccountMutation__
 *
 * To run a mutation, you first call `usePayOutToSavedAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `usePayOutToSavedAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = usePayOutToSavedAccountMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function usePayOutToSavedAccountMutation(options: VueApolloComposable.UseMutationOptions<Types.PayOutToSavedAccountMutation, Types.PayOutToSavedAccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.PayOutToSavedAccountMutation, Types.PayOutToSavedAccountMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.PayOutToSavedAccountMutation, Types.PayOutToSavedAccountMutationVariables>(PayOutToSavedAccountDocument, options);
}
export type PayOutToSavedAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.PayOutToSavedAccountMutation, Types.PayOutToSavedAccountMutationVariables>;
export const StorePlaySlipDocument = gql`
    mutation StorePlaySlip($input: PlaySlipInput!) {
  storePlaySlip(input: $input) {
    id
  }
}
    `;

/**
 * __useStorePlaySlipMutation__
 *
 * To run a mutation, you first call `useStorePlaySlipMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useStorePlaySlipMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useStorePlaySlipMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useStorePlaySlipMutation(options: VueApolloComposable.UseMutationOptions<Types.StorePlaySlipMutation, Types.StorePlaySlipMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.StorePlaySlipMutation, Types.StorePlaySlipMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.StorePlaySlipMutation, Types.StorePlaySlipMutationVariables>(StorePlaySlipDocument, options);
}
export type StorePlaySlipMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.StorePlaySlipMutation, Types.StorePlaySlipMutationVariables>;
export const ValidatePlaySlipDocument = gql`
    query ValidatePlaySlip($input: PlaySlipInput!) {
  validatePlaySlip(input: $input) {
    success
  }
}
    `;

/**
 * __useValidatePlaySlipQuery__
 *
 * To run a query within a Vue component, call `useValidatePlaySlipQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePlaySlipQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useValidatePlaySlipQuery({
 *   input: // value for 'input'
 * });
 */
export function useValidatePlaySlipQuery(variables: Types.ValidatePlaySlipQueryVariables | VueCompositionApi.Ref<Types.ValidatePlaySlipQueryVariables> | ReactiveFunction<Types.ValidatePlaySlipQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ValidatePlaySlipQuery, Types.ValidatePlaySlipQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ValidatePlaySlipQuery, Types.ValidatePlaySlipQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ValidatePlaySlipQuery, Types.ValidatePlaySlipQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.ValidatePlaySlipQuery, Types.ValidatePlaySlipQueryVariables>(ValidatePlaySlipDocument, variables, options);
}
export function useValidatePlaySlipLazyQuery(variables?: Types.ValidatePlaySlipQueryVariables | VueCompositionApi.Ref<Types.ValidatePlaySlipQueryVariables> | ReactiveFunction<Types.ValidatePlaySlipQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ValidatePlaySlipQuery, Types.ValidatePlaySlipQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ValidatePlaySlipQuery, Types.ValidatePlaySlipQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ValidatePlaySlipQuery, Types.ValidatePlaySlipQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.ValidatePlaySlipQuery, Types.ValidatePlaySlipQueryVariables>(ValidatePlaySlipDocument, variables, options);
}
export type ValidatePlaySlipQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.ValidatePlaySlipQuery, Types.ValidatePlaySlipQueryVariables>;
export const CheckActivePendingDocument = gql`
    query CheckActivePending {
  playSlipHistory {
    active(limit: 1, offset: 0) {
      id
    }
    pending(limit: 1, offset: 0) {
      id
    }
  }
}
    `;

/**
 * __useCheckActivePendingQuery__
 *
 * To run a query within a Vue component, call `useCheckActivePendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckActivePendingQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCheckActivePendingQuery();
 */
export function useCheckActivePendingQuery(options: VueApolloComposable.UseQueryOptions<Types.CheckActivePendingQuery, Types.CheckActivePendingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckActivePendingQuery, Types.CheckActivePendingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckActivePendingQuery, Types.CheckActivePendingQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.CheckActivePendingQuery, Types.CheckActivePendingQueryVariables>(CheckActivePendingDocument, {}, options);
}
export function useCheckActivePendingLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.CheckActivePendingQuery, Types.CheckActivePendingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckActivePendingQuery, Types.CheckActivePendingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckActivePendingQuery, Types.CheckActivePendingQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.CheckActivePendingQuery, Types.CheckActivePendingQueryVariables>(CheckActivePendingDocument, {}, options);
}
export type CheckActivePendingQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.CheckActivePendingQuery, Types.CheckActivePendingQueryVariables>;
export const PlayerSignupFinishedDocument = gql`
    query PlayerSignupFinished {
  playerSignupFinished {
    exists
  }
}
    `;

/**
 * __usePlayerSignupFinishedQuery__
 *
 * To run a query within a Vue component, call `usePlayerSignupFinishedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerSignupFinishedQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePlayerSignupFinishedQuery();
 */
export function usePlayerSignupFinishedQuery(options: VueApolloComposable.UseQueryOptions<Types.PlayerSignupFinishedQuery, Types.PlayerSignupFinishedQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.PlayerSignupFinishedQuery, Types.PlayerSignupFinishedQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.PlayerSignupFinishedQuery, Types.PlayerSignupFinishedQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.PlayerSignupFinishedQuery, Types.PlayerSignupFinishedQueryVariables>(PlayerSignupFinishedDocument, {}, options);
}
export function usePlayerSignupFinishedLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.PlayerSignupFinishedQuery, Types.PlayerSignupFinishedQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.PlayerSignupFinishedQuery, Types.PlayerSignupFinishedQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.PlayerSignupFinishedQuery, Types.PlayerSignupFinishedQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.PlayerSignupFinishedQuery, Types.PlayerSignupFinishedQueryVariables>(PlayerSignupFinishedDocument, {}, options);
}
export type PlayerSignupFinishedQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.PlayerSignupFinishedQuery, Types.PlayerSignupFinishedQueryVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($input: VerifyEmailInput!) {
  verifyEmail(input: $input) {
    success
  }
}
    `;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useVerifyEmailMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(options: VueApolloComposable.UseMutationOptions<Types.VerifyEmailMutation, Types.VerifyEmailMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.VerifyEmailMutation, Types.VerifyEmailMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.VerifyEmailMutation, Types.VerifyEmailMutationVariables>(VerifyEmailDocument, options);
}
export type VerifyEmailMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.VerifyEmailMutation, Types.VerifyEmailMutationVariables>;
export const DrawResultsDocument = gql`
    query drawResults($filter: DrawResultsFilterInput, $licensedTerritory: String!, $limit: Int!, $offset: Int!) {
  drawListByDay(
    licensedTerritory: $licensedTerritory
    limit: $limit
    offset: $offset
    filter: $filter
  ) {
    count
    days {
      date
      draws {
        drawDate
        drawnNumbers {
          name
          numbers {
            modifies
            name
            values
          }
        }
        hasMultipleDrawsOnDay
        id
        label
        lotteryConfig {
          licensedTerritory
          currentConfig {
            addOns {
              name
              perPlay
            }
          }
        }
        lotteryId
      }
    }
    timeZone
  }
}
    `;

/**
 * __useDrawResultsQuery__
 *
 * To run a query within a Vue component, call `useDrawResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawResultsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDrawResultsQuery({
 *   filter: // value for 'filter'
 *   licensedTerritory: // value for 'licensedTerritory'
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 * });
 */
export function useDrawResultsQuery(variables: Types.DrawResultsQueryVariables | VueCompositionApi.Ref<Types.DrawResultsQueryVariables> | ReactiveFunction<Types.DrawResultsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.DrawResultsQuery, Types.DrawResultsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.DrawResultsQuery, Types.DrawResultsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.DrawResultsQuery, Types.DrawResultsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.DrawResultsQuery, Types.DrawResultsQueryVariables>(DrawResultsDocument, variables, options);
}
export function useDrawResultsLazyQuery(variables?: Types.DrawResultsQueryVariables | VueCompositionApi.Ref<Types.DrawResultsQueryVariables> | ReactiveFunction<Types.DrawResultsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.DrawResultsQuery, Types.DrawResultsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.DrawResultsQuery, Types.DrawResultsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.DrawResultsQuery, Types.DrawResultsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.DrawResultsQuery, Types.DrawResultsQueryVariables>(DrawResultsDocument, variables, options);
}
export type DrawResultsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.DrawResultsQuery, Types.DrawResultsQueryVariables>;
export const ResultDetailsDocument = gql`
    query ResultDetails($id: String!) {
  drawDetail(id: $id) {
    addOns
    drawDate
    drawnNumbers {
      group
      numbers {
        modifies
        name
        values
      }
    }
    hasMultipleDrawsOnDay
    label
    lotteryConfig {
      currentConfig {
        addOns {
          ...addOn
        }
        afterPurchaseAddOns {
          ...addOn
        }
        combinedTierTable
        hasNumberOfWinners
        prizeTierCategories {
          ...prizeTierCategory
        }
        type
      }
      timeZone
    }
    lotteryId
    prizeCategories {
      group
      name
      prizes {
        prize {
          ...money
        }
        tier
        type
        winners
      }
    }
  }
}
    ${AddOnFragmentDoc}
${PrizeTierCategoryFragmentDoc}
${MoneyFragmentDoc}`;

/**
 * __useResultDetailsQuery__
 *
 * To run a query within a Vue component, call `useResultDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResultDetailsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useResultDetailsQuery({
 *   id: // value for 'id'
 * });
 */
export function useResultDetailsQuery(variables: Types.ResultDetailsQueryVariables | VueCompositionApi.Ref<Types.ResultDetailsQueryVariables> | ReactiveFunction<Types.ResultDetailsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ResultDetailsQuery, Types.ResultDetailsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ResultDetailsQuery, Types.ResultDetailsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ResultDetailsQuery, Types.ResultDetailsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.ResultDetailsQuery, Types.ResultDetailsQueryVariables>(ResultDetailsDocument, variables, options);
}
export function useResultDetailsLazyQuery(variables?: Types.ResultDetailsQueryVariables | VueCompositionApi.Ref<Types.ResultDetailsQueryVariables> | ReactiveFunction<Types.ResultDetailsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ResultDetailsQuery, Types.ResultDetailsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ResultDetailsQuery, Types.ResultDetailsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ResultDetailsQuery, Types.ResultDetailsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.ResultDetailsQuery, Types.ResultDetailsQueryVariables>(ResultDetailsDocument, variables, options);
}
export type ResultDetailsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.ResultDetailsQuery, Types.ResultDetailsQueryVariables>;
export const CheckScratchcardOrderDocument = gql`
    query CheckScratchcardOrder($orderId: String!) {
  scratchcardOrder(orderId: $orderId) {
    orderId
  }
}
    `;

/**
 * __useCheckScratchcardOrderQuery__
 *
 * To run a query within a Vue component, call `useCheckScratchcardOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckScratchcardOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCheckScratchcardOrderQuery({
 *   orderId: // value for 'orderId'
 * });
 */
export function useCheckScratchcardOrderQuery(variables: Types.CheckScratchcardOrderQueryVariables | VueCompositionApi.Ref<Types.CheckScratchcardOrderQueryVariables> | ReactiveFunction<Types.CheckScratchcardOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.CheckScratchcardOrderQuery, Types.CheckScratchcardOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckScratchcardOrderQuery, Types.CheckScratchcardOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckScratchcardOrderQuery, Types.CheckScratchcardOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.CheckScratchcardOrderQuery, Types.CheckScratchcardOrderQueryVariables>(CheckScratchcardOrderDocument, variables, options);
}
export function useCheckScratchcardOrderLazyQuery(variables?: Types.CheckScratchcardOrderQueryVariables | VueCompositionApi.Ref<Types.CheckScratchcardOrderQueryVariables> | ReactiveFunction<Types.CheckScratchcardOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.CheckScratchcardOrderQuery, Types.CheckScratchcardOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckScratchcardOrderQuery, Types.CheckScratchcardOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckScratchcardOrderQuery, Types.CheckScratchcardOrderQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.CheckScratchcardOrderQuery, Types.CheckScratchcardOrderQueryVariables>(CheckScratchcardOrderDocument, variables, options);
}
export type CheckScratchcardOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.CheckScratchcardOrderQuery, Types.CheckScratchcardOrderQueryVariables>;
export const ReserveScratchcardsDocument = gql`
    mutation ReserveScratchcards($input: ReserveScratchcardsInput!) {
  reserveScratchcards(input: $input) {
    reservationId
    restrictions {
      geoCheck
      playerCheck
      depositLimit {
        available {
          ...money
        }
        value {
          ...money
        }
        period
        type
      }
      spendingLimit {
        available {
          ...money
        }
        value {
          ...money
        }
        period
        type
      }
    }
    quantity
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useReserveScratchcardsMutation__
 *
 * To run a mutation, you first call `useReserveScratchcardsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReserveScratchcardsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReserveScratchcardsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useReserveScratchcardsMutation(options: VueApolloComposable.UseMutationOptions<Types.ReserveScratchcardsMutation, Types.ReserveScratchcardsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.ReserveScratchcardsMutation, Types.ReserveScratchcardsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.ReserveScratchcardsMutation, Types.ReserveScratchcardsMutationVariables>(ReserveScratchcardsDocument, options);
}
export type ReserveScratchcardsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.ReserveScratchcardsMutation, Types.ReserveScratchcardsMutationVariables>;
export const RevealScratchcardDocument = gql`
    mutation revealScratchcard($input: RevealScratchcardInput!) {
  revealScratchcard(input: $input) {
    amount {
      ...money
    }
    isBigWin
    prizeTier
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useRevealScratchcardMutation__
 *
 * To run a mutation, you first call `useRevealScratchcardMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRevealScratchcardMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRevealScratchcardMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRevealScratchcardMutation(options: VueApolloComposable.UseMutationOptions<Types.RevealScratchcardMutation, Types.RevealScratchcardMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.RevealScratchcardMutation, Types.RevealScratchcardMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.RevealScratchcardMutation, Types.RevealScratchcardMutationVariables>(RevealScratchcardDocument, options);
}
export type RevealScratchcardMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.RevealScratchcardMutation, Types.RevealScratchcardMutationVariables>;
export const RevealScratchcardOrderDocument = gql`
    mutation revealScratchcardOrder($input: RevealOrderInput!) {
  revealOrder(input: $input) {
    gameName
    orderId
    quantity
    quantityActive
    state
    winnings {
      ...money
    }
    scratchcards {
      image
      serialNumber
      status
      status
      winningsPayload {
        amount {
          ...money
        }
        isBigWin
      }
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useRevealScratchcardOrderMutation__
 *
 * To run a mutation, you first call `useRevealScratchcardOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRevealScratchcardOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRevealScratchcardOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRevealScratchcardOrderMutation(options: VueApolloComposable.UseMutationOptions<Types.RevealScratchcardOrderMutation, Types.RevealScratchcardOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.RevealScratchcardOrderMutation, Types.RevealScratchcardOrderMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.RevealScratchcardOrderMutation, Types.RevealScratchcardOrderMutationVariables>(RevealScratchcardOrderDocument, options);
}
export type RevealScratchcardOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.RevealScratchcardOrderMutation, Types.RevealScratchcardOrderMutationVariables>;
export const ScratchcardGameDocument = gql`
    query ScratchcardGame($licensedTerritory: String!, $slug: String!) {
  scratchcardGame(licensedTerritory: $licensedTerritory, slug: $slug) {
    available
    enabled
    name
    priceList {
      chargePerCard {
        ...money
      }
      chargePerOrder {
        ...money
      }
      currency
      feePerCard {
        ...money
      }
      feePerOrder {
        ...money
      }
      salesTaxStrategy
    }
    prizeTiers {
      prize {
        currency
        value
      }
      tier
    }
    slug
    visualParameters {
      primaryColor
      secondaryColor
      teaserImage
      titleColor
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useScratchcardGameQuery__
 *
 * To run a query within a Vue component, call `useScratchcardGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useScratchcardGameQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useScratchcardGameQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 *   slug: // value for 'slug'
 * });
 */
export function useScratchcardGameQuery(variables: Types.ScratchcardGameQueryVariables | VueCompositionApi.Ref<Types.ScratchcardGameQueryVariables> | ReactiveFunction<Types.ScratchcardGameQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScratchcardGameQuery, Types.ScratchcardGameQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScratchcardGameQuery, Types.ScratchcardGameQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScratchcardGameQuery, Types.ScratchcardGameQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.ScratchcardGameQuery, Types.ScratchcardGameQueryVariables>(ScratchcardGameDocument, variables, options);
}
export function useScratchcardGameLazyQuery(variables?: Types.ScratchcardGameQueryVariables | VueCompositionApi.Ref<Types.ScratchcardGameQueryVariables> | ReactiveFunction<Types.ScratchcardGameQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScratchcardGameQuery, Types.ScratchcardGameQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScratchcardGameQuery, Types.ScratchcardGameQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScratchcardGameQuery, Types.ScratchcardGameQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.ScratchcardGameQuery, Types.ScratchcardGameQueryVariables>(ScratchcardGameDocument, variables, options);
}
export type ScratchcardGameQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.ScratchcardGameQuery, Types.ScratchcardGameQueryVariables>;
export const ScratchcardOrderDocument = gql`
    query ScratchcardOrder($orderId: String!) {
  scratchcardOrder(orderId: $orderId) {
    gameName
    licensedTerritory
    orderId
    price {
      ...money
    }
    quantity
    quantityActive
    quantityRevealed
    scratchcards {
      image
      images {
        back
        backCreationDate
        backImageProperties {
          ...imageProperties
        }
        linkExpiresAt
        scratched
        scratchedCreationDate
        scratchedImageProperties {
          ...imageProperties
        }
        unscratched
        unscratchedCreationDate
        unscratchedImageProperties {
          ...imageProperties
        }
      }
      serialNumber
      status
      winningsPayload {
        amount {
          ...money
        }
        isBigWin
      }
    }
    state
    visualParameters {
      primaryColor
      scratchArea
      secondaryColor
      titleColor
    }
    winnings {
      ...money
    }
  }
}
    ${MoneyFragmentDoc}
${ImagePropertiesFragmentDoc}`;

/**
 * __useScratchcardOrderQuery__
 *
 * To run a query within a Vue component, call `useScratchcardOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useScratchcardOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useScratchcardOrderQuery({
 *   orderId: // value for 'orderId'
 * });
 */
export function useScratchcardOrderQuery(variables: Types.ScratchcardOrderQueryVariables | VueCompositionApi.Ref<Types.ScratchcardOrderQueryVariables> | ReactiveFunction<Types.ScratchcardOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScratchcardOrderQuery, Types.ScratchcardOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScratchcardOrderQuery, Types.ScratchcardOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScratchcardOrderQuery, Types.ScratchcardOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.ScratchcardOrderQuery, Types.ScratchcardOrderQueryVariables>(ScratchcardOrderDocument, variables, options);
}
export function useScratchcardOrderLazyQuery(variables?: Types.ScratchcardOrderQueryVariables | VueCompositionApi.Ref<Types.ScratchcardOrderQueryVariables> | ReactiveFunction<Types.ScratchcardOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScratchcardOrderQuery, Types.ScratchcardOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScratchcardOrderQuery, Types.ScratchcardOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScratchcardOrderQuery, Types.ScratchcardOrderQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.ScratchcardOrderQuery, Types.ScratchcardOrderQueryVariables>(ScratchcardOrderDocument, variables, options);
}
export type ScratchcardOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.ScratchcardOrderQuery, Types.ScratchcardOrderQueryVariables>;
export const ScratchcardTeasersDocument = gql`
    query scratchcardTeasers($licensedTerritory: String!) {
  scratchcardTeasers(licensedTerritory: $licensedTerritory) {
    name
    slug
    pricePerCard {
      currency
      value
    }
    value {
      currency
      value
    }
    visualParameters {
      primaryColor
      secondaryColor
      teaserImage
      titleColor
    }
  }
}
    `;

/**
 * __useScratchcardTeasersQuery__
 *
 * To run a query within a Vue component, call `useScratchcardTeasersQuery` and pass it any options that fit your needs.
 * When your component renders, `useScratchcardTeasersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useScratchcardTeasersQuery({
 *   licensedTerritory: // value for 'licensedTerritory'
 * });
 */
export function useScratchcardTeasersQuery(variables: Types.ScratchcardTeasersQueryVariables | VueCompositionApi.Ref<Types.ScratchcardTeasersQueryVariables> | ReactiveFunction<Types.ScratchcardTeasersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScratchcardTeasersQuery, Types.ScratchcardTeasersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScratchcardTeasersQuery, Types.ScratchcardTeasersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScratchcardTeasersQuery, Types.ScratchcardTeasersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.ScratchcardTeasersQuery, Types.ScratchcardTeasersQueryVariables>(ScratchcardTeasersDocument, variables, options);
}
export function useScratchcardTeasersLazyQuery(variables?: Types.ScratchcardTeasersQueryVariables | VueCompositionApi.Ref<Types.ScratchcardTeasersQueryVariables> | ReactiveFunction<Types.ScratchcardTeasersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Types.ScratchcardTeasersQuery, Types.ScratchcardTeasersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.ScratchcardTeasersQuery, Types.ScratchcardTeasersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.ScratchcardTeasersQuery, Types.ScratchcardTeasersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.ScratchcardTeasersQuery, Types.ScratchcardTeasersQueryVariables>(ScratchcardTeasersDocument, variables, options);
}
export type ScratchcardTeasersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.ScratchcardTeasersQuery, Types.ScratchcardTeasersQueryVariables>;
export const ChangePreferencesDocument = gql`
    mutation ChangePreferences($input: PreferencesInput!) {
  setPreferences(input: $input) {
    channels {
      channel
      selected
    }
    notification
  }
}
    `;

/**
 * __useChangePreferencesMutation__
 *
 * To run a mutation, you first call `useChangePreferencesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangePreferencesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangePreferencesMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useChangePreferencesMutation(options: VueApolloComposable.UseMutationOptions<Types.ChangePreferencesMutation, Types.ChangePreferencesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.ChangePreferencesMutation, Types.ChangePreferencesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.ChangePreferencesMutation, Types.ChangePreferencesMutationVariables>(ChangePreferencesDocument, options);
}
export type ChangePreferencesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.ChangePreferencesMutation, Types.ChangePreferencesMutationVariables>;
export const ChangeTwoFactorConfigurationDocument = gql`
    mutation ChangeTwoFactorConfiguration($input: TwoFactorAuthenticationChannelSettingPayloadInput!) {
  changeTwoFactorConfiguration(input: $input) {
    channels {
      activated
      channel
    }
  }
}
    `;

/**
 * __useChangeTwoFactorConfigurationMutation__
 *
 * To run a mutation, you first call `useChangeTwoFactorConfigurationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangeTwoFactorConfigurationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangeTwoFactorConfigurationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useChangeTwoFactorConfigurationMutation(options: VueApolloComposable.UseMutationOptions<Types.ChangeTwoFactorConfigurationMutation, Types.ChangeTwoFactorConfigurationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.ChangeTwoFactorConfigurationMutation, Types.ChangeTwoFactorConfigurationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.ChangeTwoFactorConfigurationMutation, Types.ChangeTwoFactorConfigurationMutationVariables>(ChangeTwoFactorConfigurationDocument, options);
}
export type ChangeTwoFactorConfigurationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.ChangeTwoFactorConfigurationMutation, Types.ChangeTwoFactorConfigurationMutationVariables>;
export const CheckPreferencesDocument = gql`
    query CheckPreferences {
  preferences {
    channels {
      channel
      selected
    }
    notification
  }
}
    `;

/**
 * __useCheckPreferencesQuery__
 *
 * To run a query within a Vue component, call `useCheckPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPreferencesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCheckPreferencesQuery();
 */
export function useCheckPreferencesQuery(options: VueApolloComposable.UseQueryOptions<Types.CheckPreferencesQuery, Types.CheckPreferencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckPreferencesQuery, Types.CheckPreferencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckPreferencesQuery, Types.CheckPreferencesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.CheckPreferencesQuery, Types.CheckPreferencesQueryVariables>(CheckPreferencesDocument, {}, options);
}
export function useCheckPreferencesLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.CheckPreferencesQuery, Types.CheckPreferencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.CheckPreferencesQuery, Types.CheckPreferencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.CheckPreferencesQuery, Types.CheckPreferencesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.CheckPreferencesQuery, Types.CheckPreferencesQueryVariables>(CheckPreferencesDocument, {}, options);
}
export type CheckPreferencesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.CheckPreferencesQuery, Types.CheckPreferencesQueryVariables>;
export const GetTwoFactorConfigurationDocument = gql`
    query GetTwoFactorConfiguration {
  twoFactorConfiguration {
    channels {
      activated
      channel
    }
  }
}
    `;

/**
 * __useGetTwoFactorConfigurationQuery__
 *
 * To run a query within a Vue component, call `useGetTwoFactorConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTwoFactorConfigurationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetTwoFactorConfigurationQuery();
 */
export function useGetTwoFactorConfigurationQuery(options: VueApolloComposable.UseQueryOptions<Types.GetTwoFactorConfigurationQuery, Types.GetTwoFactorConfigurationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.GetTwoFactorConfigurationQuery, Types.GetTwoFactorConfigurationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.GetTwoFactorConfigurationQuery, Types.GetTwoFactorConfigurationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Types.GetTwoFactorConfigurationQuery, Types.GetTwoFactorConfigurationQueryVariables>(GetTwoFactorConfigurationDocument, {}, options);
}
export function useGetTwoFactorConfigurationLazyQuery(options: VueApolloComposable.UseQueryOptions<Types.GetTwoFactorConfigurationQuery, Types.GetTwoFactorConfigurationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Types.GetTwoFactorConfigurationQuery, Types.GetTwoFactorConfigurationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Types.GetTwoFactorConfigurationQuery, Types.GetTwoFactorConfigurationQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<Types.GetTwoFactorConfigurationQuery, Types.GetTwoFactorConfigurationQueryVariables>(GetTwoFactorConfigurationDocument, {}, options);
}
export type GetTwoFactorConfigurationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Types.GetTwoFactorConfigurationQuery, Types.GetTwoFactorConfigurationQueryVariables>;