type EventPayloads = Parameters<
  ReturnType<typeof useTracking>['pushContentViewEvent']
>[0]

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.server || !useRuntimeConfig().public.gtmEnabled!) return

  const { pushContentViewEvent } = useTracking()
  const { isLoggedIn } = useAuth()
  const { afterEach } = useRouter()

  const isMounted = ref(false)
  const preMountPayloads = ref<EventPayloads[]>([])

  nuxtApp.hook('app:beforeMount', () => {
    isMounted.value = true

    if (!preMountPayloads.value.length) return
    preMountPayloads.value.forEach((payload) => pushContentViewEvent(payload))
    preMountPayloads.value = []
  })

  const triggerPush = (payload: EventPayloads) => {
    if (isMounted.value) return pushContentViewEvent(payload)

    preMountPayloads.value = [...preMountPayloads.value, payload]
  }

  afterEach((to, from) => {
    if (to.fullPath === from.fullPath && isMounted.value) return

    const contentViewName =
      to.meta.tracking?.name ??
      String(to.name).split(/[-_]/).map(capitalize).join('')

    triggerPush({
      'content-name': to.fullPath,
      'content-view-name': contentViewName,
      'isLoggedIn': isLoggedIn.value,
    })
  })
})
