import type { RouteLocationNormalized } from 'vue-router'

export const manualUserNavigationGuard =
  (fallback: string) =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (to.fullPath === from.fullPath) {
      const { redirect, ...rest } = to.query
      return navigateTo({ path: fallback, query: rest })
    }
  }
