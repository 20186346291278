import { lottery, scratchcard } from '@lottocom/price-calculation'

import type {
  BonusValueEnum,
  KnownProductType,
  LotteryType,
  Money,
  ScratchcardPriceList,
} from '~/@types/generated/backend/graphql-schema-types'
import type { ProductType } from '~/composables/checkout'

import PriceBreakdown = lottery.PriceBreakdown
import ScratchcardPriceBreakdown = scratchcard.ScratchcardPriceBreakdown

export const TRACKING_ENVS = {
  Development: 'dev',
  Production: 'production',
  Staging: 'staging',
} as const

export enum TrackingECommerceItemPickSetup {
  None = 'none',
  QuickPick = 'quickPick',
}

export enum TrackingKycActions {
  AgeVerificationFailed = 'age_verification_failure',
  AgeVerificationStarted = 'age_verification_started',
  AgeVerificationSuccessful = 'age_verification_success',
}

export enum TrackingKycMethods {
  AutomaticAgeVerification = 'automatic_age_verification',
  ScanVerify = 'scan_verify',
  SendSafely = 'send_safely',
}

export enum TrackingLoginActions {
  Failure = 'failure',
  Success = 'success',
  Suspended = 'suspended',
}

export type TrackingCreateECommcerceDataParams = NonNullable<unknown> &
  (
    | {
        currentPrice: Omit<ScratchcardPriceBreakdown, 'libraryVersion'>
        name: string
        priceList: ScratchcardPriceList
        promoCodes?: TrackingPromoCodes[]
        quantity: number
        type: KnownProductType.Scratchcard
      }
    | {
        currentPrice?: Maybe<PriceBreakdown>
        lotteryId: string
        plays: PlaySlipPlay[]
        promoCodes?: TrackingPromoCodes[]
        ticketType: LotteryTicketType
        type: KnownProductType.Lottery
      }
  )

export type TrackingCreateECommcercePurchaseParams =
  TrackingCreateECommcerceDataParams & {
    coupon: Maybe<BonusCodeCoupon> | undefined
    isFirstTransaction: boolean
    totalPrice: Money | undefined
    transactionId: string
  }

export type TrackingCreateItemParams = {
  id: string
  name: string
  priceBreakdown?: Maybe<Pick<PriceBreakdown, 'grossTotal'>>
}

export type TrackingCreatePicksParams = {
  plays: PlaySlipPlay[]
  priceBreakdown?: Maybe<Pick<PriceBreakdown, 'chargesPerPlay'>>
}

export type TrackingECommerceFundingItem = TrackingECommerceItem & {
  item_category: 'account_funding'
  tax: number
}

export type TrackingECommerceItemPicks = {
  add_ons: {
    name: string
    price: number
  }[]
  pick_num: number
  pick_price: number
  pick_setup: TrackingECommerceItemPickSetup
}

export type TrackingECommerceLotteryItem = TrackingECommerceItem & {
  item_category?: LotteryType
  lottery_date?: string
  lottery_stake: number
  picks: TrackingECommerceItemPicks[]
  promo_codes?: TrackingPromoCodes[]
  ticket_type?: LotteryTicketType
}

export type TrackingECommerceScratchcardItem = TrackingECommerceItem & {
  item_category: ProductType.Scratchcard
  picks: TrackingECommerceItemPicks[]
  promo_codes?: TrackingPromoCodes[]
  ticket_type?: LotteryTicketType.Single
}

export type TrackingItem = {
  currency: string
  item_id: string
  item_name: string
}

export type TrackingPromoCodes = {
  name: string
  type: BonusValueEnum
  value: number
}

export type TrackingPurchaseEventParams = {
  coupon: string
  currency: string
  isFirstPurchase: boolean
  orderId: string
  subscription?: boolean
  tax: number
  value: number
}

type TrackingECommerceItem = {
  convenience_fee: number
  currency: string
  item_id: string
  item_name: string
  price: number
  quantity: number
}

export const TRACKING_IMPRESSION_ACTIONS = {
  AccountVerification: 'account_verification',
  AddPaymentMethod: 'add_payment_method',
  ChangeLimits: 'change_limits',
  ChangePassword: 'change_password',
  ChangePhoneNumber: 'change_phone_number',
  ChangeSettings: 'change_settings',
  Checkout: 'checkout',
  CloseAccount: 'close_account',
  DeletePaymentMethod: 'delete_payment_method',
  Funding: 'funding',
  Login: 'login',
  Logout: 'logout',
  Payout: 'payout',
  Register: 'register',
  ResetPassword: 'reset_password',
  Scratching: 'scratching',
  SelfExclusion: 'self_exclusion',
  StateSwitch: 'state_switch',
  Unsubscribe: 'unsubscribe',
  UseBonusCode: 'use_bonus_code',
} as const

export type LoginStatus =
  | 'invalid.credentials'
  | 'login.blocked'
  | 'playerClosed'
  | 'playerSuspended'
  | 'restricted'

export type PurchaseItem = {
  convenience_fee: number
  currency: string
  item_category: KnownProductType.Scratchcard | LotteryType | undefined
  item_id: string
  item_name: string | undefined
  lottery_date: null | string
  lottery_stake: null | number
  picks: {
    add_ons: {
      name: string
      price: number
    }[]
    pick_num: number
    pick_price: number
    pick_setup: TrackingECommerceItemPickSetup
  }[]
  price: number
  promo_codes: {
    name: string
    type: BonusValueEnum
    value: number
  }[]
  quantity: number
  tax: number
  ticket_type: LotteryTicketType
}

export type TrackingECommerce<T> = {
  currency: string
  items: T
  value: number
}

export type TrackingECommerceCheckout = TrackingECommerce<
  TrackingECommerceLotteryItem[] | TrackingECommerceScratchcardItem[]
>

export type TrackingECommerceFunding = TrackingECommerce<
  TrackingECommerceFundingItem[]
>

export type TrackingECommercePayIn = TrackingECommerce<
  TrackingECommerceFundingItem[]
> & {
  first_transaction: boolean
  pay_in_id: string
}

export type TrackingECommercePurchase = TrackingECommerceCheckout & {
  first_transaction: boolean
  items:
    | TrackingECommercePurchaseItemLottery[]
    | TrackingECommercePurchaseItemScratchcard[]
  transaction_id: string
}

export type TrackingECommercePurchaseItemLottery =
  TrackingECommerceLotteryItem & {
    promo_codes: {
      name: string
      type: BonusValueEnum
      value: number
    }[]
    tax: number
  }

export type TrackingECommercePurchaseItemScratchcard =
  TrackingECommerceScratchcardItem & {
    promo_codes: {
      name: string
      type: BonusValueEnum
      value: number
    }[]
    tax: number
  }

export type TrackingEnvironment = ObjectValues<typeof TRACKING_ENVS>

export type TrackingEvent =
  | TrackingEvent2FAOutcome
  | TrackingEvent2FAStart
  | TrackingEventAccountKYC
  | TrackingEventAccountRegistration
  | TrackingEventAccountStatusUpdate
  | TrackingEventAddToCart
  | TrackingEventBeginCheckout
  | TrackingEventBeginCheckoutConfirmation
  | TrackingEventBeginCheckoutFundAccount
  | TrackingEventCheckoutRemovePromoCode
  | TrackingEventCheckoutStartAddPromoCode
  | TrackingEventCheckoutSumbitPromoCode
  | TrackingEventContentView
  | TrackingEventCustomerData
  | TrackingEventFormAutoSubmit
  | TrackingEventFormInput
  | TrackingEventImpression
  | TrackingEventInitialization
  | TrackingEventLogin
  | TrackingEventPayIn
  | TrackingEventPurchase
  | TrackingEventRemoveFromCart
  | TrackingEventReset
  | TrackingEventSelectListItem
  | TrackingEventUpdatePayment
  | TrackingEventViewItem
  | TrackingEventViewList

export type TrackingEvent2FAOutcome = {
  event: '2fa_outcome'
  twofa_outcome: TrackingLoginActions
}

export type TrackingEvent2FAStart = {
  event: '2fa_start'
}

export type TrackingEventAccountKYC = {
  event: 'kyc'
  kyc_action: TrackingKycActions
  kyc_method: TrackingKycMethods
}

export type TrackingEventAccountRegistration = TrackingEventAccountBase & {
  event: 'account_registration'
}

export type TrackingEventAccountStatusUpdate = TrackingEventAccountBase & {
  event: 'account_status_update'
}

export type TrackingEventAddToCart = {
  ecommerce: TrackingECommerceCheckout
  event: 'add_to_cart' | 'add_to_cart_by_next_step'
}

export type TrackingEventBeginCheckout = {
  checkout: { step_index: 1; step_name: 'cart' }
  ecommerce: TrackingECommerceCheckout
  event: 'begin_checkout'
}

export type TrackingEventBeginCheckoutConfirmation = {
  checkout: {
    payment_methods: string[]
    step_index: 20
    step_name: 'checkout_confirmation'
  }
  ecommerce: TrackingECommerceCheckout
  event: 'begin_checkout'
}

export type TrackingEventBeginCheckoutFundAccount = {
  checkout: {
    payment_methods: string[]
    step_index: 15
    step_name: 'fund_account'
  }
  ecommerce: TrackingECommerceFunding
  event: 'begin_checkout'
}

export type TrackingEventBeginCheckoutFundPayloadParams = Maybe<
  Omit<TrackingEventBeginCheckoutFundAccount['ecommerce'], 'items'> &
    Pick<TrackingECommerceFundingItem, 'convenience_fee' | 'price'>
>

export type TrackingEventCheckoutRemovePromoCode = {
  event: 'checkout_remove_promo_code'
}

export type TrackingEventCheckoutStartAddPromoCode = {
  event: 'checkout_start_add_promo_code'
}

export type TrackingEventCheckoutSumbitPromoCode = {
  event: 'checkout_submit_promo_code'
}

export type TrackingEventContentView = {
  'content-name': string
  'content-view-name': string
  'event': 'content-view'
  'page_login_status': 'logged_in' | 'logged_out'
}

export type TrackingEventCustomerData = {
  cstid: string
  emh: string
  env: TrackingEnvironment
  event: 'c_data'
  user_name: string
  user_surname: string
}

export type TrackingEventFormAutoSubmit = TrackingEventFormBase & {
  finput_action: 'auto_submit'
}

export type TrackingEventFormBase = {
  event: 'forminput'
  finput_data_clickname?: string
  finput_data_container?: string
}

export type TrackingEventFormInput = TrackingEventFormBase & {
  finput_action: 'input_end' | 'input_start'
  finput_automatic_focus: boolean
  finput_error?: string
  finput_firstfocusin: boolean
  finput_value?: string
}

export type TrackingEventImpression = {
  action?: string
  event: 'impression'
  message?: string
  topic?: string
  topic_key: string
  type: 'fly_out' | 'modal' | 'toast'
}

export type TrackingEventInitialization = {
  env: TrackingEnvironment
  event: 'init'
  native_app: boolean
  nonce: string
  originalLocation: string
}

export type TrackingEventLogin = {
  action: TrackingLoginActions
  event: 'login'
  login_status?: LoginStatus
}

export type TrackingEventPayIn = {
  ecommerce: TrackingECommercePayIn
  event: 'pay_in'
}

export type TrackingEventPayInPayloadParams = Maybe<
  Omit<TrackingEventPayIn['ecommerce'], 'items'> &
    Pick<TrackingECommerceFundingItem, 'convenience_fee' | 'price'>
>

export type TrackingEventPurchase = {
  ecommerce: TrackingECommercePurchase
  event: 'purchase'
}

export type TrackingEventRemoveFromCart = {
  ecommerce: TrackingECommerceCheckout
  event: 'remove_from_cart'
}

export type TrackingEventReset = {
  checkout: null
  ecommerce: null
  event: 'reset'
}

export type TrackingEventSelectListItem = {
  ecommerce: TrackingEventViewListData
  event: 'select_list_item'
}

export type TrackingEventType =
  | 'createECommerceEventData'
  | Extract<TrackingEvent, { event: string }>['event']

export type TrackingEventUpdatePayment = {
  checkout: {
    payment_methods: string[]
  }
  event: 'update_payment'
}

export type TrackingEventViewItem = {
  ecommerce: TrackingECommerceCheckout
  event: 'view_item'
}

export type TrackingEventViewList = {
  ecommerce: TrackingEventViewListData
  event: 'view_list'
}

export type TrackingEventViewListData = {
  items: {
    index: number
    item_category:
      | KnownProductType.Scratchcard
      | LotteryType
      | string
      | undefined
    item_id: string
    item_name: string
    lottery_date?: string
    lottery_stake?: number
    promotions: {
      promo_id: null | string
      promo_name: string
      promo_type: 'badge' | 'hero_highlight'
    }[]
  }[]
  list_id: string
  list_name: string
}

export type TrackingFormProps = TrackingProps<{
  containerId: string
}>

export type TrackingImpressionAction = ObjectValues<
  typeof TRACKING_IMPRESSION_ACTIONS
>

export type TrackingImpressionProps = TrackingProps<{
  action?: TrackingImpressionAction
  customMessage?: string
  customTopic?: string
  topicKey: string
}>

export type TrackingProps<T> = { tracking: T }

type TrackingEventAccountBase = {
  account_reg_status: string
}
