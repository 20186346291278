import priceCalculation from '@lottocom/price-calculation'
import { defineStore } from 'pinia'

import type { ScratchcardPriceList } from '~/@types/generated/backend/graphql-schema-types'

export const MAX_SELECTED_QUANTITY = 10
export const MIN_SELECTED_QUANTITY = 1

export type ScratchcardGameLocalState = {
  currentSlug: Maybe<string>
  selectedQuantity: number
}

export const useScratchcardStore = defineStore('ltc_scratchcard', {
  actions: {
    setCurrentSlug(newSlug: Maybe<string>) {
      this.currentSlug = newSlug
    },
    setSelectedQuantity(newValue: number) {
      this.selectedQuantity = newValue
    },
  },
  getters: {
    currentPrice: (state) => (priceList: ScratchcardPriceList) => {
      // do not remove this check, it's necessary for the SSR
      if (!priceList) return null

      return priceCalculation.scratchcard.calculatePrices(
        state.selectedQuantity,
        priceList,
      )
    },
    maxQuantity: () => (availableCards: number) =>
      availableCards <= MAX_SELECTED_QUANTITY
        ? availableCards
        : MAX_SELECTED_QUANTITY,
    minQuantity: () => MIN_SELECTED_QUANTITY,
  },
  persist: {
    storage: piniaPluginPersistedstate.cookies({
      sameSite: 'strict',
    }),
  },
  state: (): ScratchcardGameLocalState => ({
    currentSlug: null,
    selectedQuantity: 1,
  }),
})
