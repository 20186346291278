import type { Component, VNodeChild, VNodeNormalizedChildren } from 'vue'
import type { TranslationProps } from 'vue-i18n'

type CustomVNode = VNode | VNodeChild

type I18nNode = VNode & { props: TranslationProps }

const isChildRawSlot = (
  children: VNode['children'],
): children is Extract<VNodeNormalizedChildren, 'RawSlots'> =>
  children !== null && typeof children === 'object'

const isChildString = (children: VNode['children']): children is string =>
  typeof children === 'string' && children !== 'v-if'

const isFunction = (child: unknown): child is () => VNode[] =>
  typeof child === 'function'

const isI18nVNode = (vNode: VNode): vNode is I18nNode =>
  typeof vNode.type === 'object' && (vNode.type as Component)?.name === 'i18n-t'

const isIgnorable = (vNode: VNode) =>
  vNode.props &&
  Object.prototype.hasOwnProperty.call(
    vNode.props,
    'data-ignore-tracking-content',
  )

const isNotDisplayed = (vNode: VNode) =>
  vNode.dirs &&
  vNode.dirs.some(
    (dir) => (dir.dir as LooseObject)?.name === 'show' && !dir.value,
  )

const isPrimitive = (vNode: CustomVNode): vNode is boolean | number | string =>
  typeof vNode === 'string' ||
  typeof vNode === 'number' ||
  typeof vNode === 'boolean'

export const useTrackingComponent = () => {
  const { t } = useI18n()
  const { log } = useDatadog()

  const vNodeToString = (vNode?: CustomVNode): string => {
    if (!vNode) return ''

    if (isPrimitive(vNode)) {
      return String(vNode)
    }

    if (Array.isArray(vNode)) {
      return vNode.map(vNodeToString).join('')
    }

    if (isIgnorable(vNode)) {
      return ''
    }

    if (isI18nVNode(vNode)) {
      const slots: LooseObject = {}

      const children = vNode.children as VNode['children']
      if (children && isChildRawSlot(children)) {
        for (const key of Object.keys(children)) {
          const child = children[key] as unknown

          if (typeof child !== 'function') continue
          slots[key] = vNodeToString(child())
        }
      }

      return t(vNode.props.keypath, slots)
    }

    if (isNotDisplayed(vNode)) {
      return ''
    }

    if (isChildString(vNode.children)) {
      return vNode.children
    }

    if (Array.isArray(vNode.children)) {
      return vNode.children.map(vNodeToString).join('')
    }

    if (isChildRawSlot(vNode.children)) {
      try {
        return Object.values(vNode.children)
          .filter(isFunction)
          .map((child) => getVNodeTextContent(child()))
          .join('')
      } catch {
        // catches scoped slots
        return ''
      }
    }

    return ''
  }

  const getVNodeTextContent = (vNode?: CustomVNode) => {
    try {
      const str = vNodeToString(vNode)
      return str === '' ? undefined : str
    } catch (error) {
      log('warn', {
        component: 'useTrackingComponent.ts',
        error,
        message: 'Stringify of vNode failed',
      })
      return undefined
    }
  }

  return { getVNodeTextContent }
}
