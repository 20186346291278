<template>
  <div class="flex items-center h-full">
    <template v-if="isLoggedIn && !loading && balance">
      <LcIcon class="mr-2xs" name="account-balance" />
      {{ $t('theheader.balance') }}

      <strong class="ml-xs">
        {{
          $n(balance / 100, {
            style: 'currency',
            currency,
          })
        }}
      </strong>
    </template>
  </div>
</template>

<script lang="ts" setup>
const hasBalance = useState(StateKey.HasBalance, () => false)
const { isLoggedIn } = useAuth()
const { loading, result } = useAccountQuery({
  enabled: isLoggedIn.value,
  fetchPolicy: 'cache-and-network', // always fetch the latest balance
})

const balance = computed(() => result.value?.account.balance?.value ?? 0)
const currency = computed(
  () => result.value?.account.balance?.currency ?? 'USD',
)

watchEffect(() => {
  hasBalance.value = balance.value > 0
})
</script>
