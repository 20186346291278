import { defineStore } from 'pinia'

export type ActiveAnnouncementLocalState = {
  announcements: Record<string, StoredAnnouncement>
}

export type StoredAnnouncement = {
  active: boolean
  changeDate: string
  collapsed: boolean
  description: string
  id: string
  title: string
}

export const useActiveAnnouncementStore = defineStore(
  'ltc_active_announcement',
  {
    actions: {
      deleteAllAnnouncements() {
        this.announcements = {}
      },
      deleteAnnouncementByKey(licensedTerritory: string) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.announcements[licensedTerritory]
      },
      setAnnouncementByKey(
        licensedTerritory: string,
        announcement: StoredAnnouncement,
      ) {
        const currentAnnouncement = this.announcements[licensedTerritory]

        if (
          currentAnnouncement?.changeDate.split('.')[0] !==
          announcement?.changeDate.split('.')[0]
        ) {
          this.announcements[licensedTerritory] = {
            ...currentAnnouncement,
            ...announcement,
            collapsed: false,
          }

          return
        }

        this.announcements[licensedTerritory] = {
          ...currentAnnouncement,
          ...announcement,
        }
      },
    },
    getters: {
      announcementByKey: (state) => (licensedTerritory: string) =>
        state.announcements[licensedTerritory],
    },
    persist: {
      storage: piniaPluginPersistedstate.sessionStorage(),
    },
    state: (): {
      announcements: Record<string, StoredAnnouncement>
    } => ({
      announcements: {},
    }),
  },
)
