import validate from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45setup_45global from "/builds/lottocom/shop-frontend/frontend/middleware/01.setup.global.ts";
import _02_45silent_45email_45verification_45global from "/builds/lottocom/shop-frontend/frontend/middleware/02.silent-email-verification.global.ts";
import normalize_45url_45global from "/builds/lottocom/shop-frontend/frontend/middleware/normalize-url.global.ts";
import seo_45global from "/builds/lottocom/shop-frontend/frontend/middleware/seo.global.ts";
import manifest_45route_45rule from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.14.1592_patch_hash=9a265b1ea2bd9c6bcaebb6c5cf269fbec7cf4c358cf1fee71486190f46c10_c749bfca1a99a957e0cdc2c724dc4a5e/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45setup_45global,
  _02_45silent_45email_45verification_45global,
  normalize_45url_45global,
  seo_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "age-verification": () => import("/builds/lottocom/shop-frontend/frontend/middleware/age-verification.ts"),
  auth: () => import("/builds/lottocom/shop-frontend/frontend/middleware/auth.ts"),
  "cached-product": () => import("/builds/lottocom/shop-frontend/frontend/middleware/cached-product.ts"),
  "logged-out": () => import("/builds/lottocom/shop-frontend/frontend/middleware/logged-out.ts"),
  "only-excluded-users": () => import("/builds/lottocom/shop-frontend/frontend/middleware/only-excluded-users.ts"),
  redirects: () => import("/builds/lottocom/shop-frontend/frontend/middleware/redirects.ts"),
  register: () => import("/builds/lottocom/shop-frontend/frontend/middleware/register.ts"),
  "self-excluded": () => import("/builds/lottocom/shop-frontend/frontend/middleware/self-excluded.ts")
}