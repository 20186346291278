import { differenceInYears } from 'date-fns'
import * as Yup from 'yup'

import { isUsNumber } from '~/utils/phoneNumber'

type $I18n = {
  t: (key: string, variables?: unknown) => string
}

const removeMasking = (value: string) => value.replace(/\D/g, '')

export default defineNuxtPlugin((nuxtApp) => {
  const {
    i18nConfig: { requiredAge },
  } = useRuntimeConfig().public
  const { t } = nuxtApp.$i18n as $I18n

  Yup.setLocale({
    mixed: {
      required: ({ path }: { path: string }) =>
        t('validation.required', {
          field: capitalize(splitCamelCase(path).toLowerCase()),
        }),
    },
    string: {
      max: ({ max }) => t('validation.maxlength', { max }),
      min: ({ min }) => t('validation.minlength', { min }),
    },
  })

  return {
    provide: {
      yup: {
        bonusCode: Yup.string().matches(
          /^[a-zA-Z0-9-]*$/,
          t('validation.bonuscode'),
        ),
        cardHolder: Yup.string().matches(
          /^[A-Za-z\s\-~`'.]*$/,
          t('validation.creditcard.cardholder'),
        ),
        cardNumber: Yup.string(),
        countryCode: Yup.string().matches(
          countryCodeRegex,
          t('validation.invalid', {
            field: t('phonenumberfieldset.countrycode.arialabel'),
          }),
        ),
        cvc: Yup.string(),
        dateOfBirth: Yup.string()
          .test('validDate', t('validation.date.invalid'), (value) =>
            isValidDate(value),
          )
          .test(
            'requiredAge',
            t('validation.dateofbirth.underage'),
            (value) =>
              !!value &&
              differenceInYears(new Date(), new Date(parseDate(value))) >=
                parseInt(requiredAge, 10),
          ),
        emailLogin: Yup.string().matches(
          /^.*@+.*\.+.{2,}$/,
          t('validation.email'),
        ),
        emailRegister: Yup.string().matches(
          /^[a-zA-Z0-9!#%&'*+/=?^_`{|}~.-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]{2,}$/,
          t('validation.email'),
        ),
        expirationDate: Yup.string().matches(
          /^(0[1-9]|1[0-2])\/\d{2}$/,
          t('validation.creditcard.expirydate.invalid'),
        ),
        merge: (...schemas: Yup.Schema[]) => {
          // https://github.com/jquense/yup/issues/232#issuecomment-499789569
          const [first, ...rest] = schemas

          const merged = rest.reduce(
            (mergedSchemas, schema) => mergedSchemas.concat(schema),
            first,
          )

          return merged
        },
        password: Yup.string().min(8).max(2048),
        phoneNumber: Yup.string()
          .default('')
          .when('countryCode', ([countryCode], schema) =>
            isUsNumber(countryCode)
              ? schema.transform(removeMasking).min(10).max(10)
              : schema.min(5).max(14),
          ),
      },
    },
  }
})
