import { NuxtImg, NuxtLink, NuxtTime } from '#components'
import { nuxtAppContext, setGlobalConfig } from '@lottocom/frontend-components'

type $I18n = {
  t: (key: string, variables?: unknown) => string
}

export default defineNuxtPlugin((nuxtApp) => {
  const { t } = nuxtApp.$i18n as $I18n

  setGlobalConfig({
    lcFlyOut: {
      to: '#teleports',
    },
    lcInfoButton: {
      closeButtonAriaLabel: t('common.modal.close.label'),
      openButtonAriaLabel: t('common.modal.open.label'),
    },
    lcLotteryCutoffTime: {
      textCutoffTime: t('lottery.closingtime.caption.cutofftime'),
      textTimeIsAlmostUp: t('lottery.closingtime.caption.timeisalmostup'),
      textTimeToCutoff: t('lottery.closingtime.caption.timetocutoff'),
    },
    lcModal: {
      to: '#teleports',
    },
    lcPasswordInput: {
      toggleAriaLabel: t('common.input.password.toggle'),
    },
  })

  nuxtApp.vueApp.component('NuxtImg', NuxtImg)
  nuxtApp.vueApp.component('NuxtLink', NuxtLink)
  nuxtApp.vueApp.component('NuxtTime', NuxtTime)

  nuxtApp.vueApp.provide(nuxtAppContext, {
    useId: () => useId(),
    useState: (init) => useState(init),
  })
})
