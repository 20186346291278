import priceCalculation from '@lottocom/price-calculation'

import type { NumberSelection } from '~/stores/lottery'

import {
  LotteryId,
  type LotteryQuery,
  LotteryType,
  type Stake,
  SubscriptionType,
} from '~/@types/generated/backend/graphql-schema-types'

export type PlaySlipField = {
  name: string
  selected: number[]
}

export type PlaySlipPlay = {
  fields: PlaySlipField[]
  id: string
  isComplete: boolean
  isDirty: boolean
  isQuickPick: boolean
  isValid: boolean
  perPlayAddOn: Maybe<LotterySelectedPerPlayAddOn>
}

type PlaySlipFieldConfig =
  LotteryQuery['lottery']['currentConfig']['numbers'][0]

export const usePlaySlip = (productName?: string) => {
  const licensedTerritory = useLicensedTerritory({ raw: true })
  const { params, query } = useRoute()
  const visiblePlayId = useState('visiblePlayId', () => 'play-1')

  const productId = productName || String(params.lotteryId || query?.p)
  const lotteryId = getLotteryIdInCorrectCase(productId)

  if (!lotteryId)
    throw new Error(
      'usePlaySlip composable can only be used in valid lottery routes',
    )

  const {
    confirmedPlaysByLottery,
    deleteLotterySelection,
    prepaidSubscriptionAmountByLottery,
    selectedAddOnsByLottery,
    selectedNumbersByLottery,
    selectedPerPlayAddOnsByLottery,
    selectedStakesByLottery,
    setConfirmedPlays,
    setPrepaidSubscriptionAmount,
    setSelectedAddOns,
    setSelectedLotteryNumbers,
    setSelectedPerPlayAddOns,
    setSelectedStakes,
    setTicketType,
    ticketTypeByLottery,
  } = useLotteryStore()

  const queryVariables = {
    licensedTerritory,
    lotteryId,
  }
  const queryResult = useLotteryQuery(queryVariables)

  const addOns = computed(() => config.value?.addOns ?? [])
  const afterPurchaseAddOns = computed(
    () => config.value?.afterPurchaseAddOns ?? [],
  )
  const completePlays = computed(() =>
    plays.value.filter((play) => play.isComplete),
  )
  const config = computed(
    () => queryResult.result.value?.lottery.currentConfig ?? null,
  )
  const confirmedPlays = computed(() => {
    if (!config.value) return []

    return confirmedPlaysByLottery(lotteryId, config.value)
  })
  const currentPrice = computed(() => {
    if (!config.value || !priceList.value) return null
    const { numberOfDraws, stakes, version } = config.value

    const preparedAddOns = addOns.value
      .map((addOn) => {
        const value =
          selectedAddOns.value.find(
            (selectedAddOn) => selectedAddOn.name === addOn.name,
          )?.value ?? ''

        return {
          ...addOn,
          value,
        }
      })
      .filter((addon) => addon.value)
    try {
      const filteredPlays = plays.value
        .filter((play) => play.isDirty && play.isValid)
        .map((play) => {
          const playIndex = plays.value.findIndex(
            (_play) => play.id === _play.id,
          )

          const addOns = [selectedPerPlayAddOns.value[playIndex]].filter(
            isTruthy,
          )

          const fields = plays.value[playIndex].fields.filter((field) => {
            const ignoredForAddOns = config.value?.numbers.find(
              ({ name }) => name === field.name,
            )?.ignoredForAddOns

            return !ignoredForAddOns?.some(
              (ignoredForAddOn) => ignoredForAddOn.value === addOns[0]?.value,
            )
          })

          return {
            addOns,
            fields,
            stake: {
              price: selectedStakes.value[playIndex] ?? stakes[0].price,
            },
          }
        })

      if (filteredPlays.length === 0) return null

      return priceCalculation.lottery.calculatePrices(
        {
          addOns: preparedAddOns,
          configVersion: version,
          lotteryId,
          numberOfDraws:
            numberOfDraws[0] * (prepaidSubscriptionAmount.value?.value || 1),
          plays: filteredPlays,
        },
        {
          ...priceList.value,
          currency: 'USD',
        },
      )
    } catch {
      return null
    }
  })
  const fieldConfig = computed(() => config.value?.numbers ?? [])
  const firstIncompletePlayId = computed(
    () =>
      plays.value.find(({ isComplete }) => !isComplete)?.id ??
      plays.value.at(-1)?.id ??
      'play-1',
  )
  const hasDirtyPlays = computed(() =>
    plays.value.some(({ isDirty }) => isDirty),
  )
  const isFixedStake = computed(() => config.value?.stakes.length === 1)
  const isNumberLottery = computed(
    () => lotteryType.value === LotteryType.NumberLottery,
  )
  const isPickLottery = computed(
    () => lotteryType.value === LotteryType.PickLottery,
  )
  const isLuckyLinesLottery = computed(
    () => queryResult.result.value?.lottery.id === LotteryId.OregonLuckyLines,
  )
  const isSubscriptionAllowed = computed(
    () => !!config.value?.subscriptionsAllowed,
  )

  const prepaidSubscription = computed(() => config.value?.prepaidSubscription)

  const isPrepaidSubscriptionAllowed = computed(
    () => !!config.value?.prepaidSubscription,
  )

  const subscriptionOptions = computed(() => {
    const isSubscription = ticketType.value === LotteryTicketType.Subscription
    const isPrepaidSubscription =
      ticketType.value === LotteryTicketType.PrepaidSubscription

    if (!isSubscription && !isPrepaidSubscription) return

    const type = isPrepaidSubscription
      ? SubscriptionType.Prepaid
      : SubscriptionType.Renewing

    const drawsPerPurchase =
      isPrepaidSubscription && prepaidSubscriptionAmount.value
        ? prepaidSubscriptionAmount.value.value
        : 1

    return {
      drawsPerPurchase,
      type,
    }
  })

  const lastEditedPlayId = computed(
    () => plays.value.filter(({ isDirty }) => isDirty).at(-1)?.id ?? 'play-1',
  )
  const lotteryType = computed(() => config.value?.type ?? null)
  const maxAllowedPlays = computed(() => config.value?.allowedPlays.at(-1) ?? 1)
  const perPlayAddOn = computed(() => addOns.value.find(isPerPlay))
  const plays = computed(() =>
    selectedNumbers.value.map<PlaySlipPlay>((play, index) => {
      const perPlayAddOn = selectedPerPlayAddOns.value[index] ?? null
      const fields =
        fieldConfig.value.map(({ name }, fieldIndex) => ({
          name,
          selected: play.numbers[fieldIndex] ?? [],
        })) ?? []

      const isFieldComplete = fieldConfig.value.every(
        ({ ignoredForAddOns, selectableMax }, fieldIndex) =>
          play.numbers[fieldIndex]?.length >= selectableMax ||
          ignoredForAddOns.some(
            ({ value }) => value === selectedPerPlayAddOns.value[index].value,
          ),
      )

      const isPlayConfirmed =
        !isPickLottery.value ||
        confirmedPlays.value.findIndex((play, i) => play && index === i) !== -1

      const isComplete = isFieldComplete && isPlayConfirmed

      const _play = {
        fields,
        id: `play-${index + 1}`,
        isComplete,
        isDirty: play.numbers.some((field) => field.length),
        isQuickPick: play.isQuickPick,
        isValid: isComplete,
        perPlayAddOn,
      }

      // cross check with pricecalculation library for pick lotteries
      if (isPickLottery.value) {
        _play.isValid =
          isFieldComplete &&
          priceCalculation.lottery.validatePlay(
            {
              addOns: [perPlayAddOn],
              fields,
              stake: { price: selectedStakes.value[index] as string },
            },
            lotteryId!,
          )

        _play.isComplete = _play.isComplete && _play.isValid
      }
      return _play
    }),
  )
  const priceList = computed(
    () => queryResult.result.value?.lottery.priceList ?? null,
  )
  const properties = computed(
    () => queryResult.result.value?.lottery.properties ?? [],
  )
  const quickPickConfig = computed(() => config.value?.quickPick ?? null)
  const selectedPerPlayAddOns = computed(() => {
    if (!config.value) return []
    return selectedPerPlayAddOnsByLottery(lotteryId, config.value)
  })
  const selectedAddOns = computed(() => {
    if (!config.value) return []
    return selectedAddOnsByLottery(lotteryId, config.value)
  })
  const selectedNumbers = computed(() => {
    if (!config.value) return []

    return selectedNumbersByLottery(lotteryId, config.value).map(
      (selection) => selection,
    )
  })
  const selectedStakes = computed(() => {
    if (!config.value) return []

    return selectedStakesByLottery(lotteryId, config.value)
  })
  const ticketType = computed(() => ticketTypeByLottery(lotteryId))
  const prepaidSubscriptionAmount = computed(() =>
    prepaidSubscriptionAmountByLottery(lotteryId),
  )
  const firstIncompleteAndDirtyPlayId = computed(
    () => incompleteAndDirtyPlays.value[0]?.id ?? 'play-1',
  )
  const hasIncompletePlays = computed(
    () => !!incompleteAndDirtyPlays.value.length,
  )
  const hasNoCompletePlays = computed(
    () => !plays.value.some((play) => play.isComplete),
  )
  const incompleteAndDirtyPlays = computed(
    () => plays.value.filter((play) => !play.isComplete && play.isDirty) ?? [],
  )
  const nextIncompletePlayId = computed(() => {
    const visiblePlayIndex = getPlayIndex(visiblePlayId.value)
    const playsAfter = plays.value.slice(visiblePlayIndex + 1)
    const playsBefore = plays.value.slice(0, visiblePlayIndex)
    const surroundingIncompletePlays = [...playsAfter, ...playsBefore].filter(
      ({ isComplete }) => !isComplete,
    )

    return surroundingIncompletePlays[0]?.id
  })

  const deletePlay = (playId: string) => {
    setSelectedNumbersForPlay(
      playId,
      fieldConfig.value.map<number[]>(() => []),
    )

    if (!config.value || !isPickLottery.value) return

    setSelectedStake(getInitialStake(config.value), playId)
    removeConfirmedPlay(playId)
  }

  const deleteAllPlays = () => {
    const emptyPlays: NumberSelections = plays.value.map((_) => ({
      isQuickPick: false,
      numbers: fieldConfig.value.map<number[]>(() => []),
    }))

    setSelectedLotteryNumbers(lotteryId, emptyPlays)
  }

  const fillFieldWithQuickPick = (
    playId: string,
    { max, min, selectableMax }: PlaySlipFieldConfig,
    fieldIndex: number,
    isPlaySlipComplete: boolean,
  ) => {
    const play = plays.value.find(({ id }) => id === playId)
    const numbers = new Set(play?.fields[fieldIndex].selected)
    if (isPlaySlipComplete) numbers.clear()

    while (numbers.size < selectableMax) {
      numbers.add(getRandomNumber(min, max))
    }

    return [...numbers].sort((a, b) => a - b)
  }

  const fillPlaySlipWithQuickPick = (playAmount?: Maybe<number>) => {
    const updatedAddOns = selectedAddOns.value.map((addOn) => {
      const quickPickAddOn = quickPickConfig.value?.addOns.find(
        ({ name }) => name === addOn.name,
      )
      return {
        ...addOn,
        value: quickPickAddOn ? quickPickAddOn.value : addOn.value,
      }
    })
    setSelectedAddOns(lotteryId, updatedAddOns)

    const playsWithQuickPickAmount =
      playAmount ?? quickPickConfig.value?.plays ?? 0
    const updatedPlays = plays.value.map((play, playIndex) => ({
      isQuickPick: true,
      numbers: fieldConfig.value.map((field, fieldIndex) =>
        playIndex + 1 <= playsWithQuickPickAmount
          ? fillFieldWithQuickPick(play.id, field, fieldIndex, play.isComplete)
          : [],
      ),
    }))
    setSelectedLotteryNumbers(lotteryId, updatedPlays)

    return playsWithQuickPickAmount
  }

  const fillPlaysWithQuickPick = (playIds: string[]) => {
    const updatedPlays = plays.value.map((play) => {
      if (!playIds.includes(play.id)) {
        return {
          isQuickPick: play.isQuickPick,
          numbers: play.fields.map((field) => field.selected),
        }
      }

      let updatedPlay: NumberSelection

      const isDuplicate = ref(false)

      do {
        updatedPlay = {
          isQuickPick: true,
          numbers: fieldConfig.value.map((field, index) =>
            fillFieldWithQuickPick(play.id, field, index, play.isComplete),
          ),
        }

        isDuplicate.value = !!plays.value.find((play) => {
          if (!play.isComplete) return false

          const fields = play.fields.map(({ selected }) => selected)

          return isEqual(fields, updatedPlay.numbers)
        })
      } while (isDuplicate.value)

      return updatedPlay
    })

    setSelectedLotteryNumbers(lotteryId, updatedPlays)
  }

  const getPlayIndex = (playId: string) =>
    plays.value.findIndex(({ id }) => id === playId)

  const reset = () => {
    deleteLotterySelection(lotteryId)
  }

  const setSelectedAddOn = (
    name: LotterySelectedAddOn['name'],
    value: LotterySelectedAddOn['value'],
  ) => {
    let updatedAddOns = selectedAddOns.value.map((addOn) => ({ ...addOn }))

    if (!updatedAddOns.find((addOn) => addOn.name === name)) {
      updatedAddOns = addOns.value
        .filter(isNotPerPlay)
        .map<LotterySelectedAddOn>((addOn) => {
          const selectedAddOn = selectedAddOns.value?.find(
            ({ name }) => name === addOn.name,
          )
          return {
            name: addOn.name,
            value: selectedAddOn ? selectedAddOn.value : '',
          }
        })
    }

    const addOnIndex = updatedAddOns.findIndex((addOn) => addOn.name === name)
    updatedAddOns[addOnIndex].value = value
    setSelectedAddOns(lotteryId, updatedAddOns)
  }

  const setSelectedPerPlayAddOn = (
    updatedAddOn: LotterySelectedPerPlayAddOn,
    playId: string,
  ) => {
    const playIndex = getPlayIndex(playId)
    if (playIndex < 0) return

    const updatedPerPlayAddOns = selectedPerPlayAddOns.value.map(
      (addOn, addOnIndex) => (addOnIndex !== playIndex ? addOn : updatedAddOn),
    )

    setSelectedPerPlayAddOns(lotteryId, updatedPerPlayAddOns)
  }

  const setSelectedNumbersForField = (
    playId: string,
    fieldIndex: number,
    value: number[],
  ) => {
    const playIndex = getPlayIndex(playId)
    if (playIndex < 0) return

    const updatedNumbers = selectedNumbers.value.map((play, _playIndex) => ({
      isQuickPick: playIndex === _playIndex ? false : play.isQuickPick,
      numbers: play.numbers.map((field, _fieldIndex) =>
        playIndex === _playIndex && fieldIndex === _fieldIndex ? value : field,
      ),
    }))
    setSelectedLotteryNumbers(lotteryId, updatedNumbers)
  }

  const setSelectedNumbersForPlay = (playId: string, value: number[][]) => {
    const playIndex = getPlayIndex(playId)
    if (playIndex < 0) return
    const updatedNumbers: NumberSelections = selectedNumbers.value.map(
      (play, _playIndex) =>
        playIndex === _playIndex
          ? { isQuickPick: false, numbers: value }
          : play,
    )

    setSelectedLotteryNumbers(lotteryId, updatedNumbers)
  }

  const setSelectedStake = (value: Stake['price'], playId?: string) => {
    const playIndex = playId ? getPlayIndex(playId) : 0

    const updatedStakes = selectedStakes.value.map((stake, stakeIndex) => {
      if (stakeIndex !== playIndex) return stake

      return stakeIndex !== playIndex ? stake : value
    })

    setSelectedStakes(lotteryId, updatedStakes)
  }

  const setConfirmedPlay = (playId: string) => {
    const playIndex = getPlayIndex(playId)

    const updatedPlays = confirmedPlays.value.map((confirmed, idx) =>
      idx === playIndex ? true : confirmed,
    )

    setConfirmedPlays(lotteryId, updatedPlays)
  }

  const removeConfirmedPlay = (playId: string) => {
    const playIndex = getPlayIndex(playId)
    const updatedConfirmedPlays = confirmedPlays.value.map(
      (confirmed, index) => (playIndex === index ? false : confirmed),
    )
    setConfirmedPlays(lotteryId, updatedConfirmedPlays)
  }

  const storePlaySlip = async () => {
    if (!config.value) return

    const { numberOfDraws, stakes, version } = config.value
    const { couponCode: bonusCode } = useHypeStore()
    const { mutate } = useStorePlaySlipMutation()

    const result = await mutate({
      input: {
        addOns: selectedAddOns.value.filter((addOn) => addOn.value),
        bonusCode,
        configVersion: version,
        licensedTerritory,
        lotteryId,
        numberOfDraws: numberOfDraws[0],
        plays: plays.value
          .filter((play) => play.isComplete)
          .map((play) => {
            const playIndex = plays.value.findIndex(
              (_play) => play.id === _play.id,
            )

            const selectedPerPlayAddOn = selectedPerPlayAddOns.value[playIndex]

            return {
              addOns: selectedPerPlayAddOn ? [selectedPerPlayAddOn] : [],
              fields: plays.value[playIndex].fields.map((field) => {
                const isIgnored = config.value?.numbers
                  .find((number) => number.name === field.name)
                  ?.ignoredForAddOns.some(
                    (addOn) => addOn.value === selectedPerPlayAddOn.value,
                  )

                return {
                  name: field.name,
                  selected: isIgnored ? [] : field.selected,
                }
              }),
              stake: {
                price: selectedStakes.value[playIndex] ?? stakes[0].price,
              },
            }
          }),
        subscriptionOptions: subscriptionOptions.value,
      },
    })
    if (!result?.data) return
    // TODO restriction handling - MIRA-7718 has to be done before
    useCachedProduct().setProductId(result.data.storePlaySlip.id)
  }

  return {
    addOns,
    afterPurchaseAddOns,
    completePlays,
    config,
    confirmedPlays,
    currentPrice,
    deleteAllPlays,
    deletePlay,
    fieldConfig,
    fillPlaySlipWithQuickPick,
    fillPlaysWithQuickPick,
    firstIncompleteAndDirtyPlayId,
    firstIncompletePlayId,
    getPlayIndex,
    hasDirtyPlays,
    hasIncompletePlays,
    hasNoCompletePlays,
    incompleteAndDirtyPlays,
    isFixedStake,
    isLuckyLinesLottery,
    isNumberLottery,
    isPickLottery,
    isPrepaidSubscriptionAllowed,
    isSubscriptionAllowed,
    lastEditedPlayId,
    licensedTerritory,
    lotteryId,
    lotteryType,
    maxAllowedPlays,
    nextIncompletePlayId,
    perPlayAddOn,
    plays,
    prepaidSubscription,
    prepaidSubscriptionAmount,
    priceList,
    properties,
    removeConfirmedPlay,
    reset,
    selectedAddOns,
    selectedPerPlayAddOns,
    selectedStakes,
    setConfirmedPlay,
    setPrepaidSubscriptionAmount,
    setSelectedAddOn,
    setSelectedAddOns,
    setSelectedNumbersForField,
    setSelectedNumbersForPlay,
    setSelectedPerPlayAddOn,
    setSelectedStake,
    setTicketType,
    storePlaySlip,
    subscriptionOptions,
    ticketType,
    visiblePlayId,
  }
}
