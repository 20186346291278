import type { LcTabsTab } from '@lottocom/frontend-components'
import type { ComputedRef, MaybeRef } from 'vue'

export type Tab<T = undefined> = ActiveTab & LcTabsTab<T>

export type TabInput<T = undefined> = LcTabsTab<T> & Partial<ActiveTab>
type ActiveTab = { active: ComputedRef<boolean> | MaybeRef<boolean> }

const createTab = <T>(tab: LcTabsTab<T>): Tab<T> => ({
  ...{ active: true },
  ...tab,
})

const isActive = <T>(item: Tab<T>) => toValue(item.active)

export const useTabs = <T = undefined>(
  tabs: ComputedRef<TabInput<T>[]> | TabInput<T>[],
) => {
  const innerTabs = computed(() => toValue(tabs).map(createTab<T>))
  const activeTabs = computed(() => innerTabs.value.filter(isActive))

  return { activeTabs, tabs: innerTabs }
}
