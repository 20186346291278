import { addDays, isAfter, parseISO } from 'date-fns'
import { defineStore } from 'pinia'

const COUPON_EXPIRATION_TIME = 7

export const useHypeStore = defineStore(
  'ltc_hype',
  () => {
    const couponCode = ref<Maybe<string>>(null)
    const couponDate = ref<Maybe<string>>(null)

    const deleteCoupon = () => {
      couponCode.value = null
      couponDate.value = null
    }

    const setCoupon = (newCoupon: string) => {
      couponCode.value = newCoupon
      couponDate.value = new Date().toISOString()
    }

    const checkCouponExpiration = () => {
      if (!couponDate.value) return

      const expirationDate = addDays(new Date(), COUPON_EXPIRATION_TIME)

      if (isAfter(parseISO(couponDate.value), expirationDate)) {
        couponCode.value = null
        couponDate.value = null
      }
    }

    onBeforeMount(() => {
      checkCouponExpiration()
    })

    return {
      checkCouponExpiration,
      couponCode,
      couponDate,
      deleteCoupon,
      setCoupon,
    }
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
    },
  },
)
