import { customIdleCallback } from '~/utils/requestIdleCallback'

export enum LoadScriptError {
  General = 'load.script.error',
}

export type LoadScriptArguments = {
  options?: {
    appendToBody?: boolean
    async?: boolean
    customAdd?: (element: HTMLScriptElement) => void
    defer?: boolean
    id?: string
    integrity?: string
    onerror?: (error?: Error) => void
    onload?: (event: Event) => void
  }
  src: string
}

export const loadScript = (loadScript: LoadScriptArguments, timeout?: number) =>
  customIdleCallback(() => scriptGenerator(loadScript), { timeout })

export const scriptGenerator = (loadScriptArgs: LoadScriptArguments) => {
  const { options, src } = loadScriptArgs
  return new Promise<HTMLScriptElement>((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const element = document.createElement('script')
    element.setAttribute('nonce', useSafeNonce())
    if (options) {
      const { async, defer, id, integrity } = options
      if (id) element.setAttribute('id', id)
      if (integrity) element.setAttribute('integrity', integrity)
      element.setAttribute('async', String(async))
      element.setAttribute('defer', String(async ? false : defer))
      element.setAttribute('src', String(src))

      element.onerror = (_, __, ___, ____, error) => {
        if (options?.onerror) options.onerror(error)
        reject(error ?? new Error(`${LoadScriptError.General} for ${src}`))
      }
      element.onload = (event: Event) => {
        if (options?.onload) options.onload(event)
        resolve(element)
      }
    }

    if (options?.customAdd) {
      options.customAdd(element)
      return element
    }
    const appendTo = options?.appendToBody ? document.body : document.head
    return appendTo.appendChild(element)
  })
}
