// @ts-nocheck
import * as Types from '~/@types/generated/auth/graphql-schema-types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;

export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    result
  }
}
    `;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangePasswordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(options: VueApolloComposable.UseMutationOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>(ChangePasswordDocument, { clientId: 'auth', ...options});
}
export type ChangePasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    accessToken
    accessTokenType
  }
}
    `;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLoginMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(options: VueApolloComposable.UseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, { clientId: 'auth', ...options});
}
export type LoginMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.LoginMutation, Types.LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    result
  }
}
    `;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLogoutMutation();
 */
export function useLogoutMutation(options: VueApolloComposable.UseMutationOptions<Types.LogoutMutation, Types.LogoutMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.LogoutMutation, Types.LogoutMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.LogoutMutation, Types.LogoutMutationVariables>(LogoutDocument, { clientId: 'auth', ...options});
}
export type LogoutMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.LogoutMutation, Types.LogoutMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  refreshToken {
    accessToken
    accessTokenType
  }
}
    `;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRefreshTokenMutation();
 */
export function useRefreshTokenMutation(options: VueApolloComposable.UseMutationOptions<Types.RefreshTokenMutation, Types.RefreshTokenMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.RefreshTokenMutation, Types.RefreshTokenMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.RefreshTokenMutation, Types.RefreshTokenMutationVariables>(RefreshTokenDocument, { clientId: 'auth', ...options});
}
export type RefreshTokenMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.RefreshTokenMutation, Types.RefreshTokenMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    result
  }
}
    `;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResetPasswordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(options: VueApolloComposable.UseMutationOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument, { clientId: 'auth', ...options});
}
export type ResetPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  signUp(input: $input) {
    accessToken
    accessTokenType
  }
}
    `;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSignUpMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(options: VueApolloComposable.UseMutationOptions<Types.SignUpMutation, Types.SignUpMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.SignUpMutation, Types.SignUpMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.SignUpMutation, Types.SignUpMutationVariables>(SignUpDocument, { clientId: 'auth', ...options});
}
export type SignUpMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.SignUpMutation, Types.SignUpMutationVariables>;
export const TwoFactorAuthenticationLoginDocument = gql`
    mutation TwoFactorAuthenticationLogin($input: TwoFactorAuthenticationLoginInput!) {
  twoFactorAuthenticationLogin(input: $input) {
    accessToken
    accessTokenType
  }
}
    `;

/**
 * __useTwoFactorAuthenticationLoginMutation__
 *
 * To run a mutation, you first call `useTwoFactorAuthenticationLoginMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useTwoFactorAuthenticationLoginMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useTwoFactorAuthenticationLoginMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useTwoFactorAuthenticationLoginMutation(options: VueApolloComposable.UseMutationOptions<Types.TwoFactorAuthenticationLoginMutation, Types.TwoFactorAuthenticationLoginMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Types.TwoFactorAuthenticationLoginMutation, Types.TwoFactorAuthenticationLoginMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<Types.TwoFactorAuthenticationLoginMutation, Types.TwoFactorAuthenticationLoginMutationVariables>(TwoFactorAuthenticationLoginDocument, { clientId: 'auth', ...options});
}
export type TwoFactorAuthenticationLoginMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Types.TwoFactorAuthenticationLoginMutation, Types.TwoFactorAuthenticationLoginMutationVariables>;