import type { LotteryId } from '~/@types/generated/backend/graphql-schema-types'

import footerImages from '~/app/footer.json'
import illustrations from '~/app/illustrations.json'
import illustrationsMini from '~/app/illustrationsMini.json'
import lotteryLogos from '~/app/lotteryLogos.json'

type GlobalImport = Record<string, string> | undefined

type ImageDimensions = { height: number; width: number }
type ImageLookup = Record<string, ImageDimensions>

const resolveImportGlob = async (glob?: () => Promise<GlobalImport>) => {
  if (!glob) return null

  const result = await glob()
  return result?.default || null
}

export const useAsset = () => {
  const importGlob = import.meta.glob<GlobalImport>([
    '~/assets/animations/**/*',
    '~/assets/img/illustration/**/*',
    '~/assets/img/lottery/**/*',
    '!**/style',
  ])

  const lotteryLogoGlob = import.meta.glob<string>(
    '~/node_modules/@lottocom/frontend-lotteries/logos/**/*.svg',
    {
      eager: true,
      import: 'default',
    },
  )

  const getAnimation = (name: string) => {
    const animation = importGlob[`/assets/animations/${name}.svg`]
    return resolveImportGlob(animation)
  }

  const getFooterImage = (name: string) => {
    const footerImage = (footerImages as Record<string, ImageDimensions>)[name]
    if (!footerImage) return null

    return {
      height: footerImage.height,
      src: `/assets/img/footer/${name}.svg`,
      width: footerImage.width,
    }
  }

  const getIllustration = (name: string) => {
    const illustration = (illustrations as Record<string, ImageDimensions>)[
      name
    ]
    if (!illustration) return null

    return {
      height: illustration.height,
      src: `/assets/img/illustration/${name}.svg`,
      width: illustration.width,
    }
  }

  const getIllustrationMini = (name: string) => {
    const illustration = (illustrationsMini as Record<string, ImageDimensions>)[
      name
    ]
    if (!illustration) return null

    return {
      height: illustration.height,
      src: `/assets/img/illustration-mini/${name}.svg`,
      width: illustration.width,
    }
  }

  const getLotteryHighlight = (addOnName: string) => {
    const image =
      importGlob[`/assets/img/lottery/highlight/${addOnName.toLowerCase()}.svg`]
    return resolveImportGlob(image)
  }

  const getLotteryLogo = (licensedTerritory: string, lotteryId: LotteryId) => {
    const logo = (lotteryLogos as Record<string, ImageLookup>)[
      licensedTerritory.toLowerCase()
    ]?.[lotteryId.toLowerCase()]
    if (!logo) return null

    const src =
      lotteryLogoGlob[
        `/node_modules/@lottocom/frontend-lotteries/logos/${licensedTerritory}/${lotteryId}.svg`.toLowerCase()
      ]
    if (!src) return null

    return {
      height: logo.height,
      src,
      width: logo.width,
    }
  }

  return {
    getAnimation,
    getFooterImage,
    getIllustration,
    getIllustrationMini,
    getLotteryHighlight,
    getLotteryLogo,
  }
}
