// https://github.com/nuxt-modules/apollo/issues/442#issuecomment-1420860945
import type { ApolloLink, NormalizedCacheObject } from '@apollo/client'

import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import {
  ApolloClients,
  provideApolloClient,
  provideApolloClients,
} from '@vue/apollo-composable'
import { createApolloProvider } from '@vue/apollo-option'
import { destr } from 'destr'

import type { CookiesQuery } from '~/@types/generated/backend/graphql-schema-types'

import { createAuthLinks } from '~/apollo/auth'
import { createCmsLinks } from '~/apollo/cms'
import { createDefaultLinks } from '~/apollo/default'
import { CACHE_KEY_PREFIX } from '~/apollo/local/cache'
import { inMemoryCacheOptions } from '~/apollo/local/inMemoryCacheOptions'

type ClientDict<T> = Record<string, ApolloClient<T>>

type ClientDictParams = Record<
  KnownClients,
  {
    link: ApolloLink
    persist?: boolean
  }
>

type KnownClients = 'auth' | 'cms' | 'default'

export default defineNuxtPlugin((nuxtApp) => {
  const clients: ClientDict<any> = {}
  const {
    backend: { uriClient, uriServer },
    cms: { token: cmsToken, uri: cmsUri },
    ssoAuthUri,
    subscription: { uri: subscriptionUri },
  } = useRuntimeConfig().public

  const clientParams: ClientDictParams = {
    auth: { link: createAuthLinks(nuxtApp, ssoAuthUri) },
    cms: { link: createCmsLinks(nuxtApp, cmsUri, cmsToken) },
    default: {
      link: createDefaultLinks(
        nuxtApp,
        import.meta.client ? uriClient : uriServer,
        subscriptionUri,
      ),
      persist: true,
    },
  }

  const caches: Record<KnownClients, InMemoryCache> = {
    auth: new InMemoryCache({}),
    cms: new InMemoryCache({}),
    default: new InMemoryCache(inMemoryCacheOptions),
  }

  for (const [_key, { link }] of Object.entries(clientParams)) {
    const key: KnownClients = _key as KnownClients

    if (import.meta.server && key !== 'cms') {
      const nuxtApp = useNuxtApp()
      const safeCookies =
        nuxtApp.ssrContext?.event.headers
          .get('cookie')
          ?.split(';')
          .reduce((acc: Record<string, string>, cookie) => {
            const [key, value] = cookie.split('=')
            if (key.trim().match(new RegExp(`^${CACHE_KEY_PREFIX}_.*$`)))
              acc[key.trim()] = value
            return acc
          }, {}) || {}
      caches[key as KnownClients].writeQuery<CookiesQuery>({
        data: { cookies: JSON.stringify(safeCookies) }, // filter on sensitive data
        query: CookiesDocument,
      })
    }

    const client = new ApolloClient<NormalizedCacheObject>({
      cache: caches[key],
      defaultOptions: {
        query: {
          fetchPolicy: import.meta.server ? 'network-only' : 'cache-first',
        },
      },
      devtools: { enabled: import.meta.dev },
      link,
      ssrForceFetchDelay: import.meta.client ? 100 : 0,
      ssrMode: import.meta.server,
    })
    const cacheKey = `_apollo:${key}`
    nuxtApp.hook('app:rendered', () => {
      nuxtApp.payload.data[cacheKey] = caches[key].extract()
    })

    if (import.meta.client && nuxtApp.payload.data[cacheKey]) {
      caches[key].restore(destr(JSON.stringify(nuxtApp.payload.data[cacheKey])))
    }
    clients[key] = client
  }
  provideApolloClient(clients?.default)
  provideApolloClients(clients)
  nuxtApp.vueApp.provide(ApolloClients, clients)
  nuxtApp.vueApp.use(createApolloProvider({ defaultClient: clients?.default }))
  nuxtApp._apolloClients = clients
  const defaultClient = clients?.default

  return {
    provide: {
      apollo: { clients, defaultClient },
    },
  }
})
