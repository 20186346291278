import type { LicensedTerritory } from '@lottocom/lottery-mapping'

import { lotteryIdsByLicensedTerritory } from '@lottocom/lottery-mapping'

export const useLotteriesOfLicensedTerritory = () => {
  const licensedTerritory = useLicensedTerritory({
    raw: true,
  }) as LicensedTerritory

  const lotteryIds = lotteryIdsByLicensedTerritory[
    licensedTerritory
  ] as string[]

  const doesExist = (lotteryId: string) =>
    lotteryIds.map((v) => v.toLowerCase()).includes(lotteryId.toLowerCase())

  return { doesExist, lotteryIds }
}
