<template>
  <LcHeader
    v-bind="{ leftButtonIcon, withFlyOut }"
    :fly-out-toggle-aria-label="$t('lottery.header.button.flyout')"
    fly-out-toggle-class="text-md"
    headline-class="flex items-center justify-center"
    @left-button-click="handleClick"
  >
    <template #fly-out-content>
      <LotteryPriceBreakdown />
    </template>

    <template #fly-out-toggle>
      {{ chargeTotal }}
    </template>

    <template #headline>
      <slot name="headline">
        <LotteryLogo v-bind="{ licensedTerritory, lotteryId }" />
      </slot>
    </template>

    <slot />
  </LcHeader>
</template>

<script lang="ts" setup>
import type { LcHeaderProps } from '@lottocom/frontend-components'

import Money from '~/models/Money'

type LotteryHeaderProps = Pick<
  LcHeaderProps,
  'leftButtonIcon' | 'withFlyOut'
> & {
  onClick?: () => void
}

defineOptions({ inheritAttrs: false })

const props = withDefaults(defineProps<LotteryHeaderProps>(), {
  leftButtonIcon: 'arrow-left',
})

const licensedTerritory = useLicensedTerritory({ raw: true })
const { navigateBack } = useRouterUtils()

const { currentPrice, lotteryId } = usePlaySlip()

const chargeTotal = computed(() => new Money(currentPrice.value?.chargeTotal))

const handleClick = () => {
  if (props.onClick) props.onClick()
  else navigateBack()
}
</script>
