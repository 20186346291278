<template>
  <LcHeader
    v-bind="{ leftButtonIcon, withFlyOut }"
    :fly-out-toggle-aria-label="$t('scratchcard.header.button.flyout')"
    fly-out-toggle-class="text-md"
    @left-button-click="handleClick"
  >
    <template #headline>
      <ScratchcardTitle v-bind="{ color, title }" />
    </template>

    <template #fly-out-toggle>
      {{ priceListProps?.chargeTotal }}
    </template>

    <template #fly-out-content>
      <PriceList v-if="priceListProps" v-bind="priceListProps">
        <ScratchcardPriceBreakdownList />
      </PriceList>
    </template>
  </LcHeader>
</template>

<script lang="ts" setup>
import type { LcHeaderProps } from '@lottocom/frontend-components'

import Money from '~/models/Money'

type ScratchcardHeaderProps = Pick<
  LcHeaderProps,
  'leftButtonIcon' | 'withFlyOut'
> & {
  onClick?: () => void
}

const props = withDefaults(defineProps<ScratchcardHeaderProps>(), {
  leftButtonIcon: 'arrow-left',
})
const { navigateBack } = useRouterUtils()
const { name, priceList, visualParameters } = useScratchcardGame()
const { currentPrice } = useScratchcardStore()

const color = computed(() => visualParameters.value?.titleColor)
const title = computed(() => name.value || '')

const priceListProps = computed(() => {
  if (!priceList.value) return null
  const price = currentPrice(priceList.value)
  if (!price) return null
  const { chargeTotal, feesForCards, salesTax } = price
  return {
    chargeTotal: new Money(chargeTotal),
    convenienceFee: new Money(feesForCards),
    salesTax: new Money(salesTax),
    showSalesTax: (salesTax.value ?? 0) > 0,
  }
})

const handleClick = () => {
  if (props.onClick) props.onClick()
  else navigateBack()
}
</script>
