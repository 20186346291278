<template>
  <ul v-if="props.loading" class="list-select flex flex-col gap-2xs h-full">
    <li v-for="index in skeletonCount" :key="index" class="list-item bg-white">
      <LcSkeleton data-testid="skeleton" height="2rem" radius="small" />
    </li>
  </ul>

  <ul v-else class="list-select flex flex-col gap-2xs h-full">
    <li
      v-for="({ value, selected, label, href, hrefTitle }, index) in options"
      :key="value"
    >
      <slot v-bind="options[index]">
        <Component
          :is="!selected && href ? 'a' : 'span'"
          v-bind="{ href, hrefTitle }"
          class="item flex items-center justify-between px-sm py-xs"
          :class="{ selected }"
          role="button"
          tabindex="0"
          @click="handleOptionClick(options[index])"
          @keydown.enter="handleOptionClick(options[index])"
        >
          {{ label }}
          <LcIcon
            :class="{ 'visibility-hidden': !selected }"
            name="checkmark"
          />
        </Component>
      </slot>
    </li>
  </ul>
</template>

<script setup lang="ts">
export type ListSelectOption = {
  href?: string
  hrefTitle?: string
  label: string
  selected: boolean
  value: string
}

type ListSelectEmit = {
  (event: 'optionClick', value: ListSelectOption): void
}

type ListSelectProps = {
  loading?: boolean
  multiple?: boolean
  skeletonCount?: number
}

const emit = defineEmits<ListSelectEmit>()

const props = withDefaults(defineProps<ListSelectProps>(), {
  skeletonCount: 5,
})

const options = defineModel<ListSelectOption[]>({ required: true })

const handleOptionClick = (option: ListSelectOption) => {
  options.value = options.value.map((opt) => {
    let selected = opt === option ? !opt.selected : false

    if (props.multiple) {
      selected = opt === option ? !opt.selected : opt.selected
    }

    return {
      ...opt,
      selected,
    }
  })

  emit('optionClick', option)
}
</script>

<style lang="scss" scoped>
.list-select {
  overflow-y: auto;
  padding: spacing('xs');

  .item {
    border-radius: $border-radius-md;
    background-color: token('color.background.main.primary');

    @include focus-state {
      border-radius: $border-radius-sm;
      box-shadow: 0 0 0 0.1875rem token('color.feedback.focused');
    }

    @include hover-state {
      background: token('color.background.signal.info.soft');
      cursor: pointer;
    }

    &.selected {
      background: token('color.background.main.elements.dynamic');
    }
  }

  .visibility-hidden {
    visibility: hidden;
  }
}
</style>
