export const customIdleCallback = (
  callback: () => void,
  _options?: { timeout?: number },
) => {
  const options = _options ?? { timeout: 1000 }
  if (import.meta.client && typeof window.requestIdleCallback === 'function') {
    return window.requestIdleCallback(() => callback(), {
      timeout: options.timeout,
    })
  } else {
    return setTimeout(() => callback(), options.timeout)
  }
}
