<template>
  <Toaster v-bind="{ toasts }" @close="removeToast" />
  <NuxtLayout>
    <NuxtPwaManifest />
    <NuxtPage v-if="!isBlockedForAndroid" />

    <Modal
      v-else
      :is-visible="isBlockedForAndroid"
      :tracking="{
        topicKey: 'location-blocked-for-android',
      }"
      @cancel="onAndroidDisclaimerCancel"
      @confirm="onAndroidDisclaimerConfirm"
    >
      <template #headline>
        {{ $t('restriction.android.title') }}
      </template>
      <p>
        {{ $t('restriction.android.text') }}
      </p>
      <template #confirm>
        {{ $t('restriction.android.cta.primary') }}
      </template>
      <template #cancel>
        {{ $t('restriction.android.cta.secondary') }}
      </template>
    </Modal>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import '@lottocom/frontend-lotteries/style'

import broadcasterInstance, { BroadcastMessage } from '~/utils/broadcaster'

const isNativeApp = useState(StateKey.IsNativeApp)

const { isBlockedForAndroid } = useCustomContext()
const { locale, t } = useI18n()
const { removeToast, toasts } = useToaster()
const {
  clearScratchcardsCache,
  clearSingleOrdersCache,
  clearSubscriptionsCache,
  clearTransactionsCache,
} = useApolloCache()

useHead({
  htmlAttrs: {
    lang: locale.value,
  },
})

broadcasterInstance.on(
  BroadcastMessage.ClearScratchcardOrdersCache,
  clearScratchcardsCache,
)

broadcasterInstance.on(
  BroadcastMessage.ClearSingleOrdersCache,
  clearSingleOrdersCache,
)

broadcasterInstance.on(
  BroadcastMessage.ClearSubscriptionOrdersCache,
  clearSubscriptionsCache,
)

broadcasterInstance.on(
  BroadcastMessage.ClearTransactionsCache,
  clearTransactionsCache,
)

broadcasterInstance.on(BroadcastMessage.ContextChange, () => {
  window.location.reload()
})

const visitUrl = (url: string) => {
  if (import.meta.server) return
  try {
    window.location.href = new URL(url).toString()
  } catch {
    // Do nothing
  }
}

const onAndroidDisclaimerCancel = () => {
  visitUrl(t('restriction.android.url.secondary'))
}

const onAndroidDisclaimerConfirm = () => {
  visitUrl(`${t('restriction.android.url.primary')}/?leaveApp=true`)
}

onBeforeMount(() => {
  Object.defineProperty(window, 'isAppWrapper', {
    configurable: true,
    get: () => isNativeApp.value,
    set: (newValue) => (isNativeApp.value = newValue === true),
  })
})

// Logging implementd for investigation  of https://lottocom.atlassian.net/browse/SF-5251
// TODO: Remove temporary log, when not needed anymore

const logUserOnlineStatus = () => {
  const { log } = useDatadog()
  const online = useOnline()
  const userWasOffline = ref(false)

  watch(
    online,
    () => {
      if (online.value && userWasOffline.value) {
        log(DATADOG_LOG_TYPES.Info, {
          appendPlayerInfo: true,
          message: 'User is online again',
          messageContext: {
            url: window.location.href,
          },
        })
      }
      if (!online.value) {
        log(DATADOG_LOG_TYPES.Info, {
          appendPlayerInfo: true,
          message: 'User is offline',
          messageContext: {
            url: window.location.href,
          },
        })
        userWasOffline.value = true
      }
    },
    {
      immediate: true,
    },
  )
}

onMounted(() => {
  logUserOnlineStatus()
})
</script>
