import { lottery, scratchcard } from '@lottocom/price-calculation'

import type {
  TrackingCreateECommcerceDataParams,
  TrackingCreateECommcercePurchaseParams,
  TrackingCreateItemParams,
  TrackingCreatePicksParams,
  TrackingECommerceCheckout,
  TrackingECommerceItemPicks,
  TrackingECommerceLotteryItem,
  TrackingECommercePurchase,
  TrackingECommerceScratchcardItem,
  TrackingEventPurchase,
  TrackingEventViewList,
  TrackingItem,
  TrackingPromoCodes,
} from '~/layers/tracking/types/tracking'

import {
  type CampaignPayload,
  type CheckoutQuery,
  KnownProductType,
  type LotteryTeaserPayload,
  type ScheduledDrawsQuery,
  type ScratchcardPriceList,
  type ScratchcardTeaserPayload,
  type Status,
  type StoredPaymentInstrument,
  type VerificationPayload,
  VerificationPayloadName,
  VerificationPayloadStatus,
} from '~/@types/generated/backend/graphql-schema-types'
import { ScheduledDrawsDocument } from '~/composables/backend.generated'
import { ProductType } from '~/composables/checkout'
import { TrackingECommerceItemPickSetup } from '~/layers/tracking/types/tracking'
import { LotteryTicketType } from '~/stores/lottery'
import { toSnakeCase } from '~/utils/string'

import LotteryPriceBreakdown = lottery.PriceBreakdown
import PriceCalculationScratchcardPriceBreakdown = scratchcard.ScratchcardPriceBreakdown

type InternalPaymentMethod = Pick<StoredPaymentInstrument, 'method' | 'type'>

type ScratchcardPriceBreakdown = Omit<
  PriceCalculationScratchcardPriceBreakdown,
  'libraryVersion'
>

export const useTrackingData = () => {
  const { $apollo } = useNuxtApp()
  const licensedTerritory = useLicensedTerritory({ raw: true })
  const { logTrackingError } = useTrackingErrorLog()

  const createAccountStatus = (
    accountStatus: Status,
    verifications: Pick<VerificationPayload, 'name' | 'status'>[] = [],
    phoneStatus = VerificationPayloadStatus.NotStarted,
  ) => {
    const SEPARATOR = '; '

    const verificationStatuses = Object.values(VerificationPayloadName)
      .map((name) => {
        const status =
          name === VerificationPayloadName.PhoneVerification
            ? phoneStatus
            : (verifications.find(({ name: _name }) => _name === name)
                ?.status ?? VerificationPayloadStatus.NotStarted)

        return `${name}:${status}`
      })
      .join(SEPARATOR)

    return `${accountStatus}${SEPARATOR}${verificationStatuses}${SEPARATOR}`.trim()
  }

  const createItem = ({
    id,
    name,
    priceBreakdown,
  }: TrackingCreateItemParams): TrackingItem => ({
    currency: priceBreakdown?.grossTotal?.currency || 'USD',
    item_id: id.toLowerCase(),
    item_name: name.toLowerCase(),
  })

  type ListItem = {
    [key: string]: unknown
    id?: string
    name: string
  }

  const createGenericListItem = ({
    itemCategory,
    list,
  }: {
    itemCategory: string
    list: ListItem[]
  }) => {
    if (!list) return []

    let i = 0

    return list.reduce<TrackingEventViewList['ecommerce']['items']>(
      (total, item) => {
        if (!item) {
          return total
        }

        total.push({
          index: ++i,
          item_category: itemCategory,
          item_id: item.id ?? item.name.toLowerCase(),
          item_name: item.name,
          lottery_date: undefined,
          lottery_stake: undefined,
          promotions: [],
          ...item,
        })

        return total
      },
      [],
    )
  }

  type ListItemLotteryTeaser = Pick<
    LotteryTeaserPayload,
    | '__typename'
    | 'campaign'
    | 'closingDate'
    | 'id'
    | 'jackpotSize'
    | 'lotteryType'
    | 'name'
  >
  type ListItemScratchcardTeaser = Pick<
    ScratchcardTeaserPayload,
    '__typename' | 'campaign' | 'name' | 'slug'
  >

  const createTeaserListItems = (
    teasers: (ListItemLotteryTeaser | ListItemScratchcardTeaser)[],
  ) => {
    if (!teasers) return []

    const getPromotions = (
      campaign: Maybe<CampaignPayload> | undefined,
    ): TrackingEventViewList['ecommerce']['items'][0]['promotions'] => {
      if (!campaign) return []

      if ('bonusCode' in campaign) {
        return [
          {
            promo_id: campaign.bonusCode ?? null,
            promo_name: campaign.title,
            promo_type: 'badge',
          },
        ]
      }

      if ('badge' in campaign) {
        return [
          {
            promo_id: campaign.badge.bonusCode ?? null,
            promo_name: `${campaign.badge.title} ${campaign.badge.subtitle}`,
            promo_type: 'hero_highlight',
          },
        ]
      }

      return []
    }

    let i = 0

    return teasers.reduce<TrackingEventViewList['ecommerce']['items']>(
      (total, teaser) => {
        if (!teaser) {
          return total
        }

        const isLotteryTeaser = teaser.__typename === 'LotteryTeaserPayload'
        const isScratchcardTeaser =
          teaser.__typename === 'ScratchcardTeaserPayload'

        total.push({
          index: ++i,
          item_category: isLotteryTeaser
            ? teaser.lotteryType
            : KnownProductType.Scratchcard,
          item_id: isLotteryTeaser
            ? teaser.id
            : isScratchcardTeaser
              ? teaser.slug
              : teaser.name.toLowerCase(),
          item_name: teaser.name,
          lottery_date: isLotteryTeaser ? teaser.closingDate : undefined,
          lottery_stake: isLotteryTeaser
            ? teaser.jackpotSize.value / 100
            : undefined,
          promotions: getPromotions(teaser.campaign),
        })

        return total
      },
      [],
    )
  }

  const createPurchaseEventData = async (
    payload: TrackingCreateECommcercePurchaseParams,
  ): Promise<TrackingEventPurchase> => {
    const {
      coupon,
      currentPrice,
      isFirstTransaction,
      totalPrice,
      transactionId,
      type,
    } = payload

    const ecommerce: Partial<TrackingECommercePurchase> = {
      currency: totalPrice?.currency,
      first_transaction: isFirstTransaction,
      transaction_id: transactionId,
      value: totalPrice ? totalPrice.value / 100 : -1,
    }

    const baseItem: Pick<
      TrackingEventPurchase['ecommerce']['items'][0],
      'promo_codes' | 'tax'
    > = {
      promo_codes: coupon
        ? [
            {
              name: coupon.info.code,
              type: coupon.info.value.type,
              value: coupon.info.value.amount / 100,
            },
          ]
        : [],
      tax: currentPrice ? currentPrice.salesTax.value / 100 : -1,
    }

    switch (type) {
      case KnownProductType.Lottery:
        ecommerce.items = [
          {
            ...baseItem,
            ...(await createLotteryItem({
              currentPrice: currentPrice as LotteryPriceBreakdown,
              lotteryId: payload.lotteryId,
              plays: payload.plays,
              ticketType: payload.ticketType,
            })),
          },
        ]
        break
      case KnownProductType.Scratchcard:
        ecommerce.items = [
          {
            ...baseItem,
            ...(await createScratchcardItem({
              currentPrice: currentPrice as ScratchcardPriceBreakdown,
              name: payload.name,
              priceList: payload.priceList,
              quantity: payload.quantity,
            })),
          },
        ]
        break
    }

    return {
      ecommerce,
      event: 'purchase',
    } as TrackingEventPurchase
  }

  const createLotteryItem = async (payload: {
    currentPrice?: Maybe<LotteryPriceBreakdown>
    lotteryId: string
    plays: PlaySlipPlay[]
    promoCodes?: TrackingPromoCodes[]
    ticketType: LotteryTicketType
  }): Promise<TrackingECommerceLotteryItem> => {
    const { currentPrice, lotteryId, plays, ticketType } = payload
    const { data } = await $apollo.defaultClient.query<ScheduledDrawsQuery>({
      fetchPolicy: 'cache-first',
      query: ScheduledDrawsDocument,
      variables: { licensedTerritory, limit: 2, lotteryId },
    })

    const currentDraw = data?.scheduledDraws[0]
    const picks = createPicks({
      plays,
      priceBreakdown: currentPrice,
    })

    const promoCodes = payload.promoCodes
      ? { promo_codes: payload.promoCodes }
      : {}

    return {
      ...createItem({
        id: lotteryId.toLowerCase(),
        name: lotteryId.toLowerCase(),
        priceBreakdown: currentPrice,
      }),
      convenience_fee: currentPrice
        ? currentPrice.feePerPlaySlip.value / 100
        : 0,
      item_category: currentDraw?.lotteryConfig.currentConfig.type ?? undefined,
      lottery_date: currentDraw?.drawDate ?? undefined,
      lottery_stake: (currentDraw?.jackpots[0].value.value ?? 0) / 100,
      picks,
      price: picks.reduce((total, play) => total + play.pick_price, 0),
      quantity: 1,
      ticket_type: ticketType,
      ...promoCodes,
    }
  }

  const createPaymentMethods = (
    storedPayments: InternalPaymentMethod | InternalPaymentMethod[],
  ) => {
    if (Array.isArray(storedPayments) && storedPayments.length <= 0) return []

    const first = Array.isArray(storedPayments)
      ? storedPayments[0]
      : storedPayments
    const type = first.type ?? ''
    const method = first.method ?? ''

    return [toSnakeCase(`${type}${method}`)]
  }

  const createPicks = (params: TrackingCreatePicksParams) => {
    const { plays, priceBreakdown } = params
    if (!plays.length) return []
    const picks: TrackingECommerceItemPicks[] = plays
      .filter((play) => play.isComplete)
      .map(({ isQuickPick }, index) => {
        const chargesOfPlay = priceBreakdown!.chargesPerPlay[index]
        const pickPrice = chargesOfPlay
          ? chargesOfPlay.baseCharge.value / 100 +
            chargesOfPlay?.addOns.reduce(
              (total, addOn) => total + addOn.baseCharge.value / 100,
              0,
            )
          : 0

        return {
          add_ons: chargesOfPlay.addOns.map((addon) => ({
            name: addon.name,
            price: addon.baseCharge.value / 100,
          })),
          pick_num: index + 1,
          pick_price: pickPrice,
          pick_setup: isQuickPick
            ? TrackingECommerceItemPickSetup.QuickPick
            : TrackingECommerceItemPickSetup.None,
        }
      })

    return picks
  }

  const createECommerceEventData = async (
    payload: TrackingCreateECommcerceDataParams,
  ): Promise<Maybe<TrackingECommerceCheckout>> => {
    try {
      const couponValue = payload.promoCodes
        ? payload.promoCodes.reduce((total, { value }) => total + value, 0)
        : 0

      const data: Partial<TrackingECommerceCheckout> = {
        currency: payload.currentPrice?.grossTotal.currency || 'USD',
        value:
          (payload.currentPrice
            ? payload.currentPrice.grossTotal.value / 100
            : 0) - couponValue,
      }
      switch (payload.type) {
        case KnownProductType.Lottery:
          data.items = [await createLotteryItem(payload)]
          break
        case KnownProductType.Scratchcard:
          data.items = [createScratchcardItem(payload)]
          break
      }
      return data as TrackingECommerceCheckout
    } catch (err) {
      logTrackingError('createECommerceEventData', err)
      return null
    }
  }

  const createScratchcardItem = (payload: {
    currentPrice: ScratchcardPriceBreakdown
    name: string
    priceList: ScratchcardPriceList
    promoCodes?: TrackingPromoCodes[]
    quantity: number
  }): TrackingECommerceScratchcardItem => {
    const promoCodes = payload.promoCodes
      ? { promo_codes: payload.promoCodes }
      : {}

    return {
      ...createItem({
        id: 'scratchcard',
        name: payload.name,
        priceBreakdown: payload.currentPrice,
      }),
      convenience_fee:
        payload.currentPrice.feeTotal.value / payload.quantity / 100,
      item_category: ProductType.Scratchcard,
      picks: Array.from(Array(payload.quantity)).map(
        (_card, index) =>
          ({
            add_ons: [],
            pick_num: index + 1,
            pick_price: payload.priceList.chargePerCard.value / 100,
            pick_setup: TrackingECommerceItemPickSetup.None,
          }) as TrackingECommerceItemPicks,
      ),
      price: payload.currentPrice
        ? payload.currentPrice.grossTotal.value / payload.quantity / 100
        : 0,
      quantity: payload.quantity,
      ticket_type: LotteryTicketType.Single,
      ...promoCodes,
    }
  }

  const createCheckoutPaymentData = (
    paymentData: CheckoutQuery['checkout']['payment'],
  ) => {
    const hasBalance = computed(
      () => paymentData?.accountBalance && paymentData.accountBalance.value > 0,
    )

    const topUpPayment = isPayWithTopUp(paymentData) ? paymentData : null

    const paymentMethods = []
    if (topUpPayment?.stored[0]?.type) {
      paymentMethods.push(...createPaymentMethods(topUpPayment.stored))
    }
    if (hasBalance.value) paymentMethods.push('account_balance')

    return paymentMethods
  }

  return {
    createAccountStatus,
    createCheckoutPaymentData,
    createECommerceEventData,
    createGenericListItem,
    createPaymentMethods,
    createPurchaseEventData,
    createTeaserListItems,
  }
}
